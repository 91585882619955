import { observable, flow } from 'mobx'
import api from '../api'

class WatchSizeStore {
    @observable isSubmitting = false
    @observable error = null
    @observable watchSizes = []
    @observable count = 0
    @observable currentWatchSize = undefined

    createWatchSize = flow(function* (token, name, priority, status) {
        this.isSubmitting = true
        try {
            yield api.createWatchSize(token, name, priority, status)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(error.response.data.messages)
        }
    })

    listWatchSize = flow(function* (token, limit, skip, search, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listWatchSize(token, limit, skip, search, status)
            const { watchSizes, count } = response.data
            this.watchSizes = watchSizes
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getWatchSize = flow(function* (token, watchSizeId) {
        this.isSubmitting = true
        try {
            const response = yield api.getWatchSize(token, watchSizeId)
            this.currentWatchSize = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateWatchSize = flow(function* (token, watchSizeId, name, priority, status) {
        this.isSubmitting = true
        try {
            const response = yield api.updateWatchSize(token, watchSizeId, name, priority, status)
            const index = this.watchSizes.findIndex((b) => {
                return b._id === watchSizeId
            })
            this.watchSizes[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(error.response.data.messages)
        }
    })

    deleteWatchSize = flow(function* (token, watchSizeId) {
        this.isSubmitting = true
        try {
            yield api.removeWatchSize(token, watchSizeId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new WatchSizeStore()
