import React, { Component } from 'react'
import styled from 'styled-components'
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Menu } from 'antd';
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { withRouter } from 'react-router-dom'

const SiderWrapper = styled(Menu)`
    min-height: 100vh;
`

class Sider extends Component {

    handleOnMenuItemClick(e) {
        console.log(e.key)
        console.log(e.item.props.route)
        if (e.key === 'home') {
            this.props.history.push(`/`)
        } else {
            this.props.history.push(`/${e.item.props.route}`)
        }
    }

    getSiderItems() {
        return [
            {
                key: 'watch',
                value: 'watch',
                text: messages.watch,
                icon: 'clock-circle',
                subMenu: [
                    {
                        key: 'watchItem',
                        value: 'watchItem',
                        text: messages.watchItem,
                        route: 'watchItem'
                    },
                    {
                        key: 'watchBrand',
                        value: 'watchBrand',
                        text: messages.watchBrand,
                        route: 'watchBrand'
                    },
                    {
                        key: 'watchCase',
                        value: 'watchCase',
                        text: messages.watchCase,
                        route: 'watchCase'
                    },
                    {
                        key: 'watchFunction',
                        value: 'watchFunction',
                        text: messages.watchFunction,
                        route: 'watchFunction'
                    },
                    {
                        key: 'watchMovement',
                        value: 'watchMovement',
                        text: messages.watchMovement,
                        route: 'watchMovement'
                    },
                    {
                        key: 'watchSize',
                        value: 'watchSize',
                        text: messages.watchSize,
                        route: 'watchSize'
                    },
                ]
            },
            {
                key: 'store',
                value: 'store',
                text: messages.store,
                icon: 'shop',
                route: 'store'
            },
            {
                key: 'staff',
                value: 'staff',
                text: messages.staff,
                icon: 'user',
                route: 'staff'
            },
            {
                key: 'sellWatch',
                value: 'sellWatch',
                text: messages.sellWatch,
                icon: 'red-envelope',
                route: 'sellWatch'
            },
            {
                key: 'enquiry',
                value: 'enquiry',
                text: messages.enquiry,
                icon: 'message',
                route: 'enquiry'
            },
            {
                key: 'feed',
                value: 'feed',
                text: messages.feed,
                icon: 'heart',
                route: 'feed'
            },
            {
                key: 'media',
                value: 'media',
                text: messages.media,
                icon: 'youtube',
                route: 'media'
            },
            {
                key: 'testimonial',
                value: 'testimonial',
                text: messages.testimonial,
                icon: 'file',
                route: 'testimonial'
            },
            {
                key: 'settings',
                value: 'settings',
                text: messages.settings,
                icon: 'setting',
                route: 'settings'
            },
            {
                key: 'accessControl',
                value: 'accessControl',
                text: messages.accessControl,
                icon: 'control',
                route: 'accessControl'
            },
        ]
    }

    renderMenuItems() {
        const { isMobile, intl } = this.props
        return this.getSiderItems().map((item, index) => {

            if (!item.subMenu) {
                return <Menu.Item key={item.key} route={item.route}><span><LegacyIcon type={item.icon} /><span>{intl.formatMessage({ ...item.text })}</span></span></Menu.Item>;
            }

            const subMenuItems = item.subMenu.map((subMenuItem, subIndex) => {
                return <Menu.Item key={subMenuItem.key} route={subMenuItem.route}>{intl.formatMessage({ ...subMenuItem.text })}</Menu.Item>
            })
            return (
                <Menu.SubMenu key={item.key} title={<span><LegacyIcon type={item.icon} /><span>{intl.formatMessage({ ...item.text })}</span></span>}>
                    {subMenuItems}
                </Menu.SubMenu>
            );
        });
    }

    getSelectedKey() {
        if (this.props.location.pathname === '/') {
            return 'watchItem'
        }
        const tempArray = this.props.location.pathname.substring(1).split('/')
        return tempArray[0]
    }

    getOpenKey() {
        const tempArray = this.props.location.pathname.substring(1).split('/')
        const selectedPage = tempArray[0]
        switch (selectedPage) {
            case "":
            case 'watchItem':
            case 'watchBrand':
            case 'watchCase':
            case 'watchFunction':
            case 'watchMovement':
            case 'watchSize':
                return 'watch'
            default:
                return selectedPage
        }
    }

    render() {
        return (
            <SiderWrapper
                theme='dark'
                onClick={(e) => this.handleOnMenuItemClick(e)}
                style={{ maxWidth: 256 }}
                defaultSelectedKeys={[this.getSelectedKey()]}
                defaultOpenKeys={[this.getOpenKey()]}
                mode="inline">
                {
                    this.renderMenuItems()
                }
            </SiderWrapper>
        )
    }
}

export default withRouter(injectIntl(Sider))
