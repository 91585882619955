import React from 'react'
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row } from 'antd';
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import validator from '~/src/lib/validator'
import { hasError, getErrorMessage } from '~/src/lib/errorMessages'

const LoginButton = styled(Button)`
    margin-right: 16px;
`

const Error = styled.div`
    color: red;
`

class LoginForm extends React.Component {

    handleSubmit(e) {
        e.preventDefault()
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onLogin(values)
                }
            })
        }
    }

    render() {
        const { isSubmitting, error, intl } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Form.Item
                    validateStatus={hasError(error, 1000) ? 'error' : null}
                    help={getErrorMessage(intl, error, 1000)}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                        })(
                            <Input
                                prefix={<MailOutlined />}
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    validateStatus={hasError(error, 1001) ? 'error' : null}
                    help={getErrorMessage(intl, error, 1001)}>
                    {
                        getFieldDecorator('password', {
                            rules: [{ required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }],
                        })(
                            <Input
                                prefix={<LockOutlined />}
                                type="password"
                                placeholder={intl.formatMessage({ ...messages.password })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item>
                    <LoginButton type="primary" block htmlType="submit" loading={isSubmitting}>
                        {intl.formatMessage({ ...messages.login })}
                    </LoginButton>
                </Form.Item>
                <Form.Item>
                    <Row type='flex' justify='center'>
                        <a href="/forgotPassword" style={{ textAlign: 'center' }}>{intl.formatMessage({ ...messages.forgotPassword })}</a>
                    </Row>
                </Form.Item>
                {
                    hasError(error, 1003)
                        ? (
                            <Form.Item>
                                <Error>{getErrorMessage(intl, error, 1003)}</Error>
                            </Form.Item>
                        )
                        : null

                }
            </Form>
        );
    }
}

export default injectIntl(Form.create()(LoginForm))