import { InputNumber, Row, Col } from 'antd'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import messages from '~/src/messages'

const Wrapper = styled.div`

`

const InputWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 2px;
    align-items: center;
`

const LabelWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    padding: 0px 11px;
    line-height: 2.2;
    font-size: 14px;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #fafafa;
    color: rgba(0,0,0,0.65);
`


class InstalmentRateInput extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { intl, disabled, value, onChange } = this.props
        console.log(value)
        return (
            <Wrapper>
                <InputWrapper>
                    <LabelWrapper>{`${intl.formatMessage({ ...messages.INSTAL12 })} (%)`}</LabelWrapper>
                    <InputNumber
                        value={value['INSTAL12']}
                        min={0}
                        max={100}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={(e) => onChange({ ...value, 'INSTAL12': e })}
                    />
                </InputWrapper>
                <InputWrapper>
                    <LabelWrapper>{`${intl.formatMessage({ ...messages.INSTAL24 })} (%)`}</LabelWrapper>
                    <InputNumber
                        value={value['INSTAL24']}
                        min={0}
                        max={100}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={(e) => onChange({ ...value, 'INSTAL24': e })}
                    />
                </InputWrapper>
                <InputWrapper>
                    <LabelWrapper>{`${intl.formatMessage({ ...messages.INSTAL36 })} (%)`}</LabelWrapper>
                    <InputNumber
                        value={value['INSTAL36']}
                        min={0}
                        max={100}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={(e) => onChange({ ...value, 'INSTAL36': e })}
                    />
                </InputWrapper>
            </Wrapper>
        )
    }
}

export default injectIntl(InstalmentRateInput)