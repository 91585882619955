import { observable, flow } from 'mobx'
import api from '../api'

class FilterStore {
    @observable isSubmitting = false
    @observable error = null
    @observable allFilterOptions = undefined
    @observable watchBrands = []
    @observable watchSizes = []
    @observable watchFunctions = []
    @observable watchCases = []
    @observable watchMovements = []
    @observable stores = []

    listFilter = flow(function* (token) {
        this.isSubmitting = true
        try {
            const response = yield api.listFilter(token)
            const { watchBrands, watchSizes, watchFunctions, watchCases, watchMovements, stores } = response.data
            this.allFilterOptions = response.data
            this.watchBrands = watchBrands
            this.watchSizes = watchSizes
            this.watchFunctions = watchFunctions
            this.watchCases = watchCases
            this.watchMovements = watchMovements
            this.stores = stores
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

}

export default new FilterStore()
