import {
    Breadcrumb,
    message
} from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { withRouter } from "react-router"
import styled from 'styled-components'
import messages from '~/src/messages'
import WatchFunctionForm from '~/src/components/forms/watchFunctionForm'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`


@inject('commonStore', 'watchFunctionStore') @observer
class WatchFunctionDetailPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {
        const { watchFunctionStore, commonStore, match } = this.props
        const { token } = commonStore
        const { watchFunctionId } = match.params
        await watchFunctionStore.getWatchFunction(token, watchFunctionId)
    }


    handleOnBackClick() {
        this.props.history.goBack()
    }

    async handleOnWatchFunctionFormSubmit(values) {
        const { watchFunctionStore, commonStore, intl, match } = this.props
        const { token } = commonStore
        const { watchFunctionId } = match.params
        const { description, priority, status } = values
        try {
            await watchFunctionStore.updateWatchFunction(token, watchFunctionId, description, priority, status)
            message.success(intl.formatMessage({ ...messages.success }))
        } catch (e) {
            if (e) {
                return message.error(e)
            }
            return message.error(intl.formatMessage({ ...messages.failure }))
        }
    }


    render() {
        const { watchFunctionStore, intl, commonStore } = this.props
        console.log(toJS(watchFunctionStore.currentWatchFunction))
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.updateWatchFunction })}</title>
                </Helmet>
                <Breadcrumb>
                    {/* <Breadcrumb.Item onClick={() => this.handleOnBackClick()}>
                        <a>{intl.formatMessage({ ...messages.back })}</a>
                    </Breadcrumb.Item> */}
                    <Breadcrumb.Item>
                        {intl.formatMessage({ ...messages.updateWatchFunction })}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <WatchFunctionForm
                    token={commonStore.token}
                    initial={watchFunctionStore.currentWatchFunction}
                    isSubmitting={watchFunctionStore.isSubmitting}
                    onSubmit={(values) => this.handleOnWatchFunctionFormSubmit(values)} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(WatchFunctionDetailPage))