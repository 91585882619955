import {
    Breadcrumb, message
} from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { withRouter } from "react-router"
import styled from 'styled-components'
import WatchItemForm from '~/src/components/forms/watchItemForm'
import messages from '~/src/messages'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`


@inject('commonStore', 'settingsStore', 'watchItemStore', 'filterStore') @observer
class WatchItemCreatePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {
        const { watchItemStore, settingsStore, commonStore, match, filterStore } = this.props
        const { token, user } = commonStore
        await settingsStore.getSettings(token)
        console.log(toJS(user))
        const { watchItemId } = match.params
        await filterStore.listFilter(token)
        // await watchItemStore.getWatchItem(token, watchItemId)
    }

    handleOnBackClick() {
        this.props.history.goBack()
    }

    async handleOnModelRefNumberSearch(value) {
        const { watchItemStore, commonStore } = this.props
        const { token } = commonStore
        // await watchItemStore.getWatchItemByModelRefNumber(token, value)
        await watchItemStore.modelRefNumberAutocomplete(token, value)
    }

    async handleOnModelRefNumberChange(value) {
        const { watchItemStore, commonStore } = this.props
        const { token } = commonStore
        await watchItemStore.getWatchItemByModelRefNumber(token, value)
    }

    async handleOnWatchItemFormSubmit(values, reset) {
        const { watchItemStore, commonStore, intl } = this.props
        const { token } = commonStore
        try {
            console.log(values)
            await watchItemStore.createWatchItem(token, values)
            message.success(intl.formatMessage({ ...messages.success }))
            reset()
            await watchItemStore.resetCurrentWatchItem()
            // this.props.history.goBack()
        } catch (e) {
            console.log(e)
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }

    async handleOnFieldSearch(field, lang, value) {
        const { watchItemStore, commonStore, intl } = this.props
        const { token } = commonStore
        try {
            await watchItemStore.remarkAutocomplete(token, field, lang, value)
        } catch (e) {
            console.log(e)
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }

    render() {
        const { watchItemStore, filterStore, intl, commonStore, settingsStore } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.addNewWatchItem })}</title>
                </Helmet>
                <Breadcrumb>
                    {/* <Breadcrumb.Item onClick={() => this.handleOnBackClick()}>
                        <a>{intl.formatMessage({ ...messages.back })}</a>
                    </Breadcrumb.Item> */}
                    <Breadcrumb.Item>
                        {intl.formatMessage({ ...messages.addNewWatchItem })}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <WatchItemForm
                    token={commonStore.token}
                    user={commonStore.user}
                    modelRefNumberSearchVisible={true}
                    modelRefNumberOptions={toJS(watchItemStore.modelRefNumberOptions)}
                    sizeRemarkOptions={toJS(watchItemStore.sizeRemarkOptions)}
                    remarkOptions={toJS(watchItemStore.remarkOptions)}
                    modelOptions={toJS(watchItemStore.modelOptions)}
                    priceRemarkOptions={toJS(watchItemStore.priceRemarkOptions)}
                    cashRemarkOptions={toJS(watchItemStore.cashRemarkOptions)}
                    isSubmitting={watchItemStore.isSubmitting}
                    instalmentRate={settingsStore.settings ? settingsStore.settings.instalmentRate : undefined}
                    filterOptions={filterStore.allFilterOptions}
                    onSubmit={(values, reset) => this.handleOnWatchItemFormSubmit(values, reset)}
                    initial={watchItemStore.currentWatchItem}
                    onModelRefNumberSearch={(value) => this.handleOnModelRefNumberSearch(value)}
                    onModelRefNumberChange={(value) => this.handleOnModelRefNumberChange(value)}
                    onFieldSearch={(field, lang, value) => this.handleOnFieldSearch(field, lang, value)}
                    resetCurrentWatchItem={async () => await watchItemStore.resetCurrentWatchItem()}
                />
            </Container>
        )
    }
}

export default withRouter(injectIntl(WatchItemCreatePage))