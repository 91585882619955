import { Breadcrumb, message } from "antd";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import styled from "styled-components";
import messages from "~/src/messages";

import { Helmet } from "react-helmet";
import WatchItemForm from "~/src/components/forms/watchItemForm";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
`;

@inject("commonStore", "settingsStore", "watchItemStore", "filterStore")
@observer
class WatchItemDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { watchItemStore, settingsStore, commonStore, match, filterStore } =
      this.props;
    const { token } = commonStore;
    await settingsStore.getSettings(token);
    const { watchItemId } = match.params;
    await filterStore.listFilter(token);
    await watchItemStore.getWatchItem(token, watchItemId);
  }

  async componentWillUnmount() {
    const { watchItemStore } = this.props;
    await watchItemStore.resetCurrentWatchItem();
  }

  handleOnBackClick() {
    this.props.history.goBack();
  }

  async handleOnWatchItemFormSubmit(values, reset) {
    const { watchItemStore, commonStore, intl, match } = this.props;
    const { watchItemId } = match.params;
    const { token } = commonStore;
    try {
      await watchItemStore.updateWatchItem(token, watchItemId, values);
      message.success(intl.formatMessage({ ...messages.success }));
      reset();
      await watchItemStore.resetCurrentWatchItem();
      // this.props.history.goBack()
    } catch (e) {
      message.error(intl.formatMessage({ ...messages.failure }));
    }
  }

  async handleOnFieldSearch(field, lang, value) {
    const { watchItemStore, commonStore, intl } = this.props;
    const { token } = commonStore;
    try {
      await watchItemStore.remarkAutocomplete(token, field, lang, value);
    } catch (e) {
      console.log(e);
      message.error(intl.formatMessage({ ...messages.failure }));
    }
  }

  async handleOnWatchItemPublishChange(value) {
    const { watchItemStore, commonStore, intl, match } = this.props;
    const { watchItemId } = match.params;
    const { token } = commonStore;
    try {
      await watchItemStore.updateWatchItemPublishStatus(
        token,
        watchItemId,
        value
      );
      message.success(intl.formatMessage({ ...messages.success }));
    } catch (e) {
      message.error(intl.formatMessage({ ...messages.failure }));
    }
  }

  render() {
    const { watchItemStore, filterStore, intl, commonStore, settingsStore } =
      this.props;
    console.log(toJS(filterStore.allFilterOptions));
    return (
      <Container>
        <Helmet>
          <title>{intl.formatMessage({ ...messages.updateWatchItem })}</title>
        </Helmet>
        <Breadcrumb>
          {/* <Breadcrumb.Item onClick={() => this.handleOnBackClick()}>
                        <a>{intl.formatMessage({ ...messages.back })}</a>
                    </Breadcrumb.Item> */}
          <Breadcrumb.Item>
            {intl.formatMessage({ ...messages.updateWatchItem })}
          </Breadcrumb.Item>
        </Breadcrumb>
        <WatchItemForm
          user={commonStore.user}
          token={commonStore.token}
          modelRefNumberSearchVisible={false}
          sizeRemarkOptions={toJS(watchItemStore.sizeRemarkOptions)}
          remarkOptions={toJS(watchItemStore.remarkOptions)}
          modelOptions={toJS(watchItemStore.modelOptions)}
          priceRemarkOptions={toJS(watchItemStore.priceRemarkOptions)}
          cashRemarkOptions={toJS(watchItemStore.cashRemarkOptions)}
          isSubmitting={watchItemStore.isSubmitting}
          instalmentRate={
            settingsStore.settings
              ? settingsStore.settings.instalmentRate
              : undefined
          }
          filterOptions={filterStore.allFilterOptions}
          initial={watchItemStore.currentWatchItem}
          onSubmit={(values, reset) =>
            this.handleOnWatchItemFormSubmit(values, reset)
          }
          onPublishChange={(value) =>
            this.handleOnWatchItemPublishChange(value)
          }
          onFieldSearch={(field, lang, value) =>
            this.handleOnFieldSearch(field, lang, value)
          }
          resetCurrentWatchItem={async () =>
            await watchItemStore.resetCurrentWatchItem()
          }
        />
      </Container>
    );
  }
}

export default withRouter(injectIntl(WatchItemDetailPage));
