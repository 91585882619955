import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Col, Modal, Row } from "antd";
import { Component } from "react";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import NameDisplaySection from "~/src/components/nameDisplaySection";
import { DATE_FORMAT } from "~/src/constants/format";
import {
  watchItemBehaviourOptions,
  watchItemConditionOptions,
  watchItemPaymentTypeOptions,
  watchItemStatusOptions,
  watchItemTypeOptions,
} from "~/src/constants/options";
import messages from "~/src/messages";
import moment from "moment";
import React from "react";

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
  margin: 8px;
`;

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

class WatchItemPreviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImage: undefined,
      previewVisible: false,
    };
  }

  handlePreviewCancel() {
    this.setState({ previewVisible: false, previewImage: undefined });
  }

  handlePreview(url) {
    this.setState({
      previewImage: url,
      previewVisible: true,
    });
  }

  render() {
    const { intl, visible, isSubmitting, watchItem } = this.props;
    if (!watchItem) {
      return null;
    }
    const {
      status,
      type,
      watchBrand,
      brandRemark,
      modelRefNumber,
      model,
      watchSize,
      sizeRemark,
      watchFunction,
      watchCase,
      watchMovement,
      material,
      remark,
      year,
      paymentType,
      listedPrice,
      priceRemark,
      cash,
      cashRemark,
      behaviours,
      conditions,
      mainPhoto,
      photos,
      store,
      isPublished,
      priority,
      createdAt,
      updatedAt,
      publishedAt
    } = watchItem;

    var mainPhotoUrl;
    var photoUrls = [];
    photos.forEach((p) => {
      const url = `${IMAGE_URL}/${p}`;
      const splitResult = p.split("/");
      const fileName = splitResult[splitResult.length - 1];
      if (mainPhoto === fileName) {
        mainPhotoUrl = url;
      } else {
        photoUrls.push(url);
      }
    });
    console.log(mainPhotoUrl);
    console.log(photoUrls);
    const typeIndex = watchItemTypeOptions.findIndex((o) => o.value === type);
    const typeText =
      typeIndex > -1
        ? intl.formatMessage({ ...watchItemTypeOptions[typeIndex].text })
        : "-";

    const statusIndex = watchItemStatusOptions.findIndex(
      (o) => o.value === status
    );
    const statusText =
      statusIndex > -1
        ? intl.formatMessage({ ...watchItemStatusOptions[statusIndex].text })
        : "-";

    const behaviourTexts = behaviours.map((b) => {
      const i = watchItemBehaviourOptions.findIndex((o) => o.value === b);
      return i > -1
        ? intl.formatMessage({ ...watchItemBehaviourOptions[i].text })
        : "-";
    });

    const conditionTexts = conditions.map((b) => {
      const i = watchItemConditionOptions.findIndex((o) => o.value === b);
      return i > -1
        ? intl.formatMessage({ ...watchItemConditionOptions[i].text })
        : "-";
    });

    const paymentTypeIndex = watchItemPaymentTypeOptions.findIndex(
      (o) => o.value === paymentType
    );
    const paymentTypeText =
      paymentTypeIndex > -1
        ? intl.formatMessage({
            ...watchItemPaymentTypeOptions[paymentTypeIndex].text,
          })
        : "-";

    return (
      <Modal
        visible={visible}
        mask
        maskClosable={false}
        centered
        width={960}
        title={intl.formatMessage({ ...messages.watchItem })}
        footer={null}
        onCancel={() => {
          this.props.onClose();
        }}
      >
        <Form>
          <Row gutter={24}>
            <Col span={12}>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={intl.formatMessage({ ...messages.type })}>
                    {typeText}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={intl.formatMessage({ ...messages.status })}>
                    {statusText}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={intl.formatMessage({ ...messages.behaviour })}>
                {behaviourTexts.join(", ")}
              </Form.Item>
              <Form.Item label={intl.formatMessage({ ...messages.condition })}>
                {conditionTexts.join(", ")}
              </Form.Item>

              <Form.Item label={intl.formatMessage({ ...messages.store })}>
                {store ? store.name["en-US"] : "-"}
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ ...messages.paymentType })}
              >
                {paymentTypeText}
              </Form.Item>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    label={intl.formatMessage({ ...messages.listedPrice })}
                  >
                    {`$${listedPrice || "-"}`}
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item
                    label={intl.formatMessage({ ...messages.priceRemark })}
                  >
                    {priceRemark}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label={intl.formatMessage({ ...messages.cash })}>
                    {`$${cash || "-"}`}
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item
                    label={intl.formatMessage({ ...messages.cashRemark })}
                  >
                    {cashRemark}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label={intl.formatMessage({ ...messages.priority })}
                  >
                    {priority}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={intl.formatMessage({ ...messages.isPublished })}
                  >
                    {isPublished ? (
                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                    ) : (
                      <CloseCircleTwoTone twoToneColor="red" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={intl.formatMessage({ ...messages.remark })}>
                <NameDisplaySection temp={1} value={remark ? remark : {}} />
              </Form.Item>

              <Form.Item label={intl.formatMessage({ ...messages.mainPhoto })}>
                <Image
                  src={mainPhotoUrl}
                  onClick={() => this.handlePreview(mainPhotoUrl)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={intl.formatMessage({ ...messages.watchBrand })}>
                <NameDisplaySection
                  temp={2}
                  value={watchBrand ? watchBrand.name : {}}
                />
              </Form.Item>
              <Form.Item>{brandRemark}</Form.Item>
              <Form.Item label={intl.formatMessage({ ...messages.model })}>
                <NameDisplaySection temp={3} value={model ? model : {}} />
              </Form.Item>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label={intl.formatMessage({ ...messages.modelRefNumber })}
                  >
                    {modelRefNumber}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={intl.formatMessage({ ...messages.year })}>
                    {year}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={intl.formatMessage({ ...messages.watchCase })}>
                <NameDisplaySection
                  temp={4}
                  value={watchCase ? watchCase.name : {}}
                />
              </Form.Item>

              <Form.Item
                label={intl.formatMessage({ ...messages.watchFunction })}
              >
                <NameDisplaySection
                  temp={5}
                  value={watchFunction ? watchFunction.description : {}}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ ...messages.watchMovement })}
              >
                <NameDisplaySection
                  temp={6}
                  value={watchMovement ? watchMovement.description : {}}
                />
              </Form.Item>
              <Form.Item label={intl.formatMessage({ ...messages.watchSize })}>
                <NameDisplaySection
                  temp={7}
                  value={watchSize ? watchSize.name : {}}
                />
              </Form.Item>
              <Form.Item label={intl.formatMessage({ ...messages.sizeRemark })}>
                <NameDisplaySection
                  temp={8}
                  value={sizeRemark ? sizeRemark : {}}
                />
              </Form.Item>
              <Form.Item label={intl.formatMessage({ ...messages.material })}>
                <NameDisplaySection temp={9} value={material ? material : {}} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={intl.formatMessage({ ...messages.photos })}>
            <Row>
              {photoUrls.map((p, i) => {
                return (
                  <Image
                    src={p}
                    key={i}
                    onClick={() => this.handlePreview(p)}
                  />
                );
              })}
            </Row>
          </Form.Item>
        </Form>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label={intl.formatMessage({ ...messages.createdAt })}>
              {moment(createdAt).format(DATE_FORMAT)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={intl.formatMessage({ ...messages.updatedAt })}>
              {moment(updatedAt).format(DATE_FORMAT)}
            </Form.Item>
          </Col>
          {isPublished && publishedAt ? (
            <Col span={12}>
              <Form.Item label={intl.formatMessage({ ...messages.publishedAt })}>
                {moment(publishedAt).format(DATE_FORMAT)}
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Modal
          visible={this.state.previewVisible}
          footer={null}
          onCancel={() => this.handlePreviewCancel()}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </Modal>
    );
  }
}

export default injectIntl(WatchItemPreviewModal);
