import { observable, flow } from 'mobx'
import api from '../api'

class WatchFunctionStore {
    @observable isSubmitting = false
    @observable error = null
    @observable watchFunctions = []
    @observable count = 0
    @observable currentWatchFunction = undefined

    createWatchFunction = flow(function* (token, description, priority) {
        this.isSubmitting = true
        try {
            yield api.createWatchFunction(token, description, priority)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(error.response.data.messages)
        }
    })

    listWatchFunction = flow(function* (token, limit, skip, search, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listWatchFunction(token, limit, skip, search, status)
            const { watchFunctions, count } = response.data
            this.watchFunctions = watchFunctions
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getWatchFunction = flow(function* (token, watchFunctionId) {
        this.isSubmitting = true
        try {
            const response = yield api.getWatchFunction(token, watchFunctionId)
            this.currentWatchFunction = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateWatchFunction = flow(function* (token, watchFunctionId, description, priority, status) {
        this.isSubmitting = true
        try {
            const response = yield api.updateWatchFunction(token, watchFunctionId, description, priority, status)
            const index = this.watchFunctions.findIndex((b) => {
                return b._id === watchFunctionId
            })
            this.watchFunctions[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(error.response.data.messages)
        }
    })

    deleteWatchFunction = flow(function* (token, watchFunctionId) {
        this.isSubmitting = true
        try {
            yield api.removeWatchFunction(token, watchFunctionId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new WatchFunctionStore()
