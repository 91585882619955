import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Upload,
  AutoComplete,
} from "antd";
import moment from "moment";
import React from "react";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import api from "~/src/api";
import { toJS } from "mobx";
import InstalmentInput from "~/src/components/instalmentInput";
import NameInput from "~/src/components/nameInput";
import {
  watchItemBehaviourOptions,
  watchItemConditionOptions,
  watchItemPaymentTypeOptions,
  watchItemStatusOptions,
  watchItemTypeOptions,
} from "~/src/constants/options";
import messages from "~/src/messages";
import { initial } from "lodash";

const FormWrapper = styled.div`
  background-color: white;
  padding: 16px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

class WatchItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImage: undefined,
      previewVisible: false,
      modelRefNumberSearchVisible: props.modelRefNumberSearchVisible,
    };
  }

  handleSubmit() {
    const { isSubmitting, initial } = this.props;
    if (!isSubmitting) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit(
            {
              ...values,
              isPublished: initial ? initial["isPublished"] : false,
            },
            () => {
              this.props.form.resetFields();
            }
          );
        }
      });
    }
  }

  handlePublishChange() {
    this.props.onPublishChange(!this.props.initial["isPublished"]);
  }

  resetForm() {
    this.props.form.resetFields();
    this.props.resetCurrentWatchItem();
  }

  normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  handlePreviewCancel() {
    this.setState({ previewVisible: false });
  }

  handlePreview(file) {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }

  async handleOnTranslateClick(field) {
    const { token } = this.props;
    const { getFieldValue, setFieldsValue } = this.props.form;
    const formValues = getFieldValue(field);
    const TW = formValues["zh-TW"];
    try {
      const response = await api.translate(token, TW);
      if (response.status == 200) {
        setFieldsValue({ [field]: { ...formValues, "zh-CN": response.data } });
      }
    } catch (e) {
      console.log(e);
    }
  }

  canPublish() {
    const { user } = this.props;
    return toJS(user.rbac)
      .find((r) => {
        return r.module == "ITEMS";
      })
      .accessControl.includes("PUBLISH");
  }

  canReadInstalment() {
    const { user } = this.props;
    return toJS(user.rbac)
      .find((r) => {
        return r.module == "INSTALMENT_RATE";
      })
      .accessControl.includes("READ");
  }

  render() {
    const {
      isSubmitting,
      intl,
      filterOptions,
      initial,
      instalmentRate,
      modelRefNumberOptions,
      modelOptions,
      sizeRemarkOptions,
      remarkOptions,
      priceRemarkOptions,
      cashRemarkOptions,
    } = this.props;
    const { modelRefNumberSearchVisible } = this.state;
    console.log(toJS(initial));
    console.log(this.props.form.getFieldValue("cashRemark"));
    const { getFieldDecorator, getFieldValue } = this.props.form;
    if (!filterOptions) {
      return null;
    }

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">
          {intl.formatMessage({ ...messages.uploadPhoto })}
        </div>
      </div>
    );
    var mainPhotoUrl;
    var photoUrls = [];
    if (initial) {
      initial.photos.forEach((p) => {
        const url = `${IMAGE_URL}/${p}`;
        const splitResult = p.split("/");
        const fileName = splitResult[splitResult.length - 1];
        if (initial.mainPhoto === fileName) {
          mainPhotoUrl = url;
        } else {
          photoUrls.push(url);
        }
      });
    }

    return (
      <FormWrapper>
        <Form>
          {/* <Row gutter={24}> */}
          {/* <Col span={12}> */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={intl.formatMessage({ ...messages.type })}>
                {getFieldDecorator("type", {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ ...messages.pleaseSelect }),
                    },
                  ],
                  initialValue: initial ? initial.type : undefined,
                })(
                  <Select
                    placeholder={intl.formatMessage({
                      ...messages.pleaseSelect,
                    })}
                    disabled={isSubmitting}
                  >
                    {watchItemTypeOptions.map((item, index) => {
                      return (
                        <Select.Option value={item.value} key={index}>
                          {intl.formatMessage({ ...item.text })}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={intl.formatMessage({ ...messages.status })}>
                {getFieldDecorator("status", {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ ...messages.pleaseSelect }),
                    },
                  ],
                  initialValue: initial ? initial.status : undefined,
                })(
                  <Select
                    placeholder={intl.formatMessage({
                      ...messages.pleaseSelect,
                    })}
                    disabled={isSubmitting}
                  >
                    {watchItemStatusOptions.map((item, index) => {
                      return (
                        <Select.Option value={item.value} key={index}>
                          {intl.formatMessage({ ...item.text })}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={intl.formatMessage({ ...messages.watchBrand })}>
            {getFieldDecorator("watchBrand", {
              rules: [
                {
                  required: false,
                  message: intl.formatMessage({ ...messages.pleaseSelect }),
                },
              ],
              initialValue:
                initial && initial.watchBrand
                  ? initial.watchBrand._id
                  : undefined,
            })(
              <Select
                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={isSubmitting}
              >
                {filterOptions.watchBrands.map((item, index) => {
                  return (
                    <Select.Option value={item._id} key={index}>
                      {item.name["en-US"]}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          {/* <Form.Item>
                        {
                            getFieldDecorator('brandRemark', {
                                initialValue: initial ? initial.brandRemark : undefined
                            })(
                                <Input
                                    placeholder={intl.formatMessage({ ...messages.brandRemark })}
                                    disabled={isSubmitting} />
                            )
                        }
                    </Form.Item> */}
          <Form.Item label={intl.formatMessage({ ...messages.model })}>
            {getFieldDecorator("model", {
              rules: [
                {
                  required: false,
                  message: intl.formatMessage({ ...messages.pleaseInput }),
                },
              ],
              initialValue: initial
                ? initial.model
                : {
                    "en-US": undefined,
                    "zh-TW": undefined,
                    "zh-CN": undefined,
                  },
            })(
              <NameInput
                isSelection={true}
                options={modelOptions}
                onFieldSearch={(lang, value) => {
                  this.props.onFieldSearch(`model`, lang, value);
                }}
                onTranslateClick={() => this.handleOnTranslateClick("model")}
                disabled={isSubmitting}
              />
            )}
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={intl.formatMessage({ ...messages.modelRefNumber })}
              >
                {getFieldDecorator("modelRefNumber", {
                  initialValue: initial ? initial.modelRefNumber : undefined,
                })(
                  modelRefNumberSearchVisible ? (
                    <AutoComplete
                      options={modelRefNumberOptions}
                      //   onSelect={(value) =>
                      //     this.props.onModelRefNumberChange(value)
                      //   }
                      onSearch={(value) =>
                        this.props.onModelRefNumberSearch(value)
                      }
                      searchValue={""}
                    >
                      <Input.Search
                        onSearch={(value) =>
                          this.props.onModelRefNumberChange(value)
                        }
                        placeholder={intl.formatMessage({
                          ...messages.modelRefNumber,
                        })}
                        enterButton
                        disabled={isSubmitting}
                      />
                    </AutoComplete>
                  ) : (
                    // <Select
                    //   showSearch
                    //   showArrow={false}
                    //   filterOption={false}
                    //   onSearch={(value) =>
                    //     this.props.onModelRefNumberSearch(value)
                    //   }
                    //   onChange={(value) =>
                    //     this.props.onModelRefNumberChange(value)
                    //   }
                    //   disabled={isSubmitting}
                    // >
                    //   {modelRefNumberOptions.map((f) => {
                    //     return (
                    //       <Select.Option key={f.modelRefNumber}>
                    //         {f.modelRefNumber}
                    //       </Select.Option>
                    //     );
                    //   })}
                    // </Select>
                    <Input
                      placeholder={intl.formatMessage({
                        ...messages.modelRefNumber,
                      })}
                      disabled={isSubmitting}
                    />
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={intl.formatMessage({ ...messages.year })}>
                {getFieldDecorator("year", {
                  initialValue: initial ? initial.year : undefined,
                })(
                  <InputNumber
                    placeholder={intl.formatMessage({ ...messages.year })}
                    min={1900}
                    max={moment().year()}
                    disabled={isSubmitting}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={intl.formatMessage({ ...messages.watchCase })}>
            {getFieldDecorator("watchCases", {
              rules: [
                {
                  required: false,
                  message: intl.formatMessage({ ...messages.pleaseSelect }),
                },
              ],
              initialValue:
                initial && initial.watchCases.length
                  ? initial.watchCases.map((f) => {
                      return f._id;
                    })
                  : [],
            })(
              <Select
                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                mode="multiple"
                disabled={isSubmitting}
              >
                {filterOptions.watchCases.map((item, index) => {
                  return (
                    <Select.Option value={item._id} key={index}>
                      {item.name["en-US"]}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={intl.formatMessage({ ...messages.watchFunction })}>
            {getFieldDecorator("watchFunctions", {
              rules: [
                {
                  required: false,
                  message: intl.formatMessage({ ...messages.pleaseSelect }),
                },
              ],
              initialValue:
                initial && initial.watchFunctions.length
                  ? initial.watchFunctions.map((f) => {
                      return f._id;
                    })
                  : [],
            })(
              <Select
                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                mode="multiple"
                disabled={isSubmitting}
              >
                {filterOptions.watchFunctions.map((item, index) => {
                  return (
                    <Select.Option value={item._id} key={index}>
                      {item.description["en-US"]}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ ...messages.watchMovement })}>
            {getFieldDecorator("watchMovement", {
              rules: [
                {
                  required: false,
                  message: intl.formatMessage({ ...messages.pleaseSelect }),
                },
              ],
              initialValue:
                initial && initial.watchMovement
                  ? initial.watchMovement._id
                  : undefined,
            })(
              <Select
                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={isSubmitting}
              >
                {filterOptions.watchMovements.map((item, index) => {
                  return (
                    <Select.Option value={item._id} key={index}>
                      {item.description["en-US"]}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ ...messages.watchSize })}>
            {getFieldDecorator("watchSize", {
              rules: [
                {
                  required: false,
                  message: intl.formatMessage({ ...messages.pleaseSelect }),
                },
              ],
              initialValue:
                initial && initial.watchSize
                  ? initial.watchSize._id
                  : undefined,
            })(
              <Select
                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={isSubmitting}
              >
                {filterOptions.watchSizes.map((item, index) => {
                  return (
                    <Select.Option value={item._id} key={index}>
                      {item.name["en-US"]}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ ...messages.sizeRemark })}>
            {getFieldDecorator("sizeRemark", {
              initialValue: initial
                ? initial.sizeRemark
                : {
                    "en-US": undefined,
                    "zh-TW": undefined,
                    "zh-CN": undefined,
                  },
            })(
              <NameInput
                isSelection={true}
                options={sizeRemarkOptions}
                onFieldSearch={(lang, value) => {
                  this.props.onFieldSearch(`sizeRemark`, lang, value);
                }}
                onTranslateClick={() =>
                  this.handleOnTranslateClick("sizeRemark")
                }
                disabled={isSubmitting}
              />
            )}
          </Form.Item>
          <Form.Item label={""}>
            {getFieldDecorator("behaviours", {
              initialValue: initial ? initial.behaviours : undefined,
            })(
              <Checkbox.Group
                disabled={isSubmitting}
                options={watchItemBehaviourOptions.map((item, index) => {
                  return {
                    value: item.value,
                    label: intl.formatMessage({ ...item.text }),
                  };
                })}
              />
            )}
          </Form.Item>
          <Form.Item label={""}>
            {getFieldDecorator("conditions", {
              initialValue: initial ? initial.conditions : undefined,
            })(
              <Checkbox.Group
                disabled={isSubmitting}
                options={watchItemConditionOptions.map((item, index) => {
                  return {
                    value: item.value,
                    label: intl.formatMessage({ ...item.text }),
                  };
                })}
              />
            )}
          </Form.Item>

          <Form.Item label={intl.formatMessage({ ...messages.store })}>
            {getFieldDecorator("store", {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ ...messages.pleaseSelect }),
                },
              ],
              initialValue:
                initial && initial.store ? initial.store._id : undefined,
            })(
              <Select
                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={isSubmitting}
              >
                {filterOptions.stores.map((item, index) => {
                  return (
                    <Select.Option value={item._id} key={index}>
                      {item.name["en-US"]}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ ...messages.paymentType })}>
            {getFieldDecorator("paymentType", {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ ...messages.pleaseSelect }),
                },
              ],
              initialValue: initial ? initial.paymentType : undefined,
            })(
              <Select
                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                disabled={isSubmitting}
              >
                {watchItemPaymentTypeOptions.map((item, index) => {
                  return (
                    <Select.Option value={item.value} key={index}>
                      {intl.formatMessage({ ...item.text })}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label={intl.formatMessage({ ...messages.listedPrice })}
              >
                {getFieldDecorator("listedPrice", {
                  initialValue: initial ? initial.listedPrice : undefined,
                })(
                  <InputNumber
                    placeholder={intl.formatMessage({
                      ...messages.listedPrice,
                    })}
                    min={0}
                    disabled={isSubmitting}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item
                label={intl.formatMessage({ ...messages.priceRemark })}
              >
                {getFieldDecorator("priceRemark", {
                  initialValue: initial ? initial.priceRemark : undefined,
                })(
                  <AutoComplete
                    options={priceRemarkOptions}
                    // onSelect={(selected) => onChange({ ...value, selected })}
                    onSearch={(value) =>
                      this.props.onFieldSearch(`priceRemark`, undefined, value)
                    }
                    searchValue={""}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        ...messages.priceRemark,
                      })}
                      disabled={isSubmitting}
                    />
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item label={intl.formatMessage({ ...messages.cash })}>
                {getFieldDecorator("cash", {
                  initialValue: initial ? initial.cash : undefined,
                })(
                  <InputNumber
                    placeholder={intl.formatMessage({ ...messages.cash })}
                    min={0}
                    disabled={isSubmitting}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item label={intl.formatMessage({ ...messages.cashRemark })}>
                {getFieldDecorator("cashRemark", {
                  initialValue: initial ? initial.cashRemark : undefined,
                })(
                  <AutoComplete
                    options={cashRemarkOptions}
                    // onSelect={(selected) => onChange({ ...value, selected })}
                    onSearch={(value) =>
                      this.props.onFieldSearch(`cashRemark`, undefined, value)
                    }
                    searchValue={""}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        ...messages.cashRemark,
                      })}
                      disabled={isSubmitting}
                    />
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={intl.formatMessage({ ...messages.instalments })}>
            {getFieldDecorator("instalments", {
              initialValue: initial
                ? initial.instalments
                  ? {
                      rate: instalmentRate,
                      amount: initial.instalments.amount
                        ? initial.instalments.amount
                        : undefined,
                      types: initial.instalments.types
                        ? initial.instalments.types
                        : [],
                    }
                  : { rate: instalmentRate, amount: undefined, types: [] }
                : { rate: instalmentRate, amount: undefined, types: [] },
            })(
              <InstalmentInput
                intl={intl}
                disabled={isSubmitting || !this.canReadInstalment()}
              />
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ ...messages.remark })}>
            {getFieldDecorator("remark", {
              initialValue: initial
                ? initial.remark
                : {
                    "en-US": undefined,
                    "zh-TW": undefined,
                    "zh-CN": undefined,
                  },
            })(
              <NameInput
                isSelection={true}
                options={remarkOptions}
                onFieldSearch={(lang, value) => {
                  this.props.onFieldSearch(`remark`, lang, value);
                }}
                onTranslateClick={() => this.handleOnTranslateClick("remark")}
                disabled={isSubmitting}
              />
            )}
          </Form.Item>

          <Form.Item label={intl.formatMessage({ ...messages.mainPhoto })}>
            {getFieldDecorator(`mainPhoto`, {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ ...messages.pleaseSelect }),
                },
              ],
              valuePropName: "fileList",
              getValueFromEvent: (e) => this.normFile(e),
              initialValue:
                initial && mainPhotoUrl
                  ? [
                      {
                        uid: 0,
                        name: mainPhotoUrl,
                        status: "done",
                        url: mainPhotoUrl,
                      },
                    ]
                  : [],
            })(
              <Upload
                listType="picture-card"
                onPreview={(file) => this.handlePreview(file)}
                disabled={isSubmitting}
                beforeUpload={(file) => {
                  return false;
                }}
              >
                {getFieldValue("mainPhoto").length >= 1 ? null : uploadButton}
              </Upload>
            )}
          </Form.Item>
          {/* </Col> */}
          {/* <Col span={12}> */}

          {/* <Form.Item
                                label={intl.formatMessage({ ...messages.material })}>
                                {
                                    getFieldDecorator('material', {
                                        initialValue: initial ? initial.material : { 'en-US': undefined, 'zh-TW': undefined, 'zh-CN': undefined }
                                    })(
                                        <NameInput
                                            disabled={isSubmitting} />
                                    )
                                }
                            </Form.Item> */}
          {/* </Col> */}
          {/* </Row> */}
          <Form.Item label={intl.formatMessage({ ...messages.photos })}>
            {getFieldDecorator(`photos`, {
              valuePropName: "fileList",
              getValueFromEvent: (e) => this.normFile(e),
              initialValue:
                initial && photoUrls
                  ? photoUrls.map((p, i) => {
                      return {
                        uid: i,
                        name: p,
                        status: "done",
                        url: p,
                      };
                    })
                  : [],
            })(
              <Upload
                listType="picture-card"
                multiple={true}
                maxCount={7}
                onPreview={(file) => this.handlePreview(file)}
                disabled={isSubmitting}
                beforeUpload={(file) => {
                  return false;
                }}
              >
                {getFieldValue("photos").length >= 7 ? null : uploadButton}
              </Upload>
            )}
          </Form.Item>
          <Divider />
          {initial ? (
            <Col span={24}>
              <Row span={24}>{`Created Time: ${moment(initial.createdAt).format(
                "YYYY-MM-DD HH:mm:ss"
              )}`}</Row>
              <Row span={24}>{`Updated Time: ${moment(initial.updatedAt).format(
                "YYYY-MM-DD HH:mm:ss"
              )}`}</Row>
              {initial.isPublished && initial.publishedAt ? (
                <Row span={24}>{`Published Time: ${moment(
                  initial.publishedAt
                ).format("YYYY-MM-DD HH:mm:ss")}`}</Row>
              ) : null}
              <Divider />
            </Col>
          ) : null}

          <Form.Item>
            <RowWrapper>
              <Button
                style={{ marginRight: 16 }}
                onClick={() => this.resetForm()}
              >
                {intl.formatMessage({ ...messages.reset })}
              </Button>
              <Button
                type="primary"
                style={{ marginRight: 16 }}
                onClick={() => this.handleSubmit()}
              >
                {intl.formatMessage({ ...messages.confirm })}
              </Button>
              {this.canPublish() && initial ? (
                <Button
                  style={{ backgroundColor: "navy", color: "white" }}
                  onClick={() => this.handlePublishChange()}
                >
                  {initial["isPublished"] ? "Unpublish" : "Publish"}
                </Button>
              ) : null}
            </RowWrapper>
          </Form.Item>
        </Form>
        <Modal
          visible={this.state.previewVisible}
          footer={null}
          onCancel={() => this.handlePreviewCancel()}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </FormWrapper>
    );
  }
}

export default injectIntl(Form.create()(WatchItemForm));
