import React, { Component } from 'react'
import { Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import WatchCaseForm from '~/src/components/forms/watchCaseForm'
import { relativeTimeThreshold } from 'moment'

class WatchCaseFormModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    render() {
        const { intl, visible, isSubmitting, watchCase, token } = this.props

        const title = watchCase
            ? `${intl.formatMessage({ ...messages.update })} - ${intl.formatMessage({ ...messages.watchCase })}`
            : `${intl.formatMessage({ ...messages.addNew })} - ${intl.formatMessage({ ...messages.watchCase })}`
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width={960}
                title={title}
                okText={intl.formatMessage({ ...messages.confirm })}
                cancelText={intl.formatMessage({ ...messages.cancel })}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => {
                    this.props.onClose()
                    this.formRef.resetForm()
                }}>
                <WatchCaseForm
                    token={token}
                    initial={watchCase}
                    isSubmitting={isSubmitting}
                    onSubmit={(values, reset) => this.props.onSubmit(values, reset)}
                    wrappedComponentRef={(inst) => this.formRef = inst} />
            </Modal>
        )
    }
}

export default injectIntl(WatchCaseFormModal)