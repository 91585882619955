import React from 'react'
import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Checkbox } from 'antd';
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import validator from '~/src/lib/validator'

const SubmitButton = styled(Button)`
    margin-right: 16px;
`

class StaffPasswordForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            confirmDirty: false
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    handleConfirmBlur(e) {
        const value = e.target.value
        this.setState({ confirmDirty: this.state.confirmDirty || !!value })
    }

    validateToNextPassword(rule, value, callback) {
        const form = this.props.form
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true })
        }
        callback()
    }

    compareToFirstPassword(rule, value, callback) {
        const { form, intl } = this.props
        if (value && value !== form.getFieldValue('password')) {
            callback(intl.formatMessage({ ...messages.passwordNotMatch }))
        } else {
            callback()
        }
    }

    render() {
        const { isSubmitting, intl, staff } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Form>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        staff ? staff.name : undefined
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.newPassword })}>
                    {
                        getFieldDecorator('password', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => this.validateToNextPassword(rule, value, callback) }
                            ],
                        })(
                            <Input
                                type="password"
                                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder={intl.formatMessage({ ...messages.newPassword })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.confirmPassword })}>
                    {
                        getFieldDecorator('confirmPassword', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseConfirmPassword }) },
                                { validator: (rule, value, callback) => this.compareToFirstPassword(rule, value, callback) }
                            ],
                        })(
                            <Input
                                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder={intl.formatMessage({ ...messages.confirmPassword })}
                                onBlur={(e) => this.handleConfirmBlur(e)}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
            </Form>
        );
    }
}

export default injectIntl(Form.create()(StaffPasswordForm))