import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { PlusOutlined } from '@ant-design/icons';
import { Input, InputNumber, Select, Upload } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import api from '~/src/api';
import NameInput from '~/src/components/nameInput';
import { statusOptions } from '~/src/constants/options';
import messages from '~/src/messages';

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

class StoreDetailForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }


    async handleOnTranslateClick(field) {
        const { token } = this.props
        const { getFieldValue, setFieldsValue } = this.props.form
        const formValues = getFieldValue(field)
        const TW = formValues['zh-TW']
        try {
            const response = await api.translate(token, TW)
            if (response.status == 200) {
                setFieldsValue({ [field]: { ...formValues, "zh-CN": response.data } })
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        console.log(initial)
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">{intl.formatMessage({ ...messages.uploadPhoto })}</div>
            </div>
        )
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.locationCode })}>
                    {
                        getFieldDecorator('locationCode', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.locationCode : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.locationCode })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : { 'en-US': undefined, 'zh-TW': undefined, 'zh-CN': undefined }
                        })(
                            <NameInput
                                onTranslateClick={() => this.handleOnTranslateClick('name')}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.openHour })}>
                    {
                        getFieldDecorator('openHour', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.openHour : { 'en-US': undefined, 'zh-TW': undefined, 'zh-CN': undefined }
                        })(
                            <NameInput
                                onTranslateClick={() => this.handleOnTranslateClick('openHour')}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.tel })}>
                    {
                        getFieldDecorator('tel', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.tel : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.tel })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.address })}>
                    {
                        getFieldDecorator('address', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.address : { 'en-US': undefined, 'zh-TW': undefined, 'zh-CN': undefined }
                        })(
                            <NameInput
                                onTranslateClick={() => this.handleOnTranslateClick('address')}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.googleEmbedMap })}>
                    {
                        getFieldDecorator('googleEmbedMap', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.googleEmbedMap : undefined
                        })(
                            <Input
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>

                <Form.Item
                    label={intl.formatMessage({ ...messages.priority })}>
                    {
                        getFieldDecorator('priority', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.priority : 1
                        })(
                            <InputNumber
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : 'ACTIVE'
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.photo })}>
                    {
                        getFieldDecorator(`photo`, {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            valuePropName: 'fileList',
                            getValueFromEvent: (e) => this.normFile(e),
                            initialValue: (initial && initial.photo)
                                ? [{
                                    uid: initial.photo,
                                    name: initial.photo,
                                    status: 'done',
                                    url: `${IMAGE_URL}/${initial.photo}`,
                                }]
                                : []
                        })(
                            <Upload
                                listType="picture-card"
                                onPreview={(file) => this.handlePreview(file)}
                                disabled={isSubmitting}
                                beforeUpload={(file) => {
                                    return false
                                }}
                            >
                                {getFieldValue('photo').length >= 1 ? null : uploadButton}
                            </Upload>
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(StoreDetailForm))