import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/src/lib/imageCompressor'

class UserStore {
    @observable isSubmitting = false
    @observable error = null
    @observable staffs = []
    @observable count = 0

    createStaff = flow(function* (token, name, password, stores, role, status) {
        this.isSubmitting = true
        try {
            yield api.createStaff(token, name, password, stores, role, status)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listStaff = flow(function* (token, limit, skip) {
        this.isSubmitting = true
        try {
            const response = yield api.listStaff(token, limit, skip)
            const { staffs, count } = response.data
            this.staffs = staffs
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateStaff = flow(function* (token, userId, name, stores, role, status) {
        this.isSubmitting = true
        try {
            const response = yield api.updateStaff(token, userId, name, stores, role, status)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateStaffPassword = flow(function* (token, userId, password) {
        this.isSubmitting = true
        try {
            const response = yield api.updateStaffPassword(token, userId, password)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new UserStore()
