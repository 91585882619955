import messages from '~/src/messages'

function getMessage(errorCode) {
    switch (errorCode) {
        case 1000:
            return messages.nameNotFound
        case 1001:
            return messages.wrongPassword
        case 1002:
            return messages.nameDuplicated
        case 1003:
            return messages.yourAccountHasBeenInactived
        default:
            return undefined
    }
}

export function hasError(error, errorCode) {
    if (!error) {
        return false
    }
    return error.errorCodes.includes(errorCode)
}

export function getErrorMessage(intl, error, errorCode) {
    if (hasError(error, errorCode)) {
        const text = getMessage(errorCode)
        if (text) {
            return intl.formatMessage({...text})
        } else {
            return null
        }
    } else {
        return null
    }
}