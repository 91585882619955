import {
    Breadcrumb,
    message
} from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { withRouter } from "react-router"
import styled from 'styled-components'
import messages from '~/src/messages'
import WatchBrandForm from '~/src/components/forms/watchBrandForm'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`


@inject('commonStore', 'watchBrandStore') @observer
class WatchBrandCreatePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {
    }

    handleOnBackClick() {
        this.props.history.goBack()
    }


    async handleOnWatchBrandFormSubmit(values) {
        const { watchBrandStore, commonStore, intl } = this.props
        const { token } = commonStore
        const { name, priority, type, status, isOthers } = values
        try {
            await watchBrandStore.createWatchBrand(token, name, type, priority, status, isOthers)
            message.success(intl.formatMessage({ ...messages.success }))
            this.props.history.goBack()
        } catch (e) {
            console.log(e)
            if (e) {
                return message.error(e)
            }
            return message.error(intl.formatMessage({ ...messages.failure }))
        }
    }


    render() {
        const { watchBrandStore, commonStore, intl } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.addNewWatchBrand })}</title>
                </Helmet>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => this.handleOnBackClick()}>
                        <a>{intl.formatMessage({ ...messages.back })}</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {intl.formatMessage({ ...messages.addNewWatchBrand })}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <WatchBrandForm
                    token={commonStore.token}
                    initial={undefined}
                    isSubmitting={watchBrandStore.isSubmitting}
                    onSubmit={(values) => this.handleOnWatchBrandFormSubmit(values)} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(WatchBrandCreatePage))