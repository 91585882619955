import {
    Breadcrumb,
    message
} from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { withRouter } from "react-router"
import styled from 'styled-components'
import messages from '~/src/messages'
import WatchCaseForm from '~src/components/forms/watchCaseForm'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`


@inject('commonStore', 'watchCaseStore') @observer
class WatchCaseDetailPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {
        const { watchCaseStore, commonStore, match } = this.props
        const { token } = commonStore
        const { watchCaseId } = match.params
        await watchCaseStore.getWatchCase(token, watchCaseId)
    }


    handleOnBackClick() {
        this.props.history.goBack()
    }

    async handleOnWatchCaseFormSubmit(values) {
        const { watchCaseStore, commonStore, intl, match } = this.props
        const { token } = commonStore
        const { watchCaseId } = match.params
        const { name, priority, status } = values
        try {
            await watchCaseStore.updateWatchCase(token, watchCaseId, name, priority, status)
            message.success(intl.formatMessage({ ...messages.success }))
        } catch (e) {
            if (e) {
                return message.error(e)
            }
            return message.error(intl.formatMessage({ ...messages.failure }))
        }
    }


    render() {
        const { watchCaseStore, intl, commonStore } = this.props
        console.log(toJS(watchCaseStore.currentWatchCase))
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.updateWatchCase })}</title>
                </Helmet>
                <Breadcrumb>
                    {/* <Breadcrumb.Item onClick={() => this.handleOnBackClick()}>
                        <a>{intl.formatMessage({ ...messages.back })}</a>
                    </Breadcrumb.Item> */}
                    <Breadcrumb.Item>
                        {intl.formatMessage({ ...messages.updateWatchCase })}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <WatchCaseForm
                    token={commonStore.token}
                    initial={watchCaseStore.currentWatchCase}
                    isSubmitting={watchCaseStore.isSubmitting}
                    onSubmit={(values) => this.handleOnWatchCaseFormSubmit(values)} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(WatchCaseDetailPage))