import React, { Component } from 'react'
import { toJS } from 'mobx'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import messages from '~/src/messages'
import { InputNumber, Input, Checkbox } from 'antd'

const Wrapper = styled.div`

`

const InputWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 2px;
    align-items: center;
`

const LabelWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    padding: 0px 11px;
    line-height: 2.2;
    font-size: 14px;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #fafafa;
    color: rgba(0,0,0,0.65);
`


class InstalmentInput extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { intl, disabled, value, onChange } = this.props
        const { amount, rate, types } = value
        console.log(toJS(rate))
        const options = [
            { label: intl.formatMessage({ ...messages.INSTAL12 }), value: 'INSTAL12' },
            { label: intl.formatMessage({ ...messages.INSTAL24 }), value: 'INSTAL24' },
            { label: intl.formatMessage({ ...messages.INSTAL36 }), value: 'INSTAL36' },
        ]
        return (
            <Wrapper>
                <InputWrapper>
                    <InputNumber
                        placeholder={intl.formatMessage({ ...messages.price })}
                        min={0}
                        value={amount}
                        onChange={(e) => onChange({ ...value, 'amount': e })}
                        disabled={disabled} />
                    <Checkbox.Group value={types} style={{ padding: '6px' }} options={options} onChange={(e) => onChange({ ...value, types: e })} />
                </InputWrapper>
                <Input
                    value={amount ? Math.ceil(amount * (1 + rate['INSTAL12'] / 100) / 12) : 0}
                    disabled={true}
                    addonBefore={intl.formatMessage({ ...messages.INSTAL12 })} />
                <Input
                    value={amount ? Math.ceil(amount * (1 + rate['INSTAL24'] / 100) / 24) : 0}
                    disabled={true}
                    addonBefore={intl.formatMessage({ ...messages.INSTAL24 })} />
                <Input
                    value={amount ? Math.ceil(amount * (1 + rate['INSTAL36'] / 100) / 36) : 0}
                    disabled={true}
                    addonBefore={intl.formatMessage({ ...messages.INSTAL36 })} />
            </Wrapper >
        )
    }
}

export default injectIntl(InstalmentInput)