import { observable, flow, toJS } from 'mobx'
import api from '../api'
import _ from 'lodash'
class AccessControlStore {
    @observable isSubmitting = false
    @observable error = null
    @observable accessControls = {}


    listAccessControl = flow(function* (token) {
        this.isSubmitting = true
        try {
            const response = yield api.listAccessControl(token)
            this.accessControls = _.groupBy(response.data, 'role')
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateAccessControl = flow(function* (token, rbacId, accessControl) {
        this.isSubmitting = true
        try {
            const response = yield api.updateAccessControl(token, rbacId, accessControl)
            const { _id, role } = response.data
            let _accessControls = toJS(this.accessControls)
            const index = _accessControls[role].findIndex((b) => {
                return b._id === _id
            })
            _accessControls[role][index] = response.data
            this.accessControls = _accessControls
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })
}

export default new AccessControlStore()
