import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL

async function login(name, password) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/login`,
        data: {
            name,
            password,
            type: 'GENERAL'
        }
    }
    return axios(options)
}

async function getSelf(token) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/self`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function forgotPassword(token, name) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/user/forgotPassword`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name
        }
    }
    return axios(options)
}

async function resetPassword(token, password) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/user/resetPassword`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            password
        }
    }
    return axios(options)
}

async function createWatchBrand(token, name, type, priority, status, isOthers) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/watchBrand`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name, type, priority, status, isOthers
        }
    }
    return axios(options)
}

async function updateWatchBrand(token, watchBrandId, name, type, priority, status, isOthers) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/watchBrand/${watchBrandId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name, type, priority, status, isOthers
        }
    }
    return axios(options)
}

async function listWatchBrand(token, limit, skip, status, sort) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchBrand`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, status, sort
        }
    }
    return axios(options)
}

async function getWatchBrand(token, watchBrandId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchBrand/${watchBrandId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function removeWatchBrand(token, watchBrandId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/watchBrand/${watchBrandId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}
async function createWatchCase(token, name, priority) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/watchCase`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name, priority
        }
    }
    return axios(options)
}

async function updateWatchCase(token, watchCaseId, name, priority, status) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/watchCase/${watchCaseId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name, priority, status
        }
    }
    return axios(options)
}

async function listWatchCase(token, limit, skip, search, status) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchCase`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, search, status
        }
    }
    return axios(options)
}

async function getWatchCase(token, watchCaseId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchCase/${watchCaseId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function removeWatchCase(token, watchCaseId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/watchCase/${watchCaseId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}
async function createWatchFunction(token, description, priority) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/watchFunction`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            description, priority
        }
    }
    return axios(options)
}

async function updateWatchFunction(token, watchFunctionId, description, priority, status) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/watchFunction/${watchFunctionId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            description, priority, status
        }
    }
    return axios(options)
}

async function listWatchFunction(token, limit, skip, search, status) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchFunction`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, search, status
        }
    }
    return axios(options)
}

async function getWatchFunction(token, watchFunctionId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchFunction/${watchFunctionId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function removeWatchFunction(token, watchFunctionId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/watchFunction/${watchFunctionId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}
async function createWatchMovement(token, description, priority, status) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/watchMovement`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            description, priority, status
        }
    }
    return axios(options)
}

async function updateWatchMovement(token, watchMovementId, description, priority, status) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/watchMovement/${watchMovementId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            description, priority, status
        }
    }
    return axios(options)
}

async function listWatchMovement(token, limit, skip, search, status) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchMovement`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, search, status
        }
    }
    return axios(options)
}

async function getWatchMovement(token, watchMovementId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchMovement/${watchMovementId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function removeWatchMovement(token, watchMovementId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/watchMovement/${watchMovementId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function createWatchSize(token, name, priority, status) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/watchSize`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name, priority, status
        }
    }
    return axios(options)
}

async function updateWatchSize(token, watchSizeId, name, priority, status) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/watchSize/${watchSizeId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name, priority, status
        }
    }
    return axios(options)
}

async function listWatchSize(token, limit, skip, search, status) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchSize`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, search, status
        }
    }
    return axios(options)
}

async function getWatchSize(token, watchSizeId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchSize/${watchSizeId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function removeWatchSize(token, watchSizeId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/watchSize/${watchSizeId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}
async function createWatchItem(token, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/watchItem`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function updateWatchItem(token, watchItemId, formData) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/watchItem/${watchItemId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function modelRefNumberAutocomplete(token, q) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchItem/modelRefNumberAutocomplete`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            q
        }
    }
    return axios(options)
}

async function remarkAutocomplete(token, field, q) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchItem/remarkAutocomplete`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            field,
            q
        }
    }
    return axios(options)
}

async function updateWatchItemPublishStatus(token, watchItemId, isPublished) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/watchItem/${watchItemId}/publish`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            isPublished
        }
    }
    return axios(options)
}

async function listWatchItem(token, limit, skip, filters) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchItem`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, ...filters, sort: "-itemId"
        }
    }
    return axios(options)
}

async function getWatchItem(token, watchItemId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/watchItem/${watchItemId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function removeWatchItem(token, watchItemId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/watchItem/${watchItemId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}
async function updateEnquiry(token, enquiryId, status, remark) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/enquiry/${enquiryId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            status, remark
        }
    }
    return axios(options)
}

async function listEnquiry(token, limit, skip, status) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/enquiry`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, status
        }
    }
    return axios(options)
}

async function getEnquiry(token, enquiryId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/enquiry/${enquiryId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function deleteEnquiry(token, enquiryId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/enquiry/${enquiryId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function updateSellWatch(token, sellWatchId, status, remark) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/sellWatch/${sellWatchId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            status, remark
        }
    }
    return axios(options)
}

async function listSellWatch(token, limit, skip, status) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/sellWatch`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, status
        }
    }
    return axios(options)
}

async function getSellWatch(token, sellWatchId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/sellWatch/${sellWatchId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function removeSellWatch(token, sellWatchId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/sellWatch/${sellWatchId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function createFeed(token, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/feed/`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function updateFeed(token, feedId, formData) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/feed/${feedId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function listFeed(token, limit, skip, status) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/feed`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, status
        }
    }
    return axios(options)
}

async function getFeed(token, feedId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/feed/${feedId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function deleteFeed(token, feedId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/feed/${feedId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listFilter(token) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/filter`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function createStore(token, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/store`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function updateStore(token, storeId, formData) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/store/${storeId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function listStore(token) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/store`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function getStore(token, storeId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/store/${storeId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)

}

async function deleteStore(token, storeId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/store/${storeId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)

}

async function listStaff(token, limit, skip) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/user`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, type: 'GENERAL'
        }
    }
    return axios(options)

}

async function createStaff(token, name, password, stores, role, status) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/user`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name, password, stores, status, role,
            type: 'GENERAL'
        }
    }
    return axios(options)

}

async function updateStaff(token, userId, name, stores, role, status) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/user/${userId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name, stores, role, status
        }
    }
    return axios(options)
}

async function updateStaffPassword(token, userId, password) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/user/${userId}/password`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            password
        }
    }
    return axios(options)
}

async function getSettings(token) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/settings`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function updateSettings(token, formData) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/settings`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function createMedia(token, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/media`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function updateMedia(token, mediaId, formData) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/media/${mediaId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function listMedia(token, limit, skip, status) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/media`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, status
        }
    }
    return axios(options)
}

async function getMedia(token, mediaId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/media/${mediaId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function deleteMedia(token, mediaId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/media/${mediaId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}


async function createTestimonial(token, content, status, priority) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/admin/testimonial`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            content, status, priority
        }
    }
    return axios(options)
}

async function updateTestimonial(token, testimonialId, content, status, priority) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/testimonial/${testimonialId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            content, status, priority
        }
    }
    return axios(options)
}

async function listTestimonial(token, limit, skip, status) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/testimonial`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, status
        }
    }
    return axios(options)
}

async function deleteTestimonial(token, testimonialId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/admin/testimonial/${testimonialId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function translate(token, q) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/util/translate`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            q
        }
    }
    return axios(options)
}

async function listAccessControl(token) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/admin/rbac`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function updateAccessControl(token, rbacId, accessControl) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/admin/rbac/${rbacId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            accessControl
        }

    }
    return axios(options)
}

export default {
    login,
    getSelf,
    forgotPassword,
    resetPassword,
    createWatchBrand,
    updateWatchBrand,
    listWatchBrand,
    getWatchBrand,
    removeWatchBrand,
    createWatchCase,
    updateWatchCase,
    listWatchCase,
    getWatchCase,
    removeWatchCase,
    createWatchFunction,
    updateWatchFunction,
    listWatchFunction,
    getWatchFunction,
    removeWatchFunction,
    createWatchMovement,
    updateWatchMovement,
    listWatchMovement,
    getWatchMovement,
    removeWatchMovement,
    createWatchSize,
    updateWatchSize,
    listWatchSize,
    getWatchSize,
    removeWatchSize,
    createWatchItem,
    updateWatchItem,
    modelRefNumberAutocomplete,
    remarkAutocomplete,
    updateWatchItemPublishStatus,
    listWatchItem,
    getWatchItem,
    removeWatchItem,
    updateEnquiry,
    listEnquiry,
    getEnquiry,
    deleteEnquiry,
    updateSellWatch,
    listSellWatch,
    getSellWatch,
    removeSellWatch,
    createFeed,
    updateFeed,
    listFeed,
    getFeed,
    deleteFeed,
    listFilter,
    createStore,
    updateStore,
    listStore,
    getStore,
    deleteStore,
    listStaff,
    createStaff,
    updateStaff,
    updateStaffPassword,
    getSettings,
    updateSettings,
    listMedia,
    createMedia,
    updateMedia,
    deleteMedia,
    listTestimonial,
    createTestimonial,
    updateTestimonial,
    deleteTestimonial,
    translate,
    listAccessControl,
    updateAccessControl
}