import { observable, flow } from 'mobx'
import api from '../api'

class WatchBrandStore {
    @observable isSubmitting = false
    @observable error = null
    @observable watchBrands = []
    @observable count = 0
    @observable currentWatchBrand = undefined

    resetCurrentWatchBrand = flow(function* () {
        this.currentWatchBrand = undefined
        return Promise.resolve()
    })

    createWatchBrand = flow(function* (token, name, type, priority, status, isOthers) {
        this.isSubmitting = true
        try {
            yield api.createWatchBrand(token, name, type, priority, status, isOthers)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(error.response.data.messages)
        }
    })

    listWatchBrand = flow(function* (token, limit, skip, status, sort) {
        this.isSubmitting = true
        try {
            const response = yield api.listWatchBrand(token, limit, skip, status, sort)
            const { watchBrands, count } = response.data
            this.watchBrands = watchBrands
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getWatchBrand = flow(function* (token, watchBrandId) {
        this.isSubmitting = true
        try {
            const response = yield api.getWatchBrand(token, watchBrandId)
            this.currentWatchBrand = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateWatchBrand = flow(function* (token, watchBrandId, name, type, priority, status, isOthers) {
        this.isSubmitting = true
        try {
            const response = yield api.updateWatchBrand(token, watchBrandId, name, type, priority, status, isOthers)
            const index = this.watchBrands.findIndex((b) => {
                return b._id === watchBrandId
            })
            this.watchBrands[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(error.response.data.messages)
        }
    })

    deleteWatchBrand = flow(function* (token, watchBrandId) {
        this.isSubmitting = true
        try {
            yield api.removeWatchBrand(token, watchBrandId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new WatchBrandStore()
