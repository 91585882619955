import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Button, Select, Row, Col, InputNumber, Checkbox, Divider, Collapse } from 'antd'
import messages from '~/src/messages'
import { watchItemStatusOptions, watchItemTypeOptions } from '~/src/constants/options'

const Wrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 16px;
`

const Label = styled.div`
    margin: 8px;
`

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
`

class WatchItemFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            itemId: undefined,
            search: undefined,
            watchBrand: [],
            watchCase: [],
            watchFunction: [],
            watchMovement: [],
            watchSize: [],
            status: 'ALL',
            stores: [],
            priceRange: [1, 200000],
            isNew: false,
            notPublished: false
        }
    }

    async componentDidMount() {
        const { filterStore, commonStore } = this.props
        const { token } = commonStore
        await filterStore.listFilter(token)
    }

    handleOnApplyClick() {
        var values = {}
        if (this.state.itemId) {
            values['itemId'] = this.state.itemId
        }
        if (this.state.search) {
            values['search'] = this.state.search
        }
        if (this.state.watchBrand && this.state.watchBrand.length > 0) {
            values['watchBrand'] = this.state.watchBrand
        }
        if (this.state.watchCase && this.state.watchCase.length > 0) {
            values['watchCase'] = this.state.watchCase
        }
        if (this.state.watchFunction && this.state.watchFunction.length > 0) {
            values['watchFunction'] = this.state.watchFunction
        }
        if (this.state.watchMovement && this.state.watchMovement.length > 0) {
            values['watchMovement'] = this.state.watchMovement
        }
        if (this.state.watchSize && this.state.watchSize.length > 0) {
            values['watchSize'] = this.state.watchSize
        }
        if (this.state.status !== 'ALL') {
            values['status'] = this.state.status
        }
        if (this.state.stores && this.state.stores.length > 0) {
            values['stores'] = this.state.stores
        }
        if (this.state.priceRange && (this.state.priceRange[0] > 1 || this.state.priceRange[1] < 200000)) {
            values['priceRange'] = this.state.priceRange
        }
        if (this.state.isNew) {
            values['isNew'] = true
        }
        if (this.state.notPublished) {
            values['notPublished'] = true
        }
        this.props.onApply(values)
    }

    handleOnResetClick() {
        this.setState({
            itemId: undefined,
            search: undefined,
            watchBrand: [],
            watchCase: [],
            watchFunction: [],
            watchMovement: [],
            watchSize: [],
            status: 'ALL',
            stores: [],
            priceRange: [1, 200000],
            isNew: false,
            notPublished: false
        })
        this.props.onReset()
    }

    render() {
        const { intl, filterStore, onReset, onApply } = this.props
        return (
            <Wrapper>
                <Collapse>
                    <Collapse.Panel header={intl.formatMessage({ ...messages.filter })} key="1">
                        <Row gutter={{ xs: 6, sm: 12, md: 24 }} justify='start'>
                            <Col span={6}>
                                <Label>{intl.formatMessage({ ...messages.itemId })}:</Label>
                                <Input
                                    style={{ width: '100%', marginBottom: 16 }}
                                    placeholder={intl.formatMessage({ ...messages.itemId })}
                                    value={this.state.itemId}
                                    onChange={e => this.setState({ itemId: e.target.value })} />
                            </Col>
                            <Col span={6}>
                                <Label>{intl.formatMessage({ ...messages.nameOrModel })}:</Label>
                                <Input
                                    style={{ width: '100%', marginBottom: 16 }}
                                    placeholder={intl.formatMessage({ ...messages.nameOrModel })}
                                    value={this.state.search}
                                    onChange={e => this.setState({ search: e.target.value })} />
                            </Col>
                            <Col span={6}>
                                <Label>{intl.formatMessage({ ...messages.watchBrand })}:</Label>
                                <Select
                                    style={{ width: '100%', marginBottom: 16 }}
                                    value={this.state.watchBrand}
                                    onChange={(v) => this.setState({ watchBrand: v })}
                                    showSearch
                                    mode="multiple"
                                    placeholder={intl.formatMessage({ ...messages.watchBrand })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {
                                        filterStore.watchBrands.map(b => {
                                            return (
                                                <Select.Option key={b._id} value={b._id}>{b.name['en-US']}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={6}>
                                <Label>{intl.formatMessage({ ...messages.watchCase })}:</Label>
                                <Select
                                    style={{ width: '100%', marginBottom: 16 }}
                                    value={this.state.watchCase}
                                    onChange={(v) => this.setState({ watchCase: v })}
                                    showSearch
                                    mode="multiple"
                                    placeholder={intl.formatMessage({ ...messages.watchCase })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {
                                        filterStore.watchCases.map(b => {
                                            return (
                                                <Select.Option key={b._id} value={b._id}>{b.name['en-US']}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={6}>
                                <Label>{intl.formatMessage({ ...messages.watchFunction })}:</Label>
                                <Select
                                    style={{ width: '100%', marginBottom: 16 }}
                                    value={this.state.watchFunction}
                                    onChange={(v) => this.setState({ watchFunction: v })}
                                    showSearch
                                    mode="multiple"
                                    placeholder={intl.formatMessage({ ...messages.watchFunction })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {
                                        filterStore.watchFunctions.map(b => {
                                            return (
                                                <Select.Option key={b._id} value={b._id}>{b.description['en-US']}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={6}>
                                <Label>{intl.formatMessage({ ...messages.watchMovement })}:</Label>
                                <Select
                                    style={{ width: '100%', marginBottom: 16 }}
                                    value={this.state.watchMovement}
                                    onChange={(v) => this.setState({ watchMovement: v })}
                                    showSearch
                                    mode="multiple"
                                    placeholder={intl.formatMessage({ ...messages.watchMovement })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {
                                        filterStore.watchMovements.map(b => {
                                            return (
                                                <Select.Option key={b._id} value={b._id}>{b.description['en-US']}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={6}>
                                <Label>{intl.formatMessage({ ...messages.watchSize })}:</Label>
                                <Select
                                    style={{ width: '100%', marginBottom: 16 }}
                                    value={this.state.watchSize}
                                    onChange={(v) => this.setState({ watchSize: v })}
                                    showSearch
                                    mode="multiple"
                                    placeholder={intl.formatMessage({ ...messages.watchSize })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {
                                        filterStore.watchSizes.map(b => {
                                            return (
                                                <Select.Option key={b._id} value={b._id}>{b.name['en-US']}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={6}>
                                <Label>{intl.formatMessage({ ...messages.priceRange })}:</Label>
                                <RowWrapper>
                                    <InputNumber
                                        style={{ flexGrow: 1 }}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        value={this.state.priceRange[0]}
                                        onChange={(v) => this.setState({ priceRange: [v, this.state.priceRange[1]] })} />
                                    <div>-</div>
                                    <InputNumber
                                        style={{ flexGrow: 1 }}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        value={this.state.priceRange[1]}
                                        onChange={(v) => this.setState({ priceRange: [this.state.priceRange[0], v] })} />
                                </RowWrapper>
                            </Col>
                            <Col span={6}>
                                <Label>{intl.formatMessage({ ...messages.status })}:</Label>
                                <Select
                                    style={{ width: '100%', marginBottom: 16 }}
                                    value={this.state.status}
                                    onChange={(v) => this.setState({ status: v })}
                                    placeholder={intl.formatMessage({ ...messages.store })}>
                                    <Select.Option value={'ALL'}>{intl.formatMessage({ ...messages.all })}</Select.Option>
                                    {
                                        watchItemStatusOptions.map(b => {
                                            return (
                                                <Select.Option key={b.value} value={b.value}>{intl.formatMessage({ ...b.text })}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={6}>
                                <Label>{intl.formatMessage({ ...messages.store })}:</Label>
                                <Select
                                    style={{ width: '100%', marginBottom: 16 }}
                                    value={this.state.stores}
                                    onChange={(v) => this.setState({ stores: v })}
                                    showSearch
                                    mode="multiple"
                                    placeholder={intl.formatMessage({ ...messages.store })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {
                                        filterStore.stores.map(b => {
                                            return (
                                                <Select.Option key={b._id} value={b._id}>{b.name['en-US']}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={3}>
                                <Label>{intl.formatMessage({ ...messages.isNew })}:</Label>
                                <Checkbox checked={this.state.isNew} onChange={(e) => this.setState({ isNew: e.target.checked })} />
                            </Col>
                            <Col span={3}>
                                <Label>{intl.formatMessage({ ...messages.notPublished })}:</Label>
                                <Checkbox checked={this.state.notPublished} onChange={(e) => this.setState({ notPublished: e.target.checked })} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 16 }}>
                            <Button
                                icon={<RedoOutlined />}
                                onClick={() => this.handleOnResetClick()}
                                style={{ marginRight: 16 }}>
                                {intl.formatMessage({ ...messages.reset })}
                            </Button>
                            <Button
                                type="primary"
                                icon={<SearchOutlined />}
                                onClick={() => this.handleOnApplyClick()}>
                                {intl.formatMessage({ ...messages.apply })}
                            </Button>
                        </Row>
                    </Collapse.Panel>
                </Collapse>
            </Wrapper>
        );
    }
}

export default injectIntl(
    inject('commonStore', 'filterStore')(
        observer(WatchItemFilter)
    )
)
