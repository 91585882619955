import React from 'react'
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Upload, Modal, Radio, InputNumber } from 'antd';
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { statusOptions } from '~/src/constants/options'
import RichTextEditor from '~/src/components/richTextEditor'
import BraftEditor from 'braft-editor'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const PlayerWrapper = styled.div`
    position: relative;
    height: 500px;
    width: 100%;
    padding-top: 56.25%;
    background-color: #000000;
    padding: 8px;
`

const Player = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

class MediaForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: ''
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        console.log('handleSubmit')
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                console.log('validateFields')
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false, previewImage: '' })
    }

    handlePreview(file) {
        let type = undefined
        if (file.type) {
            if (file.type === 'VIDEO') {
                type = 'VIDEO'
            } else {
                if (file.type.split('/')[0].toLowerCase() === 'image') {
                    type = 'IMAGE'
                }
                if (file.type === 'IMAGE') {
                    type = 'IMAGE'
                }
            }
        }
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        })
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    renderImageUploaderArea() {
        const { intl, isSubmitting, initial } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">{intl.formatMessage({ ...messages.uploadPhoto })}</div>
            </div>
        )
        return (
            <Form.Item
                label={intl.formatMessage({ ...messages.uploadPhoto })}>
                {getFieldDecorator(`photos`, {
                    valuePropName: 'fileList',
                    getValueFromEvent: (e) => this.normFile(e),
                    initialValue: (initial && initial.photos)
                        ? initial.photos.map((p, i) => {
                            return {
                                uid: p,
                                name: p,
                                status: 'done',
                                url: `${IMAGE_URL}/${p}`,
                            }
                        })
                        : []
                })(
                    <Upload
                        listType="picture-card"
                        onPreview={(file) => this.handlePreview(file)}
                        disabled={isSubmitting}
                        beforeUpload={(file) => {
                            return false
                        }}
                    >
                        {getFieldValue('photos').length >= 5 ? null : uploadButton}
                    </Upload>
                )}
            </Form.Item>
        )
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.title })}>
                    {
                        getFieldDecorator('title', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.title : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.title })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.type })}>
                    {
                        getFieldDecorator('type', {
                            initialValue: initial && initial.youtubeLink ? 'youtubeLink' : 'photos'
                        })(
                            <Radio.Group>
                                <Radio value="youtubeLink">Youtube Link</Radio>
                                <Radio value="photos">Photos</Radio>
                            </Radio.Group>
                        )
                    }
                </Form.Item>
                {
                    getFieldValue('type') === 'youtubeLink'
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.youtubeLink })}>
                                {
                                    getFieldDecorator('youtubeLink', {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                                        ],
                                        initialValue: initial ? initial.youtubeLink : undefined
                                    })(
                                        <Input
                                            placeholder={intl.formatMessage({ ...messages.title })}
                                            disabled={isSubmitting} />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                {
                    getFieldValue('type') === 'photos'
                        ? this.renderImageUploaderArea()
                        : null
                }
                <Form.Item
                    label={intl.formatMessage({ ...messages.priority })}>
                    {
                        getFieldDecorator('priority', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.priority : 1
                        })(
                            <InputNumber
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(MediaForm))