import { observable, flow } from 'mobx'
import api from '../api'

class WatchMovementStore {
    @observable isSubmitting = false
    @observable error = null
    @observable watchMovements = []
    @observable count = 0
    @observable currentWatchMovement = undefined

    createWatchMovement = flow(function* (token, description, priority, status) {
        this.isSubmitting = true
        try {
            yield api.createWatchMovement(token, description, priority, status)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(error.response.data.messages)
        }
    })

    listWatchMovement = flow(function* (token, limit, skip, search, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listWatchMovement(token, limit, skip, search, status)
            const { watchMovements, count } = response.data
            this.watchMovements = watchMovements
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getWatchMovement = flow(function* (token, watchMovementId) {
        this.isSubmitting = true
        try {
            const response = yield api.getWatchMovement(token, watchMovementId)
            this.currentWatchMovement = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateWatchMovement = flow(function* (token, watchMovementId, description, priority, status) {
        this.isSubmitting = true
        try {
            const response = yield api.updateWatchMovement(token, watchMovementId, description, priority, status)
            const index = this.watchMovements.findIndex((b) => {
                return b._id === watchMovementId
            })
            this.watchMovements[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(error.response.data.messages)
        }
    })

    deleteWatchMovement = flow(function* (token, watchMovementId) {
        this.isSubmitting = true
        try {
            yield api.removeWatchMovement(token, watchMovementId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new WatchMovementStore()
