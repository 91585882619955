import React, { Component } from 'react'
import styled from 'styled-components'
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Button, Menu } from 'antd';
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
// import UserDetailModal from '~/src/components/userDetailModal'

const AppBar = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    box-shadow: 1px 1px 1px #888888;
    background: #fff;
    height: 64px;
`

const AppBarLeftSideWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-grow: 1;
`

const AppBarRightSideWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

const AppBarEmail = styled.div`
    margin: 0 16px;
    cursor: pointer;
`

const Username = styled.div`
    cursor: pointer;
`

class ApplicationBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userDetailVisible: false
        }
    }

    handleOnUserInformationClick() {
        this.setState({ userDetailVisible: true })
    }

    handleOnLogoutClick() {
        this.props.onLogout()
    }

    renderMenu() {
        const { intl } = this.props
        return (
            <Menu>
                <Menu.Item onClick={() => this.handleOnLogoutClick()}>
                    <a>{intl.formatMessage({ ...messages.logout })}</a>
                </Menu.Item>
            </Menu>
        )
    }

    render() {
        const { intl, name, isSiderCollapsed } = this.props

        return (
            <AppBar>
                <AppBarLeftSideWrapper>
                    <LegacyIcon
                        type={isSiderCollapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={() => this.props.onMenuFoldClick()}
                        style={{ cursor: 'pointer' }}
                        theme="outlined" />
                </AppBarLeftSideWrapper>
                <AppBarRightSideWrapper>
                    <Dropdown trigger={['hover']} overlay={this.renderMenu()}>
                        <Username>
                            {name} <DownOutlined />
                        </Username>
                    </Dropdown>
                    {/* <UserDetailModal 
                        visible={this.state.userDetailVisible}
                        onClose={() => this.setState({ userDetailVisible: false })}/> */}
                </AppBarRightSideWrapper>
            </AppBar>
        );
    }
}

export default withRouter(injectIntl(ApplicationBar))
