import React from 'react'
import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select } from 'antd';
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { statusOptions } from '~/src/constants/options'
import validator from '~/src/lib/validator'
import { hasError, getErrorMessage } from '~/src/lib/errorMessages'
import { roleOptions } from '~/src/constants/options'

class StaffForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            confirmDirty: false
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }


    handleConfirmBlur(e) {
        const value = e.target.value
        this.setState({ confirmDirty: this.state.confirmDirty || !!value })
    }

    validateToNextPassword(rule, value, callback) {
        const form = this.props.form
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true })
        }
        callback()
    }

    compareToFirstPassword(rule, value, callback) {
        const { form, intl } = this.props
        if (value && value !== form.getFieldValue('password')) {
            callback(intl.formatMessage({ ...messages.passwordNotMatch }))
        } else {
            callback()
        }
    }

    render() {
        const { isSubmitting, intl, initial, stores, error } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}
                    validateStatus={hasError(error, 1002) ? 'error' : null}
                    help={getErrorMessage(intl, error, 1002)}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                {
                    !initial
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.password })}>
                                {
                                    getFieldDecorator('password', {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                            { validator: (rule, value, callback) => this.validateToNextPassword(rule, value, callback) }
                                        ],
                                    })(
                                        <Input
                                            type="password"
                                            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder={intl.formatMessage({ ...messages.password })}
                                            disabled={isSubmitting} />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                {
                    !initial
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.confirmPassword })}>
                                {
                                    getFieldDecorator('confirmPassword', {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ ...messages.pleaseConfirmPassword }) },
                                            { validator: (rule, value, callback) => this.compareToFirstPassword(rule, value, callback) }
                                        ],
                                    })(
                                        <Input
                                            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="password"
                                            placeholder={intl.formatMessage({ ...messages.confirmPassword })}
                                            onBlur={(e) => this.handleConfirmBlur(e)}
                                            disabled={isSubmitting} />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                <Form.Item
                    label={intl.formatMessage({ ...messages.store })}>
                    {
                        getFieldDecorator('stores', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial && initial.stores ? initial.stores.map(s => s._id) : undefined
                        })(
                            <Select
                                mode='multiple'
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={isSubmitting}>
                                {
                                    stores.map((item, index) => {
                                        return <Select.Option value={item._id} key={index}>{item.name['en-US']}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.role })}>
                    {
                        getFieldDecorator('role', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial && initial.role ? initial.role : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={isSubmitting}>
                                {
                                    roleOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
            </Form>
        );
    }
}

export default injectIntl(Form.create()(StaffForm))