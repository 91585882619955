import React from 'react'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select } from 'antd';
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import validator from '~/src/lib/validator'
import { enquiryStatusOptions } from '~/src/constants/options'

class EnquiryForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }
        if (!initial) {
            return null
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {`${initial.title} ${initial.name}`}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.contact })}>
                    {
                        initial.email
                            ? `(${initial.areaCode}) ${initial.phoneNumber} / ${initial.email}`
                            : `(${initial.areaCode}) ${initial.phoneNumber}`
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.content })}>
                    {
                        initial.content
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    enquiryStatusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.remark })}>
                    {
                        getFieldDecorator('remark', {
                            initialValue: initial ? initial.remark : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.remark })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(EnquiryForm))