import React, { Component } from 'react'
import { Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import StoreDetailForm from '~/src/components/forms/storeDetailForm'

class StoreFormModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    render() {
        const { intl, visible, isSubmitting, store, token } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width={960}
                title={intl.formatMessage({ ...messages.store })}
                okText={intl.formatMessage({ ...messages.confirm })}
                cancelText={intl.formatMessage({ ...messages.cancel })}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => {
                    this.props.onClose()
                    this.formRef.resetForm()
                }}>
                <StoreDetailForm
                    token={token}
                    initial={store}
                    isSubmitting={isSubmitting}
                    onSubmit={(values, reset) => this.props.onSubmit(values, reset)}
                    wrappedComponentRef={(inst) => this.formRef = inst} />
            </Modal>
        )
    }
}

export default injectIntl(StoreFormModal)