import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Carousel, message, Modal, Popconfirm, Table } from "antd";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { Helmet } from "react-helmet";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import styled from "styled-components";
import WatchItemFilter from "~/src/components/watchItemFilter";
import WatchItemPreviewModal from "~/src/components/watchItemPreviewModal";
import { LIMIT } from "~/src/constants/common";
import { DATE_FORMAT } from "~/src/constants/format";
import {
  pageSizeOptions,
  watchItemStatusOptions,
} from "~/src/constants/options";
import messages from "~/src/messages";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 16px;

  button {
    margin-right: 8px;
  }
`;

const ActionLeftWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
`;

const TableWrapper = styled.div`
  background-color: white;
`;

const ImageWrapper = styled.a`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

@inject("commonStore", "watchItemStore")
@observer
class WatchItemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      watchItemFormVisible: false,
      watchItemPreviewVisible: false,
      pageSize: LIMIT,
      filterValues: {},
      previewVisible: false,
      previewImages: [],
    };
    this.formRef = React.createRef();
    this.carosel = React.createRef();
  }

  async componentDidMount() {
    const { watchItemStore, commonStore, location } = this.props;
    const { token, user } = commonStore;
    const { pageSize } = this.state;
    const params = queryString.parse(location.search);
    const currentPage = params.page || 1;
    const canRead = toJS(user.rbac)
      .find((r) => {
        return r.module == "ITEMS";
      })
      .accessControl.includes("READ");
    if (canRead) {
      await watchItemStore.listWatchItem(
        token,
        pageSize,
        pageSize * (currentPage - 1)
      );
    }
    this.setState({ currentPage });
    window.addEventListener("focus", (e) => {
      const { watchItemStore, commonStore, location } = this.props;
      console.log(location);
      if (location.pathname == "/watchItem") {
        const params = queryString.parse(location.search);
        console.log(params);
        let page = params.page;
        const { token, user } = commonStore;
        const { pageSize } = this.state;
        if (isNaN(page)) page = 1;
        const canRead = toJS(user.rbac)
          .find((r) => {
            return r.module == "ITEMS";
          })
          .accessControl.includes("READ");
        if (canRead) {
          watchItemStore.listWatchItem(
            token,
            +pageSize,
            +pageSize * (page - 1),
            this.state.filterValues
          );
        }
      }
    });
  }

  async handleOnWatchItemDelete(record) {
    const { watchItemStore, commonStore, intl } = this.props;
    const { token } = commonStore;
    const { currentPage, pageSize, sortedInfo } = this.state;
    try {
      await watchItemStore.deleteWatchItem(token, record._id);
      message.success(intl.formatMessage({ ...messages.success }));
      await watchItemStore.listWatchItem(
        token,
        pageSize,
        pageSize * (currentPage - 1)
      );
    } catch (e) {
      message.error(intl.formatMessage({ ...messages.failure }));
    }
  }

  handleOnTableChange(pagination, filters, sorter) {
    const page = pagination.current;
    const { watchItemStore, commonStore } = this.props;
    const { token, user } = commonStore;
    const { pageSize } = pagination;
    const canRead = toJS(user.rbac)
      .find((r) => {
        return r.module == "ITEMS";
      })
      .accessControl.includes("READ");
    if (canRead) {
      watchItemStore.listWatchItem(
        token,
        pageSize,
        pageSize * (page - 1),
        this.state.filterValues
      );
    }
    const href = `/watchItem?page=${page}`;
    this.props.history.replace(href);
    this.setState({
      currentPage: page,
      pageSize: pageSize,
      sortedInfo: sorter,
    });
  }

  handleOnFilterReset() {
    this.setState({ filterValues: {} }, () => {
      this.handleOnTableChange(
        { current: 1, pageSize: this.state.pageSize },
        null,
        {}
      );
    });
  }

  handleOnFilterApply(values) {
    console.log(values);
    this.setState({ filterValues: values, sortedInfo: null }, () => {
      this.handleOnTableChange(
        { current: 1, pageSize: this.state.pageSize },
        null,
        {}
      );
    });
  }

  async handleOnViewClick(record) {
    // this.props.history.push(`/watchItem/${record._id}`)
    this.setState({
      watchItemPreviewVisible: true,
      selectedWatchItemId: record._id,
    });
  }

  async handleOnUpdateClick(record) {
    this.props.history.push(`/watchItem/${record._id}`);
  }

  async handleOnAddNewClick() {
    this.props.history.push(`/watchItem/create`);
  }

  handlePreviewCancel() {
    this.setState({ previewVisible: false, previewImages: [] });
  }

  handlePreview(photos) {
    if (this.carosel.current) {
      this.carosel.current.goTo(0, true);
    }
    this.setState({
      previewImages: photos,
      previewVisible: true,
    });
  }

  renderTableColumn() {
    const { intl, commonStore } = this.props;
    const { user } = commonStore;
    const { sortedInfo } = this.state;
    return [
      {
        title: intl.formatMessage({ ...messages.itemId }),
        dataIndex: "itemId",
        key: "itemId",
        width: 200,
        // sorter: true,
        // sortOrder: sortedInfo && sortedInfo.columnKey === 'title' && sortedInfo.order,
        // ...this.getColumnSearchProps('title')
      },
      {
        title: intl.formatMessage({ ...messages.modelRefNumber }),
        dataIndex: "modelRefNumber",
        key: "modelRefNumber",
        width: 200,
        // sorter: true,
        // sortOrder: sortedInfo && sortedInfo.columnKey === 'title' && sortedInfo.order,
        // ...this.getColumnSearchProps('title')
      },
      // {
      //     title: intl.formatMessage({ ...messages.model }),
      //     dataIndex: 'model',
      //     key: 'model',
      //     render: (text, record) => {
      //         return <NameDisplaySection value={record.model} />
      //     },
      // },
      {
        title: intl.formatMessage({ ...messages.photos }),
        dataIndex: "photos",
        key: "photos",
        width: 100,
        render: (text, record) => {
          const mainPhotoIndex = record.photos.findIndex((p) => {
            const splitResult = p.split("/");
            const fileName = splitResult[splitResult.length - 1];
            if (record.mainPhoto === fileName) {
              return true;
            } else {
              return false;
            }
          });
          const thumbnail =
            mainPhotoIndex > -1
              ? `${IMAGE_URL}/${record.photos[mainPhotoIndex]}`
              : record.photos[0]
              ? `${IMAGE_URL}/${record.photos[0]}`
              : null;

          const photoUrls = record.photos.map((p) => `${IMAGE_URL}/${p}`);
          return (
            <ImageWrapper onClick={() => this.handlePreview(photoUrls)}>
              <img
                src={thumbnail}
                height={50}
                width={50}
                style={{ objectFit: "contain" }}
              />
              <div>
                {intl.formatMessage(
                  { ...messages.photoCount },
                  { count: photoUrls.length }
                )}
              </div>
            </ImageWrapper>
          );
        },
      },
      {
        title: intl.formatMessage({ ...messages.status }),
        dataIndex: "status",
        key: "status",
        width: 100,
        render: (text, record) => {
          const index = watchItemStatusOptions.findIndex(
            (c) => c.value === text
          );
          return index > -1
            ? intl.formatMessage({ ...watchItemStatusOptions[index].text })
            : null;
        },
      },
      {
        title: intl.formatMessage({ ...messages.createdAt }),
        dataIndex: "createdAt",
        key: "createdAt",
        // sorter: true,
        width: 200,
        // sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
        render: (createdAt) => (
          <span>{moment(createdAt).format(DATE_FORMAT)}</span>
        ),
      },
      {
        title: intl.formatMessage({ ...messages.actions }),
        key: "actions",
        width: 120,
        render: (text, record) => {
          const canUpdate =
            toJS(user.rbac)
              .find((r) => {
                return r.module == "ITEMS";
              })
              .accessControl.includes("UPDATE") &&
            (commonStore.user.stores.includes(record.store._id) ||
              toJS(user.role) !== "STAFF");
          const canDelete =
            toJS(user.rbac)
              .find((r) => {
                return r.module == "ITEMS";
              })
              .accessControl.includes("DELETE") &&
            (commonStore.user.stores.includes(record.store._id) ||
              toJS(user.role) !== "STAFF");
          return (
            <span>
              <Button.Group>
                <Button
                  icon={<EyeOutlined />}
                  onClick={() => this.handleOnViewClick(record)}
                  title={intl.formatMessage({ ...messages.view })}
                />
                {canUpdate ? (
                  <a href={`/watchItem/${record._id}`} target={"_blank"}>
                    <Button
                      icon={<EditOutlined />}
                      // onClick={() => this.handleOnUpdateClick(record)}
                      title={intl.formatMessage({ ...messages.update })}
                    />
                  </a>
                ) : null}
                {canDelete ? (
                  <Popconfirm
                    title={intl.formatMessage({
                      ...messages.areYouSureToDelete,
                    })}
                    onConfirm={() => this.handleOnWatchItemDelete(record)}
                    okText={intl.formatMessage({ ...messages.yes })}
                    okType="danger"
                    cancelText={intl.formatMessage({ ...messages.no })}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      type="danger"
                      title={intl.formatMessage({ ...messages.delete })}
                    />
                  </Popconfirm>
                ) : null}
              </Button.Group>
            </span>
          );
        },
      },
    ];
  }

  render() {
    const { watchItemStore, commonStore, intl } = this.props;
    const { currentPage, pageSize, selectedWatchItemId } = this.state;
    var selectedWatchItemIndex;
    if (selectedWatchItemId) {
      const index = watchItemStore.watchItems.findIndex(
        (o) => o._id === selectedWatchItemId
      );
      selectedWatchItemIndex = index;
    }
    const canCreate = toJS(commonStore.user.rbac)
      .find((r) => {
        return r.module == "ITEMS";
      })
      .accessControl.includes("CREATE");
    const canRead = toJS(commonStore.user.rbac)
      .find((r) => {
        return r.module == "ITEMS";
      })
      .accessControl.includes("READ");
    return (
      <Container>
        <Helmet>
          <title>{intl.formatMessage({ ...messages.watchItem })}</title>
        </Helmet>
        {canRead ? (
          <WatchItemFilter
            onReset={() => this.handleOnFilterReset()}
            onApply={(values) => this.handleOnFilterApply(values)}
          />
        ) : null}
        <ActionWrapper>
          <ActionLeftWrapper>
            <a href={`/watchItem/create`} target={"_blank"}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                disabled={!canCreate}
              >
                {intl.formatMessage({ ...messages.addNew })}
              </Button>
            </a>
          </ActionLeftWrapper>
        </ActionWrapper>
        <TableWrapper>
          <Table
            columns={this.renderTableColumn()}
            dataSource={toJS(watchItemStore.watchItems)}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: LIMIT,
              pageSizeOptions: pageSizeOptions,
              showQuickJumper: true,
              current: +currentPage,
              pageSize: pageSize,
              total: watchItemStore.count,
              showTotal: (total) => {
                return intl.formatMessage(
                  { ...messages.showTotalDisplayText },
                  { total }
                );
              },
            }}
            onChange={(pagination, filters, sorter) =>
              this.handleOnTableChange(pagination, filters, sorter)
            }
            loading={watchItemStore.isSubmitting}
            rowKey={(record) => record._id}
          />
        </TableWrapper>
        <WatchItemPreviewModal
          watchItem={
            selectedWatchItemIndex > -1
              ? toJS(watchItemStore.watchItems[selectedWatchItemIndex])
              : undefined
          }
          visible={this.state.watchItemPreviewVisible}
          onClose={() =>
            this.setState({
              watchItemPreviewVisible: false,
              selectedWatchItemId: undefined,
            })
          }
        />
        <Modal
          visible={this.state.previewVisible}
          footer={null}
          onCancel={() => this.handlePreviewCancel()}
        >
          <Carousel ref={this.carosel}>
            {this.state.previewImages.map((p, index) => {
              console.log(p);
              return (
                <div key={index}>
                  <img alt="example" style={{ width: "100%" }} src={p} />
                </div>
              );
            })}
          </Carousel>
        </Modal>
      </Container>
    );
  }
}

export default withRouter(injectIntl(WatchItemPage));
