import { observable, flow, toJS } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/src/lib/imageCompressor'

class SettingsStore {
    @observable isSubmitting = false
    @observable error = null
    @observable settings = undefined

    getSettings = flow(function* (token) {
        this.isSubmitting = true
        try {
            const response = yield api.getSettings(token)
            this.settings = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateSettings = flow(function* (token, values, removeDealershipBanners) {
        this.isSubmitting = true
        const formData = new FormData()
        if (values.latestUpdatedDate) {
            formData.append('latestUpdatedDate', values.latestUpdatedDate.format())
        }
        if (values.latestNews) {
            formData.append('latestNews', JSON.stringify(values.latestNews))
        }
        if (values.instalmentRate) {
            formData.append('instalmentRate', JSON.stringify(values.instalmentRate))
        }
        if (removeDealershipBanners) {
            formData.append('removeDealershipBanners', JSON.stringify(removeDealershipBanners))
        }
        if (values.mainPageBanners) {
            var linkPairs = []
            for (const banner of values.mainPageBanners) {
                const { key, photo, link } = banner
                for (const f of photo) {
                    const { originFileObj, type } = f
                    if (originFileObj) {
                        if (type.split('/')[0].toLowerCase() === 'image') {
                            var thumbnail = yield makeOriginal(originFileObj)
                            formData.append('main', thumbnail)
                            linkPairs.push({ key, link, name: thumbnail.name })
                        }
                    } else {
                        const { url } = f
                        linkPairs.push({ key, url, link })
                    }
                }
            }
            formData.append('links', JSON.stringify(linkPairs))
        }

        if (values.aboutUsPageBanner) {
            for (const f of values.aboutUsPageBanner) {
                const { originFileObj, type } = f
                if (originFileObj) {
                    if (type.split('/')[0].toLowerCase() === 'image') {
                        var thumbnail = yield makeOriginal(originFileObj)
                        formData.append('aboutUs', thumbnail)
                    }
                }
            }
        }

        if (values.repairPageBanner) {
            for (const f of values.repairPageBanner) {
                const { originFileObj, type } = f
                if (originFileObj) {
                    if (type.split('/')[0].toLowerCase() === 'image') {
                        var thumbnail = yield makeOriginal(originFileObj)
                        formData.append('repair', thumbnail)
                    }
                }
            }
        }
        if (values.termPageBanner) {
            for (const f of values.termPageBanner) {
                const { originFileObj, type } = f
                if (originFileObj) {
                    if (type.split('/')[0].toLowerCase() === 'image') {
                        var thumbnail = yield makeOriginal(originFileObj)
                        formData.append('term', thumbnail)
                    }
                }
            }
        }
        if (values.dealershipBanners) {
            for (const f of values.dealershipBanners) {
                const { originFileObj, type } = f
                if (originFileObj) {
                    if (type.split('/')[0].toLowerCase() === 'image') {
                        var thumbnail = yield makeOriginal(originFileObj)
                        formData.append('dealership', thumbnail)
                    }
                }
            }
        }

        try {
            const response = yield api.updateSettings(token, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new SettingsStore()
