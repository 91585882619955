import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { EditOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Table,
    Button,
    message,
    Input,
    Tooltip,
    Checkbox,
    Row,
    DatePicker,
    Modal,
    Carousel,
    Divider,
    Popconfirm,
    Tag,
} from 'antd';
import { LIMIT } from '~/src/constants/common'
import { DATE_FORMAT } from '~/src/constants/format'
import messages from '~/src/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import StaffFormModal from '~/src/components/staffFormModal'
import StaffPasswordFormModal from '~/src/components/staffPasswordFormModal'
import { statusOptions, pageSizeOptions, roleOptions } from '~/src/constants/options'
import update from 'immutability-helper'
import ReactPlayer from 'react-player'
import NameDisplaySection from '~/src/components/nameDisplaySection'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

const PlayerWrapper = styled.div`
    position: relative;
    height: 500px;
    width: 100%;
    padding-top: 56.25%;
    background-color: #000000;
    padding: 8px;
`

const Player = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

@inject('commonStore', 'userStore', 'filterStore') @observer
class StaffPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            staffFormVisible: false,
            staffPasswordFormVisible: false,
            pageSize: LIMIT,
            selectedStaffId: undefined
        }
    }

    async componentDidMount() {
        const { userStore, commonStore, location, filterStore } = this.props
        const { token } = commonStore
        const { pageSize } = this.state
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        await filterStore.listFilter(token)
        await userStore.listStaff(token, pageSize, pageSize * (currentPage - 1))
        this.setState({ currentPage })
    }

    async handleOnStaffFormSubmit(values, reset) {
        const { userStore, commonStore, intl } = this.props
        const { currentPage, pageSize, selectedStaffId } = this.state
        const { token } = commonStore
        const { name, password, stores, role, status } = values
        try {
            if (selectedStaffId) {
                await userStore.updateStaff(token, selectedStaffId, name, stores, role, status)
            } else {
                await userStore.createStaff(token, name, password, stores, role, status)
            }
            reset()
            this.setState({ staffFormVisible: false, selectedStaffId: undefined })
            message.success(intl.formatMessage({ ...messages.success }))
            await userStore.listStaff(token, pageSize, pageSize * (currentPage - 1))
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }

    async handleOnStaffPasswordFormSubmit(values, reset) {
        const { userStore, commonStore, intl } = this.props
        const { currentPage, pageSize, selectedStaffId } = this.state
        const { token } = commonStore
        const { password } = values
        if (selectedStaffId) {
            try {
                await userStore.updateStaffPassword(token, selectedStaffId, password)
                reset()
                this.setState({ staffPasswordFormVisible: false, selectedStaffId: undefined })
                message.success(intl.formatMessage({ ...messages.success }))
                await userStore.listStaff(token, pageSize, pageSize * (currentPage - 1))
            } catch (e) {
                message.error(intl.formatMessage({ ...messages.failure }))
            }
        }
    }


    handleOnTableChange(pagination, filters, sorter) {
        const page = pagination.current
        const { userStore, commonStore } = this.props
        const { token } = commonStore
        const { pageSize } = pagination
        userStore.listStaff(token, pageSize, pageSize * (page - 1))
        const href = `/staff?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize
        })
    }

    async handleOnUpdateClick(record) {
        this.setState({ staffFormVisible: true, selectedStaffId: record._id })
    }

    async handleOnChangeStaffPasswordClick(record) {
        this.setState({ staffPasswordFormVisible: true, selectedStaffId: record._id })
    }

    handleOnAddNewClick() {
        this.setState({ staffFormVisible: true })
    }


    renderTableColumn() {
        const { intl, commonStore } = this.props
        const columns = [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: intl.formatMessage({ ...messages.store }),
                dataIndex: 'store',
                key: 'store',
                render: (text, record) => {
                    const storeText = record.stores.map(s => s.name['en-US'])
                    if (storeText.length > 0) {
                        return storeText.join(', ')
                    } else {
                        return '-'
                    }
                }
            },
            {
                title: intl.formatMessage({ ...messages.role }),
                dataIndex: 'role',
                key: 'role',
                width: 100,
                render: (text, record) => {
                    const index = roleOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...roleOptions[index].text }) : null
                }
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text, record) => {
                    const index = statusOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...statusOptions[index].text }) : null
                }
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 200,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                )
            }
        ]
        return commonStore.user.role === 'SUPERADMIN'
            ? columns.concat([
                {
                    title: intl.formatMessage({ ...messages.actions }),
                    key: 'actions',
                    width: 100,
                    render: (text, record) => {
                        return (
                            <span>
                                <Button.Group>
                                    <Button
                                        icon={<EditOutlined />}
                                        onClick={() => this.handleOnUpdateClick(record)}
                                        title={intl.formatMessage({ ...messages.update })} />
                                    <Button
                                        icon={<LockOutlined />}
                                        onClick={() => this.handleOnChangeStaffPasswordClick(record)}
                                        title={intl.formatMessage({ ...messages.changeStaffPassword })} />
                                </Button.Group>
                            </span>
                        );
                    }
                }
            ])
            : columns;
    }

    render() {
        const { userStore, intl, filterStore, commonStore } = this.props
        const { currentPage, pageSize, selectedStaffId } = this.state
        var selectedStaffIndex
        if (selectedStaffId) {
            const index = userStore.staffs.findIndex(o => o._id === selectedStaffId)
            selectedStaffIndex = index
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.store })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        {
                            commonStore.user.role === 'SUPERADMIN'
                                ? (
                                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.handleOnAddNewClick()}>
                                        {intl.formatMessage({ ...messages.addNew })}
                                    </Button>
                                )
                                : null
                        }
                    </ActionLeftWrapper>
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(userStore.staffs)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: userStore.count,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={userStore.isSubmitting}
                        rowKey={record => record._id}
                    />
                </TableWrapper>
                <StaffFormModal
                    error={userStore.error}
                    stores={filterStore.stores}
                    staff={toJS(userStore.staffs[selectedStaffIndex])}
                    visible={this.state.staffFormVisible}
                    isSubmitting={userStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnStaffFormSubmit(values, reset)}
                    onClose={() => this.setState({ staffFormVisible: false, selectedStaffId: undefined })} />
                <StaffPasswordFormModal
                    staff={toJS(userStore.staffs[selectedStaffIndex])}
                    visible={this.state.staffPasswordFormVisible}
                    isSubmitting={userStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnStaffPasswordFormSubmit(values, reset)}
                    onClose={() => this.setState({ staffPasswordFormVisible: false, selectedStaffId: undefined })} />
            </Container>
        );
    }
}

export default withRouter(injectIntl(StaffPage))