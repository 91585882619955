import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { DeleteOutlined, EditOutlined, EyeOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import {
    Table,
    Button,
    message,
    Input,
    Tooltip,
    Checkbox,
    Row,
    DatePicker,
    Modal,
    Carousel,
    Divider,
    Popconfirm,
    Tag,
} from 'antd';
import { LIMIT } from '~/src/constants/common'
import { DATE_FORMAT } from '~/src/constants/format'
import messages from '~/src/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import WatchBrandFormModal from '~/src/components/watchBrandFormModal'
import { statusOptions, pageSizeOptions } from '~/src/constants/options'
import update from 'immutability-helper'
import ReactPlayer from 'react-player'
import NameDisplaySection from '~/src/components/nameDisplaySection'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

const PlayerWrapper = styled.div`
    position: relative;
    height: 500px;
    width: 100%;
    padding-top: 56.25%;
    background-color: #000000;
    padding: 8px;
`

const Player = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

@inject('commonStore', 'accessControlStore') @observer
class AccessControlPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }


    async handleOnUpdateClick(record) {
        this.props.history.push(`/accessControl/${record.role}`)
    }

    async componentDidMount() {
        const { accessControlStore, commonStore } = this.props
        const { token } = commonStore
        await accessControlStore.listAccessControl(token)
    }

    renderTableColumn() {
        const { intl, commonStore } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.role }),
                dataIndex: 'role',
                key: 'role',
                width: '100%',
                // sorter: true,
                // sortOrder: sortedInfo && sortedInfo.columnKey === 'title' && sortedInfo.order,
                // ...this.getColumnSearchProps('title')
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                width: 120,
                render: (text, record) => {
                    const canEdit = commonStore.user.role === 'SUPERADMIN'
                    return (
                        <span>
                            {canEdit
                                ? (
                                    <>
                                        <Button
                                            icon={<EditOutlined />}
                                            onClick={() => this.handleOnUpdateClick(record)}
                                            title={intl.formatMessage({ ...messages.update })} />

                                    </>
                                )
                                : null
                            }
                        </span>
                    );
                }
            }
        ];
    }

    render() {
        const { accessControlStore, intl } = this.props

        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.accessControl })}</title>
                </Helmet>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={Object.keys(toJS(accessControlStore.accessControls)).map((role) => { return { role } })}
                        loading={accessControlStore.isSubmitting}
                        rowKey={record => record._id}
                    />
                </TableWrapper>
            </Container>
        );
    }
}

export default withRouter(injectIntl(AccessControlPage))