import { action, observable } from 'mobx'

class CommonStore {
    @observable token = localStorage.getItem('token')
    @observable user = undefined

    @action setUser(user) {
        const { token, tokenExpAt } = user
        localStorage.setItem('token', token)
        localStorage.setItem('tokenExpAt', tokenExpAt)
        this.token = token
        this.user = user
    }

    @action removeUser() {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpAt')
        this.user = null
    }
}

export default new CommonStore()
