import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import {
    Table,
    Button,
    message,
    Input,
    Tooltip,
    Checkbox,
    Row,
    DatePicker,
    Modal,
    Carousel,
    Divider,
    Popconfirm,
    Tag,
} from 'antd';
import { LIMIT } from '~/src/constants/common'
import { DATE_FORMAT } from '~/src/constants/format'
import messages from '~/src/messages'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import SettingsForm from '~/src/components/forms/settingsForm'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

const PlayerWrapper = styled.div`
    position: relative;
    height: 500px;
    width: 100%;
    padding-top: 56.25%;
    background-color: #000000;
    padding: 8px;
`

const Player = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

@inject('commonStore', 'settingsStore') @observer
class SettingsPage extends React.Component {

    async componentDidMount() {
        const { settingsStore, commonStore } = this.props
        const { token } = commonStore
        await settingsStore.getSettings(token)
    }

    async handleOnSettingsFormSubmit(values, reset) {
        const { settingsStore, commonStore, intl } = this.props
        const { token } = commonStore
        console.log(values)
        try {
            const originalDealershipBanners = toJS(settingsStore.settings).dealershipBanners
            const newDealershipBanners = values.dealershipBanners
            let removeDealershipBanners = []
            if (originalDealershipBanners) {
                for (const banner of originalDealershipBanners) {
                    const index = newDealershipBanners.findIndex((b) => { return b.url == banner.url })
                    if (index == -1) {
                        removeDealershipBanners.push(banner.url)
                    }
                }
            }
            await settingsStore.updateSettings(token, values, removeDealershipBanners)
            reset()
            message.success(intl.formatMessage({ ...messages.success }))
            await settingsStore.getSettings(token)
        } catch (e) {
            console.log(e)
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }


    render() {
        const { settingsStore, commonStore, intl } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.settings })}</title>
                </Helmet>
                <SettingsForm
                    token={commonStore.token}
                    user={commonStore.user}
                    initial={settingsStore.settings}
                    onSubmit={(values, reset) => this.handleOnSettingsFormSubmit(values, reset)} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(SettingsPage))