import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import LoginForm from '~/src/components/forms/loginForm'
import { injectIntl } from 'react-intl'
import Helmet from 'react-helmet'
import messages from '~/src/messages'

const LoginWrapper = styled.div`
    height: 100vh;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
`

@inject('authStore') @observer
class LoginPage extends React.Component {

    async handleOnLogin(fields) {
        const { authStore } = this.props
        const { name, password } = fields
        try {
            await authStore.login(name, password)
            await authStore.getSelf(localStorage.getItem('token'))
            this.props.history.push('/')
        } catch (e) {

        }
    }

    render() {
        const { authStore, intl } = this.props
        const { isSubmitting, error } = authStore

        return (
            <LoginWrapper>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.login })}</title>
                </Helmet>
                <LoginForm
                    isSubmitting={isSubmitting}
                    error={error}
                    onLogin={(fields) => this.handleOnLogin(fields)} />
            </LoginWrapper>
        )
    }
}

export default withRouter(injectIntl(LoginPage))