import React from 'react'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Upload, InputNumber } from 'antd';
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { statusOptions } from '~/src/constants/options'
import RichTextEditor from '~/src/components/richTextEditor'
import BraftEditor from 'braft-editor'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const PlayerWrapper = styled.div`
    position: relative;
    height: 500px;
    width: 100%;
    padding-top: 56.25%;
    background-color: #000000;
    padding: 8px;
`

const Player = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

class TestimonialForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.content })}>
                    {
                        getFieldDecorator('content', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? BraftEditor.createEditorState(initial.content) : undefined
                        })(
                            <RichTextEditor 
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.priority })}>
                    {
                        getFieldDecorator('priority', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.priority : 1
                        })(
                            <InputNumber
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(TestimonialForm))