import { observable, flow } from 'mobx'
import api from '../api'

class UtilStore {
    @observable isSubmitting = false
    @observable error = null




    translate = flow(function* (token, q) {
        this.isSubmitting = true
        try {
            const response = yield api.translate(token, q)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve(response.data)
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new UtilStore()
