import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/src/lib/imageCompressor'

class TestimonialStore {
    @observable isSubmitting = false
    @observable error = null
    @observable testimonials = []
    @observable count = 0

    createTestimonial = flow(function* (token, values) {
        this.isSubmitting = true
        try {
            yield api.createTestimonial(token, values.content.toHTML(), values.status, values.priority)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listTestimonial = flow(function* (token, limit, skip, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listTestimonial(token, limit, skip, status)
            const { testimonials, count } = response.data
            this.testimonials = testimonials
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateTestimonial = flow(function* (token, testimonialId, values) {
        this.isSubmitting = true
        try {
            const response = yield api.updateTestimonial(token, testimonialId, values.content.toHTML(), values.status, values.priority)
            const index = this.testimonials.findIndex((b) => {
                return b._id === testimonialId
            })
            this.testimonials[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    deleteTestimonial = flow(function* (token, testimonialId) {
        this.isSubmitting = true
        try {
            yield api.deleteTestimonial(token, testimonialId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new TestimonialStore()
