import { Modal } from 'antd'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import WatchMovementForm from '~/src/components/forms/watchMovementForm'
import messages from '~/src/messages'

class WatchMovementFormModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    render() {
        const { intl, visible, isSubmitting, watchMovement, token } = this.props

        const title = watchMovement
            ? `${intl.formatMessage({ ...messages.update })} - ${intl.formatMessage({ ...messages.watchMovement })}`
            : `${intl.formatMessage({ ...messages.addNew })} - ${intl.formatMessage({ ...messages.watchMovement })}`
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width={960}
                title={title}
                okText={intl.formatMessage({ ...messages.confirm })}
                cancelText={intl.formatMessage({ ...messages.cancel })}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => {
                    this.props.onClose()
                    this.formRef.resetForm()
                }}>
                <WatchMovementForm
                    token={token}
                    initial={watchMovement}
                    isSubmitting={isSubmitting}
                    onSubmit={(values, reset) => this.props.onSubmit(values, reset)}
                    wrappedComponentRef={(inst) => this.formRef = inst} />
            </Modal>
        )
    }
}

export default injectIntl(WatchMovementFormModal)