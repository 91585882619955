import React from 'react'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Modal, Row } from 'antd';
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import validator from '~/src/lib/validator'
import { enquiryStatusOptions } from '~/src/constants/options'
import styled from 'styled-components'

const Image = styled.img`
    margin: 8px;
    width: 100px;
    height: 100px;
    object-fit: contain;
    cursor: pointer;
`


class SellWatchForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previewImage: undefined,
            previewVisible: false
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false, previewImage: undefined })
    }

    handlePreview(url) {
        this.setState({
            previewImage: url,
            previewVisible: true,
        })
    }


    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }
        console.log(initial)
        if (!initial) {
            return null
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {`${initial.title} ${initial.name}`}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.contact })}>
                    {
                        initial.email
                            ? `(${initial.areaCode}) ${initial.phoneNumber} ${initial.email}`
                            : `(${initial.areaCode}) ${initial.phoneNumber}`
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.content })}>
                    {
                        initial.content
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.watchBrand })}>
                    {
                        initial.customBrand ? initial.customBrand : initial.brand.name ? initial.brand.name['en-US'] : undefined
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.model })}>
                    {
                        initial.model
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.behaviour })}>
                    {
                        initial.behaviours.join(', ')
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.referenceNumber })}>
                    {
                        initial.referenceNumber
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.condition })}>
                    {
                        initial.condition
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.photos })}>
                    <Row>
                        {
                            initial.photos.map((p, i) => {
                                return <Image src={p} key={i} onClick={() => this.handlePreview(p)} />
                            })
                        }
                    </Row>
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.askingPrice })}>
                    {
                        `$ ${initial.askingPrice}`
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    enquiryStatusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.remark })}>
                    {
                        getFieldDecorator('remark', {
                            initialValue: initial ? initial.remark : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.remark })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(SellWatchForm))