import { action, observable, flow } from 'mobx'
import api from '../api'
import commonStore from './commonStore'

class AuthStore {
    @observable isSubmitting = false
    @observable error = null
    @observable isResetEmailSent = false

    login = flow(function * (name, _password) {
        this.isSubmitting = true
        try {
            const response = yield api.login(name, _password)
            localStorage.removeItem('logout')
            commonStore.setUser(response.data)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    forgotPassword = flow(function* (name) {
        this.isSubmitting = true
        try {
            yield api.forgotPassword(name)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    resetPassword = flow(function* (token, password) {
        this.isSubmitting = true
        try {
            yield api.resetPassword(token, password)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    @action logout() {
        commonStore.removeUser()
        localStorage.setItem('logout', Date.now())
        return Promise.resolve()
    }

    getSelf = flow(function* (token) {
        this.isSubmitting = true
        try {
            const response = yield api.getSelf(token)
            commonStore.setUser(response.data)
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })
}

export default new AuthStore()
