import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/src/lib/imageCompressor'

class StoreStore {
    @observable isSubmitting = false
    @observable error = null
    @observable stores = []
    @observable count = 0
    @observable currentStore = undefined

    createStore = flow(function* (token, values) {
        this.isSubmitting = true
        const formData = new FormData()
        let storeData = { ...values }
        delete storeData.photo
        formData.append('store', JSON.stringify(storeData))

        for (const f of values.photo) {
            const { originFileObj, type } = f
            if (originFileObj) {
                if (type.split('/')[0].toLowerCase() === 'image') {
                    var thumbnail = yield makeOriginal(originFileObj)
                    formData.append('photo', thumbnail)
                }
            }
        }

        try {
            yield api.createStore(token, formData)
            return Promise.resolve()
        }
        catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listStore = flow(function* (token, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listStore(token, status)
            const stores = response.data
            this.stores = stores
            this.count = stores.length
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getStore = flow(function* (token, storeId) {
        this.isSubmitting = true
        try {
            const response = yield api.getStore(token, storeId)
            this.currentStore = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateStore = flow(function* (token, storeId, values) {
        this.isSubmitting = true
        try {
            const formData = new FormData()
            let storeData = { ...values }
            delete storeData.photo
            formData.append('store', JSON.stringify(storeData))

            for (const f of values.photo) {
                const { originFileObj, type } = f
                if (originFileObj) {
                    if (type.split('/')[0].toLowerCase() === 'image') {
                        var thumbnail = yield makeOriginal(originFileObj)
                        formData.append('photo', thumbnail)
                    }
                }
            }


            const response = yield api.updateStore(token, storeId, formData)
            const index = this.stores.findIndex((b) => {
                return b._id === storeId
            })
            this.stores[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    // updateStore = flow(function* (token, storeId, locationCode, name, openHour, tel, address, priority, googleEmbedMap, status) {
    //     this.isSubmitting = true
    //     try {
    //         const response = yield api.updateStore(token, storeId, locationCode, name, openHour, tel, address, priority, googleEmbedMap, status)
    //         const index = this.stores.findIndex((b) => {
    //             return b._id === storeId
    //         })
    //         this.stores[index] = response.data
    //         this.error = null
    //         this.isSubmitting = false
    //         return Promise.resolve()
    //     } catch (error) {
    //         console.log(error)
    //         this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
    //         this.isSubmitting = false
    //         return Promise.reject()
    //     }
    // })

    deleteStore = flow(function* (token, storeId) {
        this.isSubmitting = true
        try {
            yield api.deleteStore(token, storeId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new StoreStore()
