import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Divider, InputNumber, Row, Select } from 'antd';
import moment from 'moment';

import React from 'react';
import { injectIntl } from 'react-intl';
import api from '~/src/api';
import NameInput from '~/src/components/nameInput';
import { statusOptions } from '~/src/constants/options';
import messages from '~/src/messages';
import styled from 'styled-components';

const FormWrapper = styled.div`
    background-color: white;
    padding: 16px;
`

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
`

class WatchFunctionForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    async handleOnTranslateClick(field) {
        const { token } = this.props
        const { getFieldValue, setFieldsValue } = this.props.form
        const formValues = getFieldValue(field)
        const TW = formValues['zh-TW']
        try {
            const response = await api.translate(token, TW)
            if (response.status == 200) {
                setFieldsValue({ [field]: { ...formValues, "zh-CN": response.data } })
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <FormWrapper>
                <Form >
                    <Form.Item
                        label={intl.formatMessage({ ...messages.description })}>
                        {
                            getFieldDecorator('description', {
                                rules: [
                                    { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                                ],
                                initialValue: initial ? initial.description : { 'en-US': undefined, 'zh-TW': undefined, 'zh-CN': undefined }
                            })(
                                <NameInput
                                    onTranslateClick={() => this.handleOnTranslateClick('description')}
                                    disabled={isSubmitting} />
                            )
                        }
                    </Form.Item>

                    <Form.Item
                        label={intl.formatMessage({ ...messages.priority })}>
                        {
                            getFieldDecorator('priority', {
                                rules: [
                                    { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                                ],
                                initialValue: initial ? initial.priority : 1
                            })(
                                <InputNumber
                                    disabled={isSubmitting} />
                            )
                        }
                    </Form.Item>
                    <Form.Item
                        label={intl.formatMessage({ ...messages.status })}>
                        {
                            getFieldDecorator('status', {
                                rules: [
                                    { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                                ],
                                initialValue: initial ? initial.status : 'ACTIVE'
                            })(
                                <Select
                                    placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                    disabled={isSubmitting}>
                                    {
                                        statusOptions.map((item, index) => {
                                            return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                        })
                                    }
                                </Select>
                            )
                        }
                    </Form.Item>
                </Form>
                <Divider />
                {initial ?
                    <Col span={24}>
                        <Row span={24}>{`Created Time: ${moment(initial.createdAt).format('YYYY-MM-DD HH:mm:ss')}`}</Row>
                        <Row span={24}>{`Updated Time: ${moment(initial.updatedAt).format('YYYY-MM-DD HH:mm:ss')}`}</Row>
                        <Divider />
                    </Col>
                    :
                    null}

                <Form.Item>
                    <RowWrapper>
                        <Button style={{ marginRight: 16 }} onClick={() => this.resetForm()}>{intl.formatMessage({ ...messages.reset })}</Button>
                        <Button type='primary' style={{ marginRight: 16 }} onClick={() => this.handleSubmit()}>{intl.formatMessage({ ...messages.confirm })}</Button>
                    </RowWrapper>
                </Form.Item>
            </FormWrapper>
        )
    }
}

export default injectIntl(Form.create()(WatchFunctionForm))