import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Modal, Upload } from 'antd';
import moment from 'moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import api from '~/src/api';
import InstalmentRateInput from '~/src/components/instalmentRateInput';
import MainPageBannerListTable from '~/src/components/mainPageBannerListTable';
import NameInput from '~/src/components/nameInput';
import messages from '~/src/messages';
import { toJS } from 'mobx'

const PlayerWrapper = styled.div`
    position: relative;
    height: 500px;
    width: 100%;
    padding-top: 56.25%;
    background-color: #000000;
    padding: 8px;
`

const Player = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

class SettingsForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: '',
            previewType: undefined
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false, previewImage: '', previewType: undefined })
    }

    handlePreview(file) {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        })
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    async handleOnTranslateClick(field) {
        const { token } = this.props
        const { getFieldValue, setFieldsValue } = this.props.form
        const formValues = getFieldValue(field)
        const TW = formValues['zh-TW']
        try {
            const response = await api.translate(token, TW)
            if (response.status == 200) {
                setFieldsValue({ [field]: { ...formValues, "zh-CN": response.data } })
            }
        }
        catch (e) {
            console.log(e)
        }
    }


    render() {
        const { isSubmitting, intl, initial, user } = this.props
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form
        const canBannerCreate = toJS(user.rbac).find((r) => { return r.module == 'BANNERS' }).accessControl.includes('CREATE')
        const canBannerDelete = toJS(user.rbac).find((r) => { return r.module == 'BANNERS' }).accessControl.includes('DELETE')
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">{intl.formatMessage({ ...messages.uploadPhoto })}</div>
            </div>
        )
        return (
            <Form {...formItemLayout}>
                {
                    initial && initial.latestUpdatedDate ?
                        <Form.Item
                            label={intl.formatMessage({ ...messages.latestUpdatedDate })}>
                            {
                                getFieldDecorator('latestUpdatedDate', {
                                    rules: [
                                        { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                                    ],
                                    initialValue: initial ? moment(initial.latestUpdatedDate) : undefined
                                })(
                                    <DatePicker
                                        placeholder={intl.formatMessage({ ...messages.latestUpdatedDate })}
                                        disabled={isSubmitting} />
                                )
                            }
                        </Form.Item>
                        : null
                }
                {
                    initial && initial.latestNews ?
                        <Form.Item
                            label={intl.formatMessage({ ...messages.latestNews })}>
                            {
                                getFieldDecorator('latestNews', {
                                    rules: [
                                        { required: false, message: intl.formatMessage({ ...messages.pleaseInput }) }
                                    ],
                                    initialValue: initial ? initial.latestNews : { 'en-US': undefined, 'zh-TW': undefined, 'zh-CN': undefined }
                                })(
                                    <NameInput
                                        onTranslateClick={() => this.handleOnTranslateClick('latestNews')}
                                        disabled={isSubmitting} />
                                )
                            }
                        </Form.Item>
                        : null
                }
                {
                    initial && initial.instalmentRate ?
                        < Form.Item
                            label={intl.formatMessage({ ...messages.instalmentRate })}>
                            {
                                getFieldDecorator('instalmentRate', {
                                    rules: [
                                        { required: false, message: intl.formatMessage({ ...messages.pleaseInput }) }
                                    ],
                                    initialValue: initial ? initial.instalmentRate : { 'INSTAL12': undefined, 'INSTAL24': undefined, 'INSTAL36': undefined }
                                })(
                                    <InstalmentRateInput
                                        intl={intl}
                                        disabled={isSubmitting} />
                                )
                            }
                        </Form.Item>
                        : null
                }
                {
                    initial && initial.mainPageBanners && initial.aboutUsPageBanner && initial.repairPageBanner && initial.termPageBanner && initial.dealershipBanners ?
                        <>
                            <Form.Item
                                label={intl.formatMessage({ ...messages.mainPageBanners })}>
                                {getFieldDecorator(`mainPageBanners`, {
                                    initialValue: initial ? initial.mainPageBanners.map(b => {
                                        return {
                                            key: b.key,
                                            photo: [{
                                                uid: b.url,
                                                name: 'xxx.png',
                                                status: 'done',
                                                url: b.url,
                                            }],
                                            link: b.link
                                        }
                                    }) : []
                                })(
                                    <MainPageBannerListTable user={user} />
                                )}
                            </Form.Item>
                            <Form.Item
                                label={intl.formatMessage({ ...messages.aboutUsPageBanner })}>
                                {getFieldDecorator(`aboutUsPageBanner`, {
                                    valuePropName: 'fileList',
                                    getValueFromEvent: (e) => this.normFile(e),
                                    initialValue: initial ? [{
                                        uid: initial.aboutUsPageBanner,
                                        name: 'xxx.png',
                                        status: 'done',
                                        url: initial.aboutUsPageBanner,
                                    }] : []
                                })(
                                    <Upload
                                        listType="picture-card"
                                        onPreview={(file) => this.handlePreview(file)}
                                        disabled={!canBannerDelete || isSubmitting}
                                        beforeUpload={(file) => {
                                            return false
                                        }}
                                    >
                                        {getFieldValue('aboutUsPageBanner').length >= 1 ? null : uploadButton}
                                    </Upload>
                                )}
                            </Form.Item>
                            <Form.Item
                                label={intl.formatMessage({ ...messages.repairPageBanner })}>
                                {getFieldDecorator(`repairPageBanner`, {
                                    valuePropName: 'fileList',
                                    getValueFromEvent: (e) => this.normFile(e),
                                    initialValue: initial ? [{
                                        uid: initial.repairPageBanner,
                                        name: 'xxx.png',
                                        status: 'done',
                                        url: initial.repairPageBanner,
                                    }] : []
                                })(
                                    <Upload
                                        listType="picture-card"
                                        onPreview={(file) => this.handlePreview(file)}
                                        disabled={!canBannerDelete || isSubmitting}
                                        beforeUpload={(file) => {
                                            return false
                                        }}
                                    >
                                        {getFieldValue('repairPageBanner').length >= 1 ? null : uploadButton}
                                    </Upload>
                                )}
                            </Form.Item>
                            <Form.Item
                                label={intl.formatMessage({ ...messages.termPageBanner })}>
                                {getFieldDecorator(`termPageBanner`, {
                                    valuePropName: 'fileList',
                                    getValueFromEvent: (e) => this.normFile(e),
                                    initialValue: initial ? [{
                                        uid: initial.termPageBanner,
                                        name: 'xxx.png',
                                        status: 'done',
                                        url: initial.termPageBanner,
                                    }] : []
                                })(
                                    <Upload
                                        listType="picture-card"
                                        onPreview={(file) => this.handlePreview(file)}
                                        disabled={!canBannerDelete || isSubmitting}
                                        beforeUpload={(file) => {
                                            return false
                                        }}
                                    >
                                        {getFieldValue('termPageBanner').length >= 1 ? null : uploadButton}
                                    </Upload>
                                )}
                            </Form.Item>
                            <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                                {
                                    this.state.previewType === 'IMAGE'
                                        ? <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                        : null
                                }
                            </Modal>
                            <Form.Item
                                label={intl.formatMessage({ ...messages.dealershipBanners })}>
                                {getFieldDecorator(`dealershipBanners`, {
                                    valuePropName: 'fileList',
                                    getValueFromEvent: (e) => this.normFile(e),
                                    initialValue: initial ? initial.dealershipBanners.map((b, index) => {
                                        return {
                                            uid: b.url,
                                            name: 'xxx.png',
                                            status: 'done',
                                            url: b.url
                                        }
                                    }) : []
                                })(
                                    <Upload
                                        listType="picture"
                                        disabled={!canBannerDelete || isSubmitting}
                                        beforeUpload={(file) => {
                                            return false
                                        }}
                                    >
                                        {
                                            getFieldValue('dealershipBanners').length >= 5 ? null :
                                                <Button disabled={!canBannerCreate}>
                                                    <UploadOutlined /> Upload
                                                </Button>
                                        }
                                    </Upload>
                                )}
                            </Form.Item>
                        </>
                        : null
                }
                <Form.Item>
                    <Button type='primary' onClick={() => this.handleSubmit()}>{intl.formatMessage({ ...messages.confirm })}</Button>
                </Form.Item>
            </Form >
        );
    }
}

export default injectIntl(Form.create()(SettingsForm))