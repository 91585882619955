import { observable, flow } from 'mobx'
import api from '../api'

class WatchCaseStore {
    @observable isSubmitting = false
    @observable error = null
    @observable watchCases = []
    @observable count = 0
    @observable currentWatchCase = undefined

    createWatchCase = flow(function* (token, name, priority) {
        this.isSubmitting = true
        try {
            yield api.createWatchCase(token, name, priority)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(error.response.data.messages)
        }
    })

    listWatchCase = flow(function* (token, limit, skip, search, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listWatchCase(token, limit, skip, search, status)
            const { watchCases, count } = response.data
            this.watchCases = watchCases
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getWatchCase = flow(function* (token, watchCaseId) {
        this.isSubmitting = true
        try {
            const response = yield api.getWatchCase(token, watchCaseId)
            this.currentWatchCase = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateWatchCase = flow(function* (token, watchCaseId, name, priority, status) {
        this.isSubmitting = true
        try {
            const response = yield api.updateWatchCase(token, watchCaseId, name, priority, status)
            const index = this.watchCases.findIndex((b) => {
                return b._id === watchCaseId
            })
            this.watchCases[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(error.response.data.messages)
        }
    })

    deleteWatchCase = flow(function* (token, watchCaseId) {
        this.isSubmitting = true
        try {
            yield api.removeWatchCase(token, watchCaseId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new WatchCaseStore()
