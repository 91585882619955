import React, { Component } from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { Spin } from 'antd'

const LoadingWrapper = styled.div`
    width: 100%;
    height: 100vh;
`

const LoadingContent = styled.div`
    height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
`

class Loading extends Component {

    render() {
        return (
            <LoadingWrapper>
                <LoadingContent>
                    <Spin size='large' />
                </LoadingContent>
            </LoadingWrapper>
        )
    }
}

export default Loading