import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { DeleteOutlined, EditOutlined, PlusOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import {
    Table,
    Button,
    message,
    Input,
    Tooltip,
    Checkbox,
    Row,
    DatePicker,
    Modal,
    Carousel,
    Divider,
    Popconfirm,
    Tag,
} from 'antd';
import { LIMIT } from '~/src/constants/common'
import { DATE_FORMAT } from '~/src/constants/format'
import messages from '~/src/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import FeedFormModal from '~/src/components/feedFormModal'
import { statusOptions, pageSizeOptions } from '~/src/constants/options'
import update from 'immutability-helper'
import ReactPlayer from 'react-player'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

const PlayerWrapper = styled.div`
    position: relative;
    height: 500px;
    width: 100%;
    padding-top: 56.25%;
    background-color: #000000;
    padding: 8px;
`

const Player = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

@inject('commonStore', 'feedStore') @observer
class FeedPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            feedFormVisible: false,
            pageSize: LIMIT,
            filterValues: {
                title: undefined,
                status: [],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortedInfo: undefined,
            selectedFeedId: undefined,
            previewVisible: false,
            previewImages: []
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { feedStore, commonStore, location } = this.props
        const { token, user } = commonStore
        const { pageSize, filterValues } = this.state
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        const canRead = toJS(user.rbac).find((r) => { return r.module == 'FEED' }).accessControl.includes('READ')
        if (canRead) {
            await feedStore.listFeed(token, pageSize, pageSize * (currentPage - 1),
                undefined,
                filterValues.title,
                filterValues.status,
                filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
            )
        }
        this.setState({ currentPage })
    }

    async handleOnFeedFormSubmit(values, reset) {
        const { feedStore, commonStore, intl } = this.props
        const { selectedFeedId, filterValues, pageSize, currentPage } = this.state
        const { token } = commonStore
        try {
            if (selectedFeedId) {
                await feedStore.updateFeed(token, selectedFeedId, values)
            } else {
                await feedStore.createFeed(token, values)
            }
            reset()
            message.success(intl.formatMessage({ ...messages.success }))
            this.setState({ feedFormVisible: false, selectedFeedId: undefined })
            await feedStore.listFeed(token, pageSize, pageSize * (currentPage - 1),
                sortField,
                filterValues.title,
                filterValues.status,
                filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }

    async handleOnFeedDelete(record) {
        const { feedStore, commonStore, intl } = this.props
        const { token } = commonStore
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        try {
            await feedStore.deleteFeed(token, record._id)
            message.success(intl.formatMessage({ ...messages.success }))
            await feedStore.listFeed(token, pageSize, pageSize * (currentPage - 1),
                sortField,
                filterValues.title,
                filterValues.status,
                filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }

    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { feedStore, commonStore } = this.props
        const { tokenmuser } = commonStore
        const { pageSize } = pagination
        const {
            title,
            status,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        const canRead = toJS(user.rbac).find((r) => { return r.module == 'FEED' }).accessControl.includes('READ')
        if (canRead) {
            feedStore.listFeed(token, pageSize, pageSize * (page - 1),
                sortField,
                title,
                status,
                createdAtMin ? createdAtMin.format() : undefined,
                createdAtMax ? createdAtMax.format() : undefined
            )
        }
        const href = `/feed?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    async handleOnUpdateClick(record) {
        this.setState({ feedFormVisible: true, selectedFeedId: record._id })
    }

    async handleOnAddNewClick() {
        this.setState({ feedFormVisible: true })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'title':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'createdAt':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'createdAtMin': { $set: undefined }, 'createdAtMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                title: undefined,
                status: [],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false, previewImages: [] })
    }

    handlePreview(photos) {
        this.setState({
            previewImages: photos,
            previewVisible: true,
        })
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'title':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'createdAt':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'title')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { intl, commonStore } = this.props
        const { user } = commonStore
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.title }),
                dataIndex: 'title',
                key: 'title',
                fixed: 'left',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'title' && sortedInfo.order,
                ...this.getColumnSearchProps('title')
            },
            {
                title: intl.formatMessage({ ...messages.photos }),
                dataIndex: 'photos',
                key: 'photos',
                fixed: 'left',
                width: 100,
                render: (text, record) => {
                    const thumbnail = record.photos[0] ? record.photos[0].path : null
                    return (
                        <ImageWrapper onClick={() => this.handlePreview(record.photos)}>
                            <img src={thumbnail} height={50} width={50} style={{ objectFit: 'contain' }} />
                            <div>{intl.formatMessage({ ...messages.photoCount }, { count: record.photos.length })}</div>
                        </ImageWrapper>
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.detail }),
                dataIndex: 'detail',
                key: 'detail',
                onCell: () => {
                    return {
                        style: {
                            whiteSpace: 'nowrap',
                            maxWidth: 200,
                        }
                    }
                },
                render: (text, record) => {
                    var tmp = document.createElement("DIV")
                    tmp.innerHTML = record.detail
                    return (
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: record.detail }}></div>}>
                            <EllipsisText>{tmp.textContent || tmp.innerText}</EllipsisText>
                        </Tooltip>
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text, record) => {
                    const index = statusOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...statusOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                width: 200,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('createdAt')
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                width: 100,
                render: (text, record) => {
                    const canUpdate = toJS(user.rbac).find((r) => { return r.module == 'FEED' }).accessControl.includes('UPDATE')
                    const canDelete = toJS(user.rbac).find((r) => { return r.module == 'FEED' }).accessControl.includes('DELETE')
                    return (
                        <span>
                            <Button.Group>
                                {
                                    canUpdate ?
                                        <Button
                                            icon={<EditOutlined />}
                                            onClick={() => this.handleOnUpdateClick(record)}
                                            title={intl.formatMessage({ ...messages.update })} />
                                        : null
                                }
                                {
                                    canDelete ?
                                        <Popconfirm
                                            title={intl.formatMessage({ ...messages.areYouSureToDelete })}
                                            onConfirm={() => this.handleOnFeedDelete(record)}
                                            okText={intl.formatMessage({ ...messages.yes })}
                                            okType='danger'
                                            cancelText={intl.formatMessage({ ...messages.no })}>
                                            <Button
                                                icon={<DeleteOutlined />}
                                                type="danger"
                                                title={intl.formatMessage({ ...messages.delete })} />
                                        </Popconfirm>
                                        : null
                                }
                            </Button.Group>
                        </span>
                    );
                }
            }
        ];
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'title':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.title })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'createdAtMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'createdAtMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { feedStore, commonStore, intl } = this.props
        const { user } = commonStore
        const { currentPage, pageSize, selectedFeedId } = this.state
        var selectedFeedIndex
        if (selectedFeedId) {
            const index = feedStore.feeds.findIndex(o => o._id === selectedFeedId)
            selectedFeedIndex = index
        }
        const canCreate = toJS(user.rbac).find((r) => { return r.module == 'FEED' }).accessControl.includes('CREATE')
        const canRead = toJS(user.rbac).find((r) => { return r.module == 'FEED' }).accessControl.includes('READ')
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.feed })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        {
                            canRead ?
                                <Button type="primary" icon={<RedoOutlined />} onClick={() => this.handleOnResetAllClick()}>
                                    {intl.formatMessage({ ...messages.resetAllSearch })}
                                </Button>
                                : null
                        }
                        {
                            canCreate ?
                                <Button type='primary' icon={<PlusOutlined />} onClick={() => this.handleOnAddNewClick()}>
                                    {intl.formatMessage({ ...messages.addNew })}
                                </Button>
                                : null
                        }
                    </ActionLeftWrapper>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(feedStore.feeds)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: feedStore.count,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={feedStore.isSubmitting}
                        rowKey={record => record._id}
                    />
                </TableWrapper>
                <FeedFormModal
                    feed={selectedFeedIndex > -1 ? toJS(feedStore.feeds[selectedFeedIndex]) : undefined}
                    visible={this.state.feedFormVisible}
                    isSubmitting={feedStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnFeedFormSubmit(values, reset)}
                    onClose={() => this.setState({ feedFormVisible: false, selectedFeedId: undefined })} />
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <Carousel>
                        {
                            this.state.previewImages.map((p, index) => {
                                return (
                                    <div key={index}>
                                        {
                                            p.type === 'VIDEO'
                                                ?
                                                <PlayerWrapper>
                                                    <Player>
                                                        <ReactPlayer
                                                            width='100%'
                                                            height='100%'
                                                            url={p.path}
                                                            controls={true} />
                                                    </Player>
                                                </PlayerWrapper>
                                                : null
                                        }
                                        {
                                            p.type === 'IMAGE'
                                                ? <img alt="example" style={{ width: '100%' }} src={p.path} />
                                                : null
                                        }
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </Modal>
            </Container>
        );
    }
}

export default withRouter(injectIntl(FeedPage))