import React, { Component } from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import { IntlProvider, addLocaleData } from "react-intl"
import zh from "react-intl/locale-data/zh"
import { ConfigProvider, Spin } from 'antd'
import zh_TW from 'antd/lib/locale-provider/zh_TW'
import en_US from 'antd/lib/locale-provider/en_US'
import translations from "./i18n/locales"
import queryString from 'query-string'
import moment from 'moment'
import PrivateRoute from '~/src/components/routes/privateRoute'
import PageLayout from '~/src/components/layouts/main'
import Loading from '~/src/components/loading'
import Helmet from 'react-helmet'


import authStore from './stores/authStore'
import commonStore from './stores/commonStore'
import enquiryStore from './stores/enquiryStore'
import feedStore from './stores/feedStore'
import sellWatchStore from './stores/sellWatchStore'
import userStore from './stores/userStore'
import watchBrandStore from './stores/watchBrandStore'
import watchCaseStore from './stores/watchCaseStore'
import watchFunctionStore from './stores/watchFunctionStore'
import watchItemStore from './stores/watchItemStore'
import watchMovementStore from './stores/watchMovementStore'
import watchSizeStore from './stores/watchSizeStore'
import filterStore from './stores/filterStore'
import storeStore from './stores/storeStore'
import settingsStore from './stores/settingsStore'
import mediaStore from './stores/mediaStore'
import testimonialStore from './stores/testimonialStore'
import utilStore from './stores/utilStore'
import accessControlStore from './stores/accessControlStore'

import LoginPage from './containers/LoginPage'
import ForgotPasswordPage from './containers/ForgotPasswordPage'
import ResetPasswordPage from './containers/ResetPasswordPage'
import EnquiryPage from './containers/EnquiryPage'
import FeedPage from './containers/FeedPage'
import SellWatchPage from './containers/SellWatchPage'
// import UserPage from './containers/UserPage'
import WatchBrandPage from './containers/WatchBrandPage'
import WatchCasePage from './containers/WatchCasePage'
import WatchFunctionPage from './containers/WatchFunctionPage'
import WatchItemPage from './containers/WatchItemPage'
import WatchMovementPage from './containers/WatchMovementPage'
import WatchSizePage from './containers/WatchSizePage'
import WatchItemDetailPage from './containers/WatchItemDetailPage'
import WatchItemCreatePage from './containers/WatchItemCreatePage'
import StorePage from './containers/StorePage'
import StaffPage from './containers/StaffPage'
import SettingsPage from './containers/SettingsPage'
import MediaPage from './containers/MediaPage'
import TestimonialPage from './containers/TestimonialPage'
import AccessControlPage from './containers/AccessControlPage'
import AccessControlDetailPage from './containers/AccessControlDetailPage'
import WatchBrandCreatePage from './containers/WatchBrandCreatePage'
import WatchBrandDetailPage from './containers/WatchBrandDetailPage'
import WatchCaseCreatePage from './containers/WatchCaseCreatePage'
import WatchCaseDetailPage from './containers/WatchCaseDetailPage'
import WatchFunctionCreatePage from './containers/WatchFunctionCreatePage'
import WatchFunctionDetailPage from './containers/WatchFunctionDetailPage'
import WatchMovementCreatePage from './containers/WatchMovementCreatePage'
import WatchMovementDetailPage from './containers/WatchMovementDetailPage'
import WatchSizeCreatePage from './containers/WatchSizeCreatePage'
import WatchSizeDetailPage from './containers/WatchSizeDetailPage'

addLocaleData([...zh])

moment.locale('zh-hk')

class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAppMounted: false
        }
    }

    isTokenExpired() {
        const tokenExpAt = localStorage.getItem('tokenExpAt')
        const expiresIn = Date.parse(tokenExpAt) - Date.now() // in milliseconds
        return expiresIn < 0
    }

    async componentDidMount() {
        if (localStorage.getItem('token') != null && !this.isTokenExpired()) {
            await authStore.getSelf(localStorage.getItem('token'))
        }
        this.setState({ isAppMounted: true })
    }

    render() {
        const params = queryString.parse(window.location.search)
        const locale = params.locale || 'en'
        const messages = translations[locale]
        const theme = {
            primary: '#8D6238'
        }

        if (this.state.isAppMounted) {
            return (
                <Provider
                    authStore={authStore}
                    commonStore={commonStore}
                    enquiryStore={enquiryStore}
                    feedStore={feedStore}
                    sellWatchStore={sellWatchStore}
                    userStore={userStore}
                    watchBrandStore={watchBrandStore}
                    watchCaseStore={watchCaseStore}
                    watchFunctionStore={watchFunctionStore}
                    watchItemStore={watchItemStore}
                    watchMovementStore={watchMovementStore}
                    watchSizeStore={watchSizeStore}
                    storeStore={storeStore}
                    filterStore={filterStore}
                    settingsStore={settingsStore}
                    mediaStore={mediaStore}
                    testimonialStore={testimonialStore}
                    utilStore={utilStore}
                    accessControlStore={accessControlStore}>
                    <IntlProvider locale={locale} messages={messages}>
                        <ConfigProvider locale={locale === 'zh' ? zh_TW : en_US}>
                            <ThemeProvider theme={theme}>
                                <Router>
                                    <Switch>
                                        <Route exact path="/">
                                            <Redirect to="/watchItem" />
                                        </Route>
                                        <Route exact path="/login" component={LoginPage} />
                                        <Route exact path="/forgotPassword" component={ForgotPasswordPage} />
                                        <Route exact path="/resetPassword" component={ResetPasswordPage} />
                                        <PageLayout>
                                            <PrivateRoute exact path="/enquiry" component={EnquiryPage} />
                                            <PrivateRoute exact path="/feed" component={FeedPage} />
                                            <PrivateRoute exact path="/sellWatch" component={SellWatchPage} />
                                            {/* <PrivateRoute exact path="/user" component={UserPage} /> */}
                                            <PrivateRoute exact path="/watchBrand" component={WatchBrandPage} />
                                            <PrivateRoute exact path="/watchBrand/create" component={WatchBrandCreatePage} />
                                            <PrivateRoute exact path="/watchBrand/:watchBrandId([0-9a-f]{24})" component={WatchBrandDetailPage} />
                                            <PrivateRoute exact path="/watchCase" component={WatchCasePage} />
                                            <PrivateRoute exact path="/watchCase/create" component={WatchCaseCreatePage} />
                                            <PrivateRoute exact path="/watchCase/:watchCaseId([0-9a-f]{24})" component={WatchCaseDetailPage} />
                                            <PrivateRoute exact path="/watchFunction" component={WatchFunctionPage} />
                                            <PrivateRoute exact path="/watchFunction/create" component={WatchFunctionCreatePage} />
                                            <PrivateRoute exact path="/watchFunction/:watchFunctionId([0-9a-f]{24})" component={WatchFunctionDetailPage} />
                                            <PrivateRoute exact path="/watchItem" component={WatchItemPage} />
                                            <PrivateRoute exact path="/watchItem/create" component={WatchItemCreatePage} />
                                            <PrivateRoute exact path="/watchItem/:watchItemId([0-9a-f]{24})" component={WatchItemDetailPage} />
                                            <PrivateRoute exact path="/watchMovement/create" component={WatchMovementCreatePage} />
                                            <PrivateRoute exact path="/watchMovement/:watchMovementId([0-9a-f]{24})" component={WatchMovementDetailPage} />
                                            <PrivateRoute exact path="/watchMovement" component={WatchMovementPage} />
                                            <PrivateRoute exact path="/watchSize/create" component={WatchSizeCreatePage} />
                                            <PrivateRoute exact path="/watchSize/:watchSizeId([0-9a-f]{24})" component={WatchSizeDetailPage} />
                                            <PrivateRoute exact path="/watchSize" component={WatchSizePage} />
                                            <PrivateRoute exact path="/store" component={StorePage} />
                                            <PrivateRoute exact path="/staff" component={StaffPage} />
                                            <PrivateRoute exact path="/settings" component={SettingsPage} />
                                            <PrivateRoute exact path="/media" component={MediaPage} />
                                            <PrivateRoute exact path="/testimonial" component={TestimonialPage} />
                                            <PrivateRoute exact path="/accessControl" component={AccessControlPage} />
                                            <PrivateRoute exact path="/accessControl/:role" component={AccessControlDetailPage} />
                                        </PageLayout>
                                    </Switch>
                                </Router>
                            </ThemeProvider>
                        </ConfigProvider>
                    </IntlProvider>
                </Provider>
            )
        } else {
            return <Loading />
        }
    }
}

export default App
