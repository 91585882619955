import messages from '~/src/messages'

export const statusOptions = [
    { value: 'ACTIVE', text: messages.active },
    { value: 'INACTIVE', text: messages.inactive },
]

export const pageSizeOptions = [
    '10',
    '20',
    '50',
    '100'
]

export const enquiryStatusOptions = [
    { value: 'PENDING', text: messages.pending },
    { value: 'PROCEEDED', text: messages.proceeded },
]

export const sellWatchStatusOptions = [
    { value: 'PENDING', text: messages.pending },
    { value: 'PROCEEDED', text: messages.proceeded },
]

export const watchItemStatusOptions = [
    { value: 'STOCK', text: messages.inStock },
    { value: 'SOLD', text: messages.sold },
]

export const watchBrandTypeOptions = [
    { value: 'WATCH', text: messages.watch },
    { value: 'JEWEL', text: messages.jewel },
]

export const watchItemTypeOptions = [
    { value: 'WATCHES', text: messages.watches },
    { value: 'OTHERS', text: messages.others },
]

export const watchItemPaymentTypeOptions = [
    { value: 'PRICE', text: messages.price },
    { value: 'LIST', text: messages.list },
    // { value: 'INSTAL12', text: messages.twelveMonthsInstallment },
    // { value: 'INSTAL24', text: messages.twentyFourMonthsInstallment },
    // { value: 'INSTAL1224', text: messages.twelveOrtwentyFourMonthsInstallment },
]

export const watchItemBehaviourOptions = [
    { value: 'SHOW_PRICE', text: messages.showPrice },
    { value: 'SHOW_CASH', text: messages.showCash },
    { value: 'SPECIAL_DISCOUNT', text: messages.specialDiscount }
]

export const watchItemConditionOptions = [
    { value: 'BOX', text: messages.box },
    { value: 'PAPER', text: messages.paper },
    { value: 'NEW', text: messages.new },
]

export const roleOptions = [
    { value: 'SUPERADMIN', text: messages.superadmin },
    { value: 'ADMIN', text: messages.admin },
    { value: 'ADVANCE', text: messages.advance },
    { value: 'STAFF', text: messages.staff },
]