import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/src/lib/imageCompressor'

class MediaStore {
    @observable isSubmitting = false
    @observable error = null
    @observable medias = []
    @observable count = 0

    createMedia = flow(function* (token, values) {
        this.isSubmitting = true
        const formData = new FormData()
        var mediaData = { ...values }
        delete mediaData.photos
        delete mediaData.type
        formData.append('media', JSON.stringify(mediaData))
        if (values.type == 'photos') {
            for (const f of values.photos) {
                const { originFileObj, type } = f
                if (originFileObj) {
                    if (type.split('/')[0].toLowerCase() === 'image') {
                        const thumbnail = yield makeOriginal(originFileObj)
                        formData.append('photos', thumbnail)
                    }
                }
            }
        }
        try {
            yield api.createMedia(token, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listMedia = flow(function* (token, limit, skip, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listMedia(token, limit, skip, status)
            const { medias, count } = response.data
            this.medias = medias
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateMedia = flow(function* (token, mediaId, values) {
        const formData = new FormData()
        var mediaData = { ...values }
        delete mediaData.photos
        delete mediaData.type
        formData.append('media', JSON.stringify(mediaData))

        if (values.photos) {
            const index = this.medias.findIndex((b) => {
                return b._id === mediaId
            })
            const originalPhotoIds = this.medias[index].photos.map(p => p)
            const preservedPhotoIds = []
            for (const f of values.photos) {
                const { originFileObj, type } = f
                if (originFileObj) {
                    const thumbnail = yield makeOriginal(originFileObj)
                    formData.append('photos', thumbnail)
                } else {
                    const { uid } = f
                    preservedPhotoIds.push(uid)
                }
            }
            const removePhotos = originalPhotoIds.filter(id => preservedPhotoIds.indexOf(id) < 0)

            removePhotos.forEach((p) => {
                formData.append('removePhotos[]', p)
            })
        }
        this.isSubmitting = true
        try {
            const response = yield api.updateMedia(token, mediaId, formData)
            const index = this.medias.findIndex((b) => {
                return b._id === mediaId
            })
            this.medias[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    deleteMedia = flow(function* (token, mediaId) {
        this.isSubmitting = true
        try {
            yield api.deleteMedia(token, mediaId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new MediaStore()
