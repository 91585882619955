import { observable, flow } from 'mobx'
import api from '../api'

class EnquiryStore {
    @observable isSubmitting = false
    @observable error = null
    @observable enquiries = []
    @observable count = 0
    @observable currentEnquiry = undefined

    listEnquiry = flow(function* (token, limit, skip, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listEnquiry(token, limit, skip, status)
            const { enquiries, count } = response.data
            this.enquiries = enquiries
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getEnquiry = flow(function* (token, enquiryId) {
        this.isSubmitting = true
        try {
            const response = yield api.getEnquiry(token, enquiryId)
            this.currentEnquiry = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateEnquiry = flow(function* (token, enquiryId, status, remark) {
        this.isSubmitting = true
        try {
            const response = yield api.updateEnquiry(token, enquiryId, status, remark)
            const index = this.enquiries.findIndex((b) => {
                return b._id === enquiryId
            })
            this.enquiries[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    deleteEnquiry = flow(function* (token, enquiryId) {
        this.isSubmitting = true
        try {
            yield api.deleteEnquiry(token, enquiryId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new EnquiryStore()
