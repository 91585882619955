import React, { Component } from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { Input, Button, AutoComplete } from 'antd'
import api from '../api'

const Wrapper = styled.div`

`

class NameInput extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { disabled, value, onChange, onTranslateClick, isSelection, onFieldSearch, options } = this.props
        console.log(value)
        return (
            <Wrapper>
                {
                    isSelection ?
                        <>
                            <AutoComplete
                                options={options ? options["en-US"] : []}
                                onSelect={(selected) => onChange({ ...value, 'en-US': selected })}
                                onSearch={(value) => onFieldSearch('en-US', value)}
                                value={value ? value['en-US'] : undefined}
                                searchValue={""}
                            >
                                <Input
                                    onChange={(e) => onChange({ ...value, 'en-US': e.target.value })}
                                    addonBefore={'English'}
                                    disabled={disabled}
                                />
                            </AutoComplete>
                            <AutoComplete
                                options={options ? options["zh-TW"] : []}
                                onSelect={(selected) => onChange({ ...value, 'zh-TW': selected })}
                                onSearch={(value) => onFieldSearch('zh-TW', value)}
                                value={value ? value['zh-TW'] : undefined}
                                searchValue={""}
                            >
                                <Input
                                    onChange={(e) => onChange({ ...value, 'zh-TW': e.target.value })}
                                    addonBefore={'繁'}
                                    disabled={disabled}
                                />
                            </AutoComplete>
                            <AutoComplete
                                options={options ? options["zh-CN"] : []}
                                onSelect={(selected) => onChange({ ...value, 'zh-CN': selected })}
                                onSearch={(value) => onFieldSearch('zh-CN', value)}
                                value={value ? value['zh-CN'] : undefined}
                                searchValue={""}
                            >
                                <Input
                                    onChange={(e) => onChange({ ...value, 'zh-CN': e.target.value })}
                                    addonBefore={'简'}
                                    addonAfter={<Button size="small" onClick={() => onTranslateClick()}>由繁轉簡</Button>}
                                    disabled={disabled}
                                />
                            </AutoComplete>
                        </>
                        :
                        <>
                            <Input
                                value={value ? value['en-US'] : undefined}
                                disabled={disabled}
                                onChange={(e) => onChange({ ...value, 'en-US': e.target.value })}
                                placeholder={'English'}
                                addonBefore={'English'} />
                            <Input
                                value={value ? value['zh-TW'] : undefined}
                                disabled={disabled}
                                onChange={(e) => onChange({ ...value, 'zh-TW': e.target.value })}
                                placeholder={'繁'}
                                addonBefore={'繁'} />
                            <Input
                                value={value ? value['zh-CN'] : undefined}
                                disabled={disabled}
                                onChange={(e) => onChange({ ...value, 'zh-CN': e.target.value })}
                                placeholder={'简'}
                                addonBefore={'简'}
                                addonAfter={<Button size="small" onClick={() => onTranslateClick()}>由繁轉簡</Button>}
                            />
                        </>
                }


            </Wrapper>
        )
    }
}

export default injectIntl(NameInput)