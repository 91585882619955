import { defineMessages } from 'react-intl'

export default defineMessages({
    companyName: { id: 'companyName', defaultMessage: "Ken's Watches" },
    copyright: { id: 'copyright', defaultMessage: "All Rights reserved © Ken's Watches" },
    login: { id: 'login', defaultMessage: 'Login' },
    logout: { id: 'logout', defaultMessage: 'Logout' },
    email: { id: 'email', defaultMessage: 'Email' },
    password: { id: 'password', defaultMessage: 'Password' },
    forgotPassword: { id: 'forgotPassword', defaultMessage: 'Forgot Password' },
    resetPassword: { id: 'resetPassword', defaultMessage: 'Reset Password' },
    newPassword: { id: 'newPassword', defaultMessage: 'New Password' },
    confirmPassword: { id: 'confirmPassword', defaultMessage: 'Confirm Password' },
    passwordNotMatch: { id: 'passwordNotMatch', defaultMessage: 'Password not match' },


    // Actions
    confirm: { id: 'confirm', defaultMessage: 'Confirm' },
    cancel: { id: 'cancel', defaultMessage: 'Cancel' },
    reset: { id: 'reset', defaultMessage: 'Reset' },
    resetAllSearch: { id: 'resetAllSearch', defaultMessage: 'Reset all search' },
    itemId: { id: 'itemId', defaultMessage: 'Item Id' },
    search: { id: 'search', defaultMessage: 'Search' },
    actions: { id: 'actions', defaultMessage: 'Actions' },
    addNew: { id: 'addNew', defaultMessage: 'Add New' },
    update: { id: 'update', defaultMessage: 'Update' },
    delete: { id: 'delete', defaultMessage: 'Delete' },
    submit: { id: 'submit', defaultMessage: 'Submit' },
    areYouSureToDelete: { id: 'areYouSureToDelete', defaultMessage: 'Are you sure to delete?' },
    success: { id: 'success', defaultMessage: 'Success' },
    failure: { id: 'failure', defaultMessage: 'Failure' },
    yes: { id: 'yes', defaultMessage: 'Yes' },
    no: { id: 'no', defaultMessage: 'No' },
    startTime: { id: 'startTime', defaultMessage: 'Start Time' },
    endTime: { id: 'endTime', defaultMessage: 'End Time' },
    photoCount: { id: 'photoCount', defaultMessage: 'Photo Count: {count}' },
    createdAtMin: { id: 'createdAtMin', defaultMessage: 'Created At Min.' },
    createdAtMax: { id: 'createdAtMax', defaultMessage: 'Created At Max.' },

    // Fields
    name: { id: 'name', defaultMessage: 'Name' },
    detail: { id: 'detail', defaultMessage: 'Detail' },
    content: { id: 'content', defaultMessage: 'Content' },
    remark: { id: 'remark', defaultMessage: 'Remark' },
    status: { id: 'status', defaultMessage: 'Status' },
    contact: { id: 'contact', defaultMessage: 'Contact' },
    createdAt: { id: 'createdAt', defaultMessage: 'Created At' },
    updatedAt: { id: 'updatedAt', defaultMessage: 'Updated At' },
    publishedAt:{ id: 'publishedAt', defaultMessage: 'Published At' },

    pending: { id: 'pending', defaultMessage: 'Pending' },
    proceeded: { id: 'proceeded', defaultMessage: 'Proceeded' },
    active: { id: 'active', defaultMessage: 'Active' },
    inactive: { id: 'inactive', defaultMessage: 'Inactive' },

    watch: { id: 'watch', defaultMessage: 'Watch' },
    watchItem: { id: 'watchItem', defaultMessage: 'Watch Item' },
    watchBrand: { id: 'watchBrand', defaultMessage: 'Brand' },
    watchCase: { id: 'watchCase', defaultMessage: 'Material' },
    watchFunction: { id: 'watchFunction', defaultMessage: 'Function' },
    watchMovement: { id: 'watchMovement', defaultMessage: 'Movement' },
    watchSize: { id: 'watchSize', defaultMessage: 'Size' },
    sellWatch: { id: 'sellWatch', defaultMessage: 'Sell Watch' },
    enquiry: { id: 'enquiry', defaultMessage: 'Enquiry' },
    feed: { id: 'feed', defaultMessage: 'Feed' },
    photos: { id: 'photos', defaultMessage: 'Photos' },
    photo: { id: 'photo', defaultMessage: 'Photo' },
    priority: { id: 'priority', defaultMessage: 'Priority' },
    description: { id: 'description', defaultMessage: 'Description' },
    model: { id: 'model', defaultMessage: 'Model' },


    showTotalDisplayText: { id: 'showTotalDisplayText', defaultMessage: 'Total {total} items' },

    pleaseSelect: { id: 'pleaseSelect', defaultMessage: 'Please Select' },
    pleaseInput: { id: 'pleaseInput', defaultMessage: 'Please Input' },
    pleaseConfirmPassword: { id: 'pleaseConfirmPassword', defaultMessage: 'Please confirm password' },
    pleaseCheckYourEmail: { id: 'pleaseCheckYourEmail', defaultMessage: 'Please check your email' },
    uploadPhoto: { id: 'uploadPhoto', defaultMessage: 'Upload Photo' },
    title: { id: 'title', defaultMessage: 'Title' },
    passwordHasChangedPleaseLoginAgain: { id: 'passwordHasChangedPleaseLoginAgain', defaultMessage: 'Password has changed. Please login again.' },
    nameNotFound: { id: 'nameNotFound', defaultMessage: 'Name not found' },
    wrongPassword: { id: 'wrongPassword', defaultMessage: 'Wrong password' },
    keywords: { id: 'keywords', defaultMessage: 'Keywords' },
    nameOrModel: { id: 'nameOrModel', defaultMessage: 'Name or model' },
    modelRefNumber: { id: 'modelRefNumber', defaultMessage: 'Model ref. number' },
    inStock: { id: 'inStock', defaultMessage: 'In Stock' },
    sold: { id: 'sold', defaultMessage: 'Sold' },
    watches: { id: 'watches', defaultMessage: 'Watches' },
    others: { id: 'others', defaultMessage: 'Others' },
    jewel: { id: 'jewel', defaultMessage: 'Jewel' },
    watch: { id: 'watch', defaultMessage: 'Watch' },
    isOthers: { id: 'isOthers', defaultMessage: 'is Others' },
    price: { id: 'price', defaultMessage: 'Price' },
    list: { id: 'list', defaultMessage: 'List' },
    twelveMonthsInstallment: { id: 'twelveMonthsInstallment', defaultMessage: '12 months installment' },
    twentyFourMonthsInstallment: { id: 'twentyFourMonthsInstallment', defaultMessage: '24 months installment' },
    twelveOrtwentyFourMonthsInstallment: { id: 'twelveOrtwentyFourMonthsInstallment', defaultMessage: '12 / 24 months installment' },
    showPrice: { id: 'showPrice', defaultMessage: 'Show Price' },
    showCash: { id: 'showCash', defaultMessage: 'Show Cash' },
    specialDiscount: { id: 'specialDiscount', defaultMessage: 'Special Discount' },
    verified: { id: 'verified', defaultMessage: 'Verified' },
    box: { id: 'box', defaultMessage: 'Box' },
    paper: { id: 'paper', defaultMessage: 'Paper' },
    new: { id: 'new', defaultMessage: 'New' },
    type: { id: 'type', defaultMessage: 'Type' },
    condition: { id: 'condition', defaultMessage: 'Condition' },
    apply: { id: 'apply', defaultMessage: 'Apply' },
    priceRange: { id: 'priceRange', defaultMessage: 'Price Range' },
    isPublished: { id: 'isPublished', defaultMessage: 'Is Published' },
    notPublished: { id: 'notPublished', defaultMessage: 'Not Published' },
    isNew: { id: 'isNew', defaultMessage: 'Is New' },
    store: { id: 'store', defaultMessage: 'Store' },
    all: { id: 'all', defaultMessage: 'All' },
    paymentType: { id: 'paymentType', defaultMessage: 'Payment Type' },
    behaviour: { id: 'behaviour', defaultMessage: 'Behaviour' },
    brandRemark: { id: 'brandRemark', defaultMessage: 'Brand Remark' },
    sizeRemark: { id: 'sizeRemark', defaultMessage: 'Size Remark' },
    material: { id: 'material', defaultMessage: 'Material' },
    year: { id: 'year', defaultMessage: 'Year' },
    listedPrice: { id: 'listedPrice', defaultMessage: 'Listed Price' },
    priceRemark: { id: 'priceRemark', defaultMessage: 'Price Remark' },
    cash: { id: 'cash', defaultMessage: 'Cash' },
    cashRemark: { id: 'cashRemark', defaultMessage: 'Cash Remark' },
    back: { id: 'back', defaultMessage: 'Back' },
    updateWatchItem: { id: 'updateWatchItem', defaultMessage: 'Update Watch Item' },
    addNewWatchItem: { id: 'addNewWatchItem', defaultMessage: 'Add New Watch Item' },
    mainPhoto: { id: 'mainPhoto', defaultMessage: 'Main Photo' },
    isMainPhoto: { id: 'isMainPhoto', defaultMessage: 'Is Main Photo' },
    filter: { id: 'filter', defaultMessage: 'Filter' },
    view: { id: 'view', defaultMessage: 'View' },
    locationId: { id: 'locationId', defaultMessage: 'Location Id' },
    locationCode: { id: 'locationCode', defaultMessage: 'Location Code' },
    openHour: { id: 'openHour', defaultMessage: 'Open Hour' },
    tel: { id: 'tel', defaultMessage: 'Tel' },
    address: { id: 'address', defaultMessage: 'Address' },
    googleEmbedMap: { id: 'googleEmbedMap', defaultMessage: 'Google Embed Map' },
    staff: { id: 'staff', defaultMessage: 'Staff' },
    nameDuplicated: { id: 'nameDuplicated', defaultMessage: 'Name Duplicated' },
    yourAccountHasBeenInactived: { id: 'yourAccountHasBeenInactived', defaultMessage: 'Your account has been inactived' },
    changeStaffPassword: { id: 'changeStaffPassword', defaultMessage: 'Change Staff Password' },
    latestUpdatedDate: { id: 'latestUpdatedDate', defaultMessage: 'Latest Update Date' },
    latestNews: { id: 'latestNews', defaultMessage: 'Latest News' },
    instalmentRate: { id: 'instalmentRate', defaultMessage: 'Instalment Rate' },
    instalments: { id: 'instalments', defaultMessage: 'instalments' },
    INSTAL12: { id: 'INSTAL12', defaultMessage: '12 months instalment' },
    INSTAL24: { id: 'INSTAL24', defaultMessage: '24 months instalment' },
    INSTAL36: { id: 'INSTAL36', defaultMessage: '36 months instalment' },
    mainPageBanners: { id: 'mainPageBanners', defaultMessage: 'Main Page Banners' },
    aboutUsPageBanner: { id: 'aboutUsPageBanner', defaultMessage: 'About Us Page Banner' },
    buyPageBanner: { id: 'buyPageBanner', defaultMessage: 'Buy Page Banner' },
    soldPageBanner: { id: 'soldPageBanner', defaultMessage: 'Sold Page Banner' },
    sellPageBanner: { id: 'sellPageBanner', defaultMessage: 'Sell Page Banner' },
    repairPageBanner: { id: 'repairPageBanner', defaultMessage: 'Repair Page Banner' },
    enquiryPageBanner: { id: 'enquiryPageBanner', defaultMessage: 'Enquiry Page Banner' },
    feedPageBanner: { id: 'feedPageBanner', defaultMessage: 'Feed Page Banner' },
    testimonialPageBanner: { id: 'testimonialPageBanner', defaultMessage: 'Testimonial Page Banner' },
    mediaPageBanner: { id: 'mediaPageBanner', defaultMessage: 'Media Page Banner' },
    termPageBanner: { id: 'termPageBanner', defaultMessage: 'Term Page Banner' },
    contactPageBanner: { id: 'contactPageBanner', defaultMessage: 'Contact Page Banner' },
    dealershipBanners: { id: 'dealershipBanners', defaultMessage: 'DealerShip Banners' },
    settings: { id: 'settings', defaultMessage: 'Settings' },
    link: { id: 'link', defaultMessage: 'Link' },
    youtubeLink: { id: 'youtubeLink', defaultMessage: 'Youtube Link' },
    media: { id: 'media', defaultMessage: 'Media' },
    testimonial: { id: 'testimonial', defaultMessage: 'Testimonial' },
    updateEnquiryFailure: { id: 'updateEnquiryFailure', defaultMessage: 'update enquiry failure' },
    updateEnquirySuccess: { id: 'updateEnquirySuccess', defaultMessage: 'update enquiry success' },
    deleteEnquirySuccess: { id: 'deleteEnquirySuccess', defaultMessage: 'delete enquiry success' },
    deleteEnquiryFailure: { id: 'deleteEnquiryFailure', defaultMessage: 'delete enquiry failure' },
    askingPrice: { id: 'askingPrice', defaultMessage: 'asking price' },
    referenceNumber: { id: 'referenceNumber', defaultMessage: 'Reference Number' },
    deleteSellWatchSuccess: { id: 'deleteSellWatchSuccess', defaultMessage: 'delete sell watch success' },
    deleteSellWatchFailure: { id: 'deleteSellWatchFailure', defaultMessage: 'delete sell watch fail' },
    updateSellWatchSuccess: { id: 'updateSellWatchSuccess', defaultMessage: 'update sell watch success' },
    updateSellWatchFailure: { id: 'updateSellWatchFailure', defaultMessage: 'update sell watch fail' },
    accessControl: { id: 'accessControl', defaultMessage: 'Access control' },
    module: { id: 'module', defaultMessage: 'module' },
    role: { id: 'role', defaultMessage: 'role' },
    updateAccessControl: { id: 'updateAccessControl', defaultMessage: 'Update Access Control' },
    create: { id: 'create', defaultMessage: 'create' },
    read: { id: 'read', defaultMessage: 'read' },
    publish: { id: 'publish', defaultMessage: 'publish' },
    superadmin: { id: 'superadmin', defaultMessage: 'Super Admin' },
    admin: { id: 'admin', defaultMessage: 'Admin' },
    advance: { id: 'advance', defaultMessage: 'Advance' },
    brandId: { id: 'brandId', defaultMessage: 'Brand id' },
    caseId: { id: 'caseId', defaultMessage: 'Material id' },
    functionId:{ id: 'functionId', defaultMessage: 'Function id' },
    sizeId:{ id: 'sizeId', defaultMessage: 'Size id' },
    movementId:{ id: 'movementId', defaultMessage: 'Movement id' },
    addNewWatchBrand:{ id: 'addNewWatchBrand', defaultMessage: 'Add New Watch Brand' },
    updateWatchBrand:{ id: 'updateWatchBrand', defaultMessage: 'Update Watch Brand' },
    addNewWatchCase:{ id: 'addNewWatchCase', defaultMessage: 'Add New Watch Material' },
    updateWatchCase:{ id: 'updateWatchCase', defaultMessage: 'Update Watch Material' },
    addNewWatchFunction:{ id: 'addNewWatchFunction', defaultMessage: 'Add New Watch Function' },
    updateWatchFunction:{ id: 'updateWatchFunction', defaultMessage: 'Update Watch Function' },
    addNewWatchMovement:{ id: 'addNewWatchMovement', defaultMessage: 'Add New Watch Movement' },
    updateWatchMovement:{ id: 'updateWatchMovement', defaultMessage: 'Update Watch Movement' },
    addNewWatchSize:{ id: 'addNewWatchSize', defaultMessage: 'Add New Watch Size' },
    updateWatchSize:{ id: 'updateWatchSize', defaultMessage: 'Update Watch Size' },
})
