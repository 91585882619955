import { observable, flow } from 'mobx'
import api from '../api'

class SellWatchStore {
    @observable isSubmitting = false
    @observable error = null
    @observable sellWatches = []
    @observable count = 0
    @observable currentSellWatch = undefined

    listSellWatch = flow(function* (token, limit, skip, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listSellWatch(token, limit, skip, status)
            const { sellWatches, count } = response.data
            this.sellWatches = sellWatches
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getSellWatch = flow(function* (token, sellWatchId) {
        this.isSubmitting = true
        try {
            const response = yield api.getSellWatch(token, sellWatchId)
            this.currentSellWatch = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateSellWatch = flow(function* (token, sellWatchId, status, remark) {
        this.isSubmitting = true
        try {
            const response = yield api.updateSellWatch(token, sellWatchId, status, remark)
            const index = this.sellWatches.findIndex((b) => {
                return b._id === sellWatchId
            })
            this.sellWatches[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    deleteSellWatch = flow(function* (token, sellWatchId) {
        this.isSubmitting = true
        try {
            yield api.removeSellWatch(token, sellWatchId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new SellWatchStore()
