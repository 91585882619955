import React, { Component } from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'

const Wrapper = styled.div`
    border: 1px solid #D9D9D9;
    padding: 8px;
`

class NameDisplaySection extends Component {

    render() {
        const { temp, value } = this.props
        var tags = []
        if (value && value['en-US']) {
            tags.push({ key: 'En', value: value['en-US'] })
        }
        if (value && value['zh-TW']) {
            tags.push({ key: '繁', value: value['zh-TW'] })
        }
        if (value && value['zh-CN']) {
            tags.push({ key: '简', value: value['zh-CN'] })
        }
        if (tags.length > 0) {
            return (
                <Wrapper>
                    {
                        tags.map((n, i) => <div key={i}>{`${n.key}: ${n.value}`}</div>)
                    }
                </Wrapper>
            )
        } else {
            return '-'
        }
    }
}

export default injectIntl(NameDisplaySection)