import { observable, flow, toJS } from "mobx";
import api from "../api";
import { makeThumbnail, makeOriginal } from "~/src/lib/imageCompressor";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

class WatchItemStore {
  @observable isSubmitting = false;
  @observable error = null;
  @observable watchItems = [];
  @observable count = 0;
  @observable currentWatchItem = undefined;
  @observable modelRefNumberOptions = [];
  @observable sizeRemarkOptions = {};
  @observable modelOptions = {};
  @observable remarkOptions = {};
  @observable priceRemarkOptions = [];
  @observable cashRemarkOptions = [];

  resetCurrentWatchItem = flow(function* () {
    this.currentWatchItem = undefined;
    return Promise.resolve();
  });

  createWatchItem = flow(function* (token, values) {
    this.isSubmitting = true;
    const formData = new FormData();
    var watchData = { ...values };
    delete watchData.mainPhoto;
    delete watchData.photos;
    formData.append("watch", JSON.stringify(watchData));

    for (const f of values.photos) {
      const { originFileObj, type } = f;
      if (originFileObj) {
        if (type.split("/")[0].toLowerCase() === "image") {
          var thumbnail = yield makeOriginal(originFileObj);
          formData.append("photos", thumbnail);
        }
      }
    }

    for (const f of values.mainPhoto) {
      const { originFileObj, type } = f;
      if (originFileObj) {
        if (type.split("/")[0].toLowerCase() === "image") {
          var thumbnail = yield makeOriginal(originFileObj);
          formData.append("mainPhoto", thumbnail);
        }
      }
    }

    try {
      yield api.createWatchItem(token, formData);
      this.error = null;
      this.isSubmitting = false;
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      };
      this.isSubmitting = false;
      return Promise.reject();
    }
  });

  updateWatchItemPublishStatus = flow(function* (
    token,
    watchItemId,
    isPublished
  ) {
    try {
      const response = yield api.updateWatchItemPublishStatus(
        token,
        watchItemId,
        isPublished
      );
      const index = this.watchItems.findIndex((b) => {
        return b._id === watchItemId;
      });
      this.watchItems[index] = response.data;
      let _currentWatchItem = {
        ...this.currentWatchItem,
        isPublished,
        publishedAt: response.data.publishedAt,
      };
      this.currentWatchItem = _currentWatchItem;
      this.error = null;
      this.isSubmitting = false;
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      };
      this.isSubmitting = false;
      return Promise.reject();
    }
  });

  listWatchItem = flow(function* (token, limit, skip, filters) {
    this.isSubmitting = true;
    try {
      const response = yield api.listWatchItem(token, limit, skip, filters);
      const { watchItems, count } = response.data;
      this.watchItems = watchItems;
      this.count = count;
      this.error = null;
    } catch (error) {
      console.log(error);
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      };
    }
    this.isSubmitting = false;
  });

  getWatchItem = flow(function* (token, watchItemId) {
    this.isSubmitting = true;
    try {
      const response = yield api.getWatchItem(token, watchItemId);
      this.currentWatchItem = response.data;
      this.error = null;
    } catch (error) {
      console.log(error);
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      };
    }
    this.isSubmitting = false;
  });

  modelRefNumberAutocomplete = flow(function* (token, q) {
    try {
      const response = yield api.modelRefNumberAutocomplete(token, q);
      let modelRefNumberOptions = [
        ...response.data.map((f) => {
          return { value: f.modelRefNumber };
        }),
      ];
      if (q.replace(/\s/g, "") !== "") {
        modelRefNumberOptions.unshift({ value: q });
      }
      this.modelRefNumberOptions = modelRefNumberOptions;
      this.error = null;
    } catch (error) {
      console.log(error);
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      };
    }
  });

  remarkAutocomplete = flow(function* (token, field, lang, q) {
    console.log(field);
    console.log(q);
    try {
      switch (field) {
        case "remark":
          const remarkResponse = yield api.remarkAutocomplete(
            token,
            `${field}.${lang}`,
            q
          );
          let remarkOptionsData = [
            ...remarkResponse.data.map((f) => {
              return { value: f.remark[lang] };
            }),
          ];
          if (q.replace(/\s/g, "") !== "") {
            remarkOptionsData.unshift({ value: q });
          }
          this.remarkOptions[lang] = remarkOptionsData;
          break;
        case "sizeRemark":
          const sizeRemarkResponse = yield api.remarkAutocomplete(
            token,
            `${field}.${lang}`,
            q
          );
          let sizeRemarkOptionsData = [
            ...sizeRemarkResponse.data.map((f) => {
              return { value: f.sizeRemark[lang] };
            }),
          ];
          if (q.replace(/\s/g, "") !== "") {
            sizeRemarkOptionsData.unshift({ value: q });
          }
          this.sizeRemarkOptions[lang] = sizeRemarkOptionsData;
          break;
        case "model":
          const modelResponse = yield api.remarkAutocomplete(
            token,
            `${field}.${lang}`,
            q
          );
          let modelOptionsData = [
            ...modelResponse.data.map((f) => {
              return { value: f.model[lang] };
            }),
          ];
          if (q.replace(/\s/g, "") !== "") {
            modelOptionsData.unshift({ value: q });
          }
          this.modelOptions[lang] = modelOptionsData;
          break;
        case "priceRemark":
          const priceRemarkResponse = yield api.remarkAutocomplete(
            token,
            field,
            q
          );
          let priceRemarkOptionsData = [
            ...priceRemarkResponse.data.map((f) => {
              return { value: f.priceRemark };
            }),
          ];
          if (q.replace(/\s/g, "") !== "") {
            priceRemarkOptionsData.unshift({ value: q });
          }
          this.priceRemarkOptions = priceRemarkOptionsData;
          break;
        case "cashRemark":
          const cashRemarkResponse = yield api.remarkAutocomplete(
            token,
            field,
            q
          );
          let cashRemarkOptionsData = [
            ...cashRemarkResponse.data.map((f) => {
              return { value: f.cashRemark };
            }),
          ];
          if (q.replace(/\s/g, "") !== "") {
            cashRemarkOptionsData.unshift({ value: q });
          }
          this.cashRemarkOptions = cashRemarkOptionsData;
          break;
        default:
          break;
      }

      this.error = null;
    } catch (error) {
      console.log(error);
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      };
    }
  });

  getWatchItemByModelRefNumber = flow(function* (token, modelRefNumber) {
    this.isSubmitting = true;
    try {
      const response = yield api.listWatchItem(token, 1, 0, {
        modelRefNumber: modelRefNumber,
      });
      if (response.data) {
        const { watchItems } = response.data;
        if (watchItems.length) {
          this.currentWatchItem = {
            ...watchItems[0],
            photos: [],
            mainPhoto: undefined,
            isPublished: false,
          };
          this.error = null;
        } else {
          this.currentWatchItem = undefined;
          this.error = null;
        }
      }
    } catch (error) {
      console.log(error);
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      };
    }
    this.isSubmitting = false;
  });

  updateWatchItem = flow(function* (token, watchItemId, values) {
    const formData = new FormData();
    var watchData = { ...values };
    delete watchData.mainPhoto;
    delete watchData.photos;
    formData.append("watch", JSON.stringify(watchData));

    var isMainPhotoChanged = false;
    for (const f of values.mainPhoto) {
      const { originFileObj, type } = f;
      if (originFileObj) {
        if (type.split("/")[0].toLowerCase() === "image") {
          var thumbnail = yield makeOriginal(originFileObj);
          formData.append("mainPhoto", thumbnail);
          isMainPhotoChanged = true;
        }
      }
    }

    const originalPhotoUrls = toJS(this.currentWatchItem.photos);
    const preservedPhotoUrls = [];
    if (!isMainPhotoChanged) {
      originalPhotoUrls.forEach((p) => {
        const splitResult = p.split("/");
        const fileName = splitResult[splitResult.length - 1];
        if (this.currentWatchItem.mainPhoto === fileName) {
          preservedPhotoUrls.push(p);
        }
      });
    }
    for (const f of values.photos) {
      const { originFileObj, type } = f;
      if (originFileObj) {
        if (type.split("/")[0].toLowerCase() === "image") {
          var thumbnail = yield makeOriginal(originFileObj);
          formData.append("photos", thumbnail);
        }
      } else {
        const { url } = f;
        preservedPhotoUrls.push(url.replace(`${IMAGE_URL}/`, ""));
      }
    }
    const removePhotos = originalPhotoUrls.filter(
      (url) => preservedPhotoUrls.indexOf(url) < 0
    );
    removePhotos.forEach((p) => {
      formData.append("removePhotos[]", p);
    });

    this.isSubmitting = true;
    try {
      const response = yield api.updateWatchItem(token, watchItemId, formData);
      const index = this.watchItems.findIndex((b) => {
        return b._id === watchItemId;
      });
      this.watchItems[index] = response.data;
      this.error = null;
      this.isSubmitting = false;
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      };
      this.isSubmitting = false;
      return Promise.reject();
    }
  });

  deleteWatchItem = flow(function* (token, watchItemId) {
    this.isSubmitting = true;
    try {
      yield api.removeWatchItem(token, watchItemId);
      this.error = null;
      this.isSubmitting = false;
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      };
      this.isSubmitting = false;
      return Promise.reject();
    }
  });
}

export default new WatchItemStore();
