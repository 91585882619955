import React from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, Table, InputNumber, Input, Modal, Button } from "antd";
import { injectIntl } from "react-intl";
import { toJS } from "mobx";
import messages from "~/src/messages";
import { getRandomId } from "~/src/lib/idGenerator";

class MainPageBannerListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImage: undefined,
      previewVisible: false,
    };
  }

  getEmptyRecord(priority) {
    return {
      key: getRandomId(),
      photo: [],
      link: undefined,
    };
  }

  handlePreviewCancel() {
    this.setState({ previewVisible: false });
  }

  handlePreview(file) {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }

  handleOnAddNewClick() {
    var oldRecords = [...this.props.value];
    // oldRecords.splice(
    //   oldRecords.length,
    //   0,
    //   this.getEmptyRecord(oldRecords.length + 1)
    // );
    oldRecords.unshift(this.getEmptyRecord())
    this.props.onChange(oldRecords);
  }

  handleOnDeleteClick(index) {
    var oldRecords = [...this.props.value];
    if (oldRecords.length > 1) {
      oldRecords.splice(index, 1);
      this.props.onChange(oldRecords);
    }
  }

  handleOnValueChange(index, newValue) {
    var oldChoices = [...this.props.value];
    oldChoices[index] = { ...oldChoices[index], ...newValue };
    this.props.onChange(oldChoices);
  }

  handleOnPhotoChange(index, e) {
    console.log(e);
    var oldRecords = this.props.value;
    oldRecords[index].photo = e.fileList;
    this.props.onChange(oldRecords);
  }

  handleOnMainPhotoChange(index, value) {
    var oldRecords = this.props.value;
    var newRecords = oldRecords.map((v, i) => {
      return {
        ...v,
        isMainPhoto: i === index ? value : false,
      };
    });
    this.props.onChange(newRecords);
  }

  renderTableColumn() {
    const { intl, disabled, user } = this.props;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">
          {intl.formatMessage({ ...messages.uploadPhoto })}
        </div>
      </div>
    );
    return [
      {
        title: intl.formatMessage({ ...messages.photo }),
        dataIndex: "photo",
        key: "photo",
        width: 100,
        render: (text, record, index) => {
          const canDelete = toJS(user.rbac)
            .find((r) => {
              return r.module == "BANNERS";
            })
            .accessControl.includes("DELETE");
          const canUpdate = toJS(user.rbac)
            .find((r) => {
              return r.module == "BANNERS";
            })
            .accessControl.includes("UPDATE");
          return (
            <Upload
              fileList={record.photo}
              onChange={(e) => this.handleOnPhotoChange(index, e)}
              listType="picture-card"
              onPreview={(file) => this.handlePreview(file)}
              disabled={!canUpdate || !canDelete || disabled}
              beforeUpload={(file) => {
                return false;
              }}
            >
              {record.photo.length >= 1 ? null : uploadButton}
            </Upload>
          );
        },
      },
      {
        title: intl.formatMessage({ ...messages.link }),
        dataIndex: "link",
        key: "link",
        render: (text, record, index) => {
          const canUpdate = toJS(user.rbac)
            .find((r) => {
              return r.module == "BANNERS";
            })
            .accessControl.includes("UPDATE");
          return (
            <Input
              disabled={!canUpdate || disabled}
              value={record.link}
              onChange={(e) =>
                this.handleOnValueChange(index, { link: e.target.value })
              }
              placeholder={intl.formatMessage({ ...messages.pleaseInput })}
            />
          );
        },
      },
      {
        title: intl.formatMessage({ ...messages.actions }),
        key: "actions",
        width: 70,
        render: (text, record, index) => {
          const canDelete = toJS(user.rbac)
            .find((r) => {
              return r.module == "BANNERS";
            })
            .accessControl.includes("DELETE");
          return (
            <span>
              {canDelete ? (
                <Button
                  disabled={disabled}
                  icon={<DeleteOutlined />}
                  onClick={() => this.handleOnDeleteClick(index)}
                  title={intl.formatMessage({ ...messages.delete })}
                />
              ) : null}
            </span>
          );
        },
      },
    ];
  }

  render() {
    const { value, intl, disabled, user } = this.props;
    console.log(value);
    const canCreate = toJS(user.rbac)
      .find((r) => {
        return r.module == "BANNERS";
      })
      .accessControl.includes("CREATE");
    return (
      <div>
        <Button
          icon={<PlusOutlined />}
          disabled={value.length >= 10 || disabled || !canCreate}
          onClick={() => this.handleOnAddNewClick()}
        >
          {intl.formatMessage({ ...messages.addNew })}
        </Button>

        <Table
          columns={this.renderTableColumn()}
          dataSource={value}
          rowKey={(record) => record.key}
          pagination={false}
        />

        <Modal
          visible={this.state.previewVisible}
          footer={null}
          onCancel={() => this.handlePreviewCancel()}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </div>
    );
  }
}

export default injectIntl(MainPageBannerListTable);
