import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import {
    Table,
    Breadcrumb,
    Button,
    message,
    Input,
    Tooltip,
    Checkbox,
    Row,
    DatePicker,
    Modal,
    Carousel,
    Divider,
    Popconfirm,
    Tag,
} from 'antd';
import { LIMIT } from '~/src/constants/common'
import { DATE_FORMAT } from '~/src/constants/format'
import messages from '~/src/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import WatchItemForm from '~/src/components/forms/watchItemForm'
import { watchItemStatusOptions, watchItemTypeOptions, statusOptions, pageSizeOptions } from '~/src/constants/options'
import update from 'immutability-helper'
import ReactPlayer from 'react-player'
import _ from 'lodash'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const FilterWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

const PlayerWrapper = styled.div`
    position: relative;
    height: 500px;
    width: 100%;
    padding-top: 56.25%;
    background-color: #000000;
    padding: 8px;
`

const Player = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

@inject('commonStore', 'accessControlStore') @observer
class WatchItemDetailPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {
        const { accessControlStore, commonStore, match } = this.props
        const { token } = commonStore
        const { role } = match.params
        await accessControlStore.listAccessControl(token)

    }


    handleOnBackClick() {
        this.props.history.goBack()
    }

    async handleOnRecordChecked(record, action, checked) {
        const { accessControlStore, commonStore } = this.props
        const { token } = commonStore
        const { accessControl } = record
        let _accessControl = accessControl.filter((a) => { return a !== action })
        if (checked) {
            _accessControl.push(action)
        }
        console.log(_accessControl)
        await accessControlStore.updateAccessControl(token, record._id, _accessControl)
    }

    renderTableColumn() {
        const { intl, commonStore } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.module }),
                dataIndex: 'module',
                key: 'module',
                width: '25%',
            },
            {
                title: intl.formatMessage({ ...messages.create }).toUpperCase(),
                key: 'CREATE',
                width: '15%',
                render: (text, record) => {
                    return (
                        <Checkbox defaultChecked={record.accessControl.includes('CREATE')} disabled={record.module == 'ENQUIRY' || record.module == 'SELL_WATCH' || record.module == 'INSTALMENT_RATE' || record.module == 'LATEST_UPDATE_DATE'} onChange={(e) => this.handleOnRecordChecked(record, 'CREATE', e.target.checked)} />
                    )
                }
            },
            {
                title: intl.formatMessage({ ...messages.read }).toUpperCase(),
                key: 'READ',
                width: '15%',
                render: (text, record) => {
                    return (
                        <Checkbox defaultChecked={record.accessControl.includes('READ')} onChange={(e) => this.handleOnRecordChecked(record, 'READ', e.target.checked)} />
                    )
                }
            },
            {
                title: intl.formatMessage({ ...messages.update }).toUpperCase(),
                key: 'UPDATE',
                width: '15%',
                render: (text, record) => {
                    return (
                        <Checkbox defaultChecked={record.accessControl.includes('UPDATE')} onChange={(e) => this.handleOnRecordChecked(record, 'UPDATE', e.target.checked)} />
                    )

                }
            },
            {
                title: intl.formatMessage({ ...messages.delete }).toUpperCase(),
                key: 'DELETE',
                width: '15%',
                render: (text, record) => {
                    return (
                        <Checkbox defaultChecked={record.accessControl.includes('DELETE')} disabled={record.module == 'INSTALMENT_RATE' || record.module == 'LATEST_UPDATE_DATE'} onChange={(e) => this.handleOnRecordChecked(record, 'DELETE', e.target.checked)} />
                    )
                }
            },
            {
                title: intl.formatMessage({ ...messages.publish }).toUpperCase(),
                key: 'PUBLISH',
                width: '15%',
                render: (text, record) => {
                    return (
                        <Checkbox defaultChecked={record.accessControl.includes('PUBLISH')} disabled={record.module !== 'ITEMS'} onChange={(e) => this.handleOnRecordChecked(record, 'PUBLISH', e.target.checked)} />
                    )
                }
            }
        ]
    }

    render() {
        const { intl, accessControlStore, match } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.updateAccessControl })}</title>
                </Helmet>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => this.handleOnBackClick()}>
                        <a>{intl.formatMessage({ ...messages.back })}</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {intl.formatMessage({ ...messages.updateAccessControl })}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={_.sortBy(toJS(accessControlStore.accessControls)[match.params.role], 'module')}
                        pagination={false}
                        loading={accessControlStore.isSubmitting}
                        rowKey={record => record._id}
                    />
                </TableWrapper>
            </Container>
        )
    }
}

export default withRouter(injectIntl(WatchItemDetailPage))