import { DeleteOutlined, EditOutlined, PlusOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons'
import {
    Button, Checkbox, message, Popconfirm, Row, Table, Tag
} from 'antd'
import update from 'immutability-helper'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import queryString from 'query-string'
import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { withRouter } from "react-router"
import styled from 'styled-components'
import NameDisplaySection from '~/src/components/nameDisplaySection'
import WatchBrandFormModal from '~/src/components/watchBrandFormModal'
import { LIMIT } from '~/src/constants/common'
import { DATE_FORMAT } from '~/src/constants/format'
import { pageSizeOptions, statusOptions } from '~/src/constants/options'
import messages from '~/src/messages'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`


@inject('commonStore', 'watchBrandStore') @observer
class WatchBrandPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            pageSize: LIMIT,
            filterValues: {
                name: undefined,
                status: []
            },
            sortedInfo: undefined,
        }
    }

    async componentDidMount() {
        const { watchBrandStore, commonStore, location } = this.props
        const { token, user } = commonStore
        const { pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        const canRead = toJS(user.rbac).find((r) => { return r.module == 'BRANDS' }).accessControl.includes('READ')
        if (canRead) {
            await watchBrandStore.listWatchBrand(token, pageSize, pageSize * (currentPage - 1), filterValues.status, sortField)
        }
        this.setState({ currentPage })
    }


    async handleOnWatchBrandDelete(record) {
        const { watchBrandStore, commonStore, intl } = this.props
        const { token } = commonStore
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        try {
            await watchBrandStore.deleteWatchBrand(token, record._id)
            message.success(intl.formatMessage({ ...messages.success }))
            await watchBrandStore.listWatchBrand(token, pageSize, pageSize * (currentPage - 1), filterValues.status, sortField)
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }

    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { watchBrandStore, commonStore } = this.props
        const { token, user } = commonStore
        const { pageSize } = pagination
        const {
            status
        } = this.state.filterValues
        const canRead = toJS(user.rbac).find((r) => { return r.module == 'BRANDS' }).accessControl.includes('READ')
        if (canRead) {
            watchBrandStore.listWatchBrand(token, pageSize, pageSize * (page - 1), status, sortField)
        }
        const href = `/watchBrand?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    async handleOnUpdateClick(record) {
        this.props.history.push(`/watchBrand/${record._id}`)
    }

    handleOnAddNewClick() {
        this.props.history.push(`/watchBrand/create`)
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                name: undefined,
                status: []
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select())
            // }
        },
    })

    renderTableColumn() {
        const { intl, commonStore } = this.props
        const { user } = commonStore
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.brandId }),
                dataIndex: 'brandId',
                key: 'brand Id',

            },
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => {
                    return <NameDisplaySection value={record.name} />
                },
            },
            {
                title: intl.formatMessage({ ...messages.priority }),
                dataIndex: 'priority',
                key: 'priority'
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text, record) => {
                    const index = statusOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...statusOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                // sorter: true,
                width: 200,
                // sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                )
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                width: 100,
                render: (text, record) => {
                    const canUpdate = toJS(user.rbac).find((r) => { return r.module == 'BRANDS' }).accessControl.includes('UPDATE')
                    const canDelete = toJS(user.rbac).find((r) => { return r.module == 'BRANDS' }).accessControl.includes('DELETE')
                    return (
                        <span>
                            <Button.Group>
                                {
                                    canUpdate ?
                                        <a href={`/watchBrand/${record._id}`} target={"_blank"}>
                                            <Button
                                                icon={<EditOutlined />}
                                                title={intl.formatMessage({ ...messages.update })} />
                                        </a>
                                        :
                                        null
                                }
                                {
                                    canDelete ?
                                        <Popconfirm
                                            title={intl.formatMessage({ ...messages.areYouSureToDelete })}
                                            onConfirm={() => this.handleOnWatchBrandDelete(record)}
                                            okText={intl.formatMessage({ ...messages.yes })}
                                            okType='danger'
                                            cancelText={intl.formatMessage({ ...messages.no })}>
                                            <Button
                                                icon={<DeleteOutlined />}
                                                type="danger"
                                                title={intl.formatMessage({ ...messages.delete })} />
                                        </Popconfirm>
                                        : null
                                }
                            </Button.Group>
                        </span>
                    );
                }
            }
        ];
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { watchBrandStore, commonStore, intl } = this.props
        const { user } = commonStore
        const { currentPage, pageSize } = this.state

        const canCreate = toJS(user.rbac).find((r) => { return r.module == 'BRANDS' }).accessControl.includes('CREATE')
        const canRead = toJS(user.rbac).find((r) => { return r.module == 'BRANDS' }).accessControl.includes('READ')
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.watchBrand })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        {
                            canRead ?
                                <Button type="primary" icon={<RedoOutlined />} onClick={() => this.handleOnResetAllClick()}>
                                    {intl.formatMessage({ ...messages.resetAllSearch })}
                                </Button> : null
                        }
                        {
                            canCreate ? <Button type="primary" icon={<PlusOutlined />} onClick={() => this.handleOnAddNewClick()}>
                                {intl.formatMessage({ ...messages.addNew })}
                            </Button> : null
                        }
                    </ActionLeftWrapper>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(watchBrandStore.watchBrands)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: watchBrandStore.count,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={watchBrandStore.isSubmitting}
                        rowKey={record => record._id}
                    />
                </TableWrapper>
            </Container>
        );
    }
}

export default withRouter(injectIntl(WatchBrandPage))