import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/src/lib/imageCompressor'

class FeedStore {
    @observable isSubmitting = false
    @observable error = null
    @observable feeds = []
    @observable count = 0

    createFeed = flow(function* (token, values) {
        this.isSubmitting = true
        const formData = new FormData()
        console.log('1')
        formData.append('title', values.title)
        console.log('2')
        formData.append('detail', values.detail.toHTML())
        console.log('3')
        formData.append('status', values.status)
        console.log('4')
        for (const f of values.photos) {
            const { originFileObj, type } = f
            if (originFileObj) {
                if (type.split('/')[0].toLowerCase() === 'image') {
                    const thumbnail = yield makeOriginal(originFileObj)
                    formData.append('photos', thumbnail)
                }

                if (type.split('/')[0].toLowerCase() === 'video') {
                    formData.append('photos', originFileObj)
                }
            }
        }
        console.log(formData)
        try {
            yield api.createFeed(token, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listFeed = flow(function* (token, limit, skip, sort, title, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listFeed(token, limit, skip, sort, title, status)
            const { feeds, count } = response.data
            this.feeds = feeds
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateFeed = flow(function* (token, feedId, values) {
        const formData = new FormData()
        formData.append('title', values.title)
        formData.append('detail', values.detail.toHTML())
        formData.append('status', values.status)
        const index = this.feeds.findIndex((b) => {
            return b._id === feedId
        })
        const originalPhotoIds = this.feeds[index].photos.map(p => p._id)
        const preservedPhotoIds = []
        for (const f of values.photos) {
            const { originFileObj, type } = f
            if (originFileObj) {
                if (type.split('/')[0].toLowerCase() === 'image') {
                    const thumbnail = yield makeOriginal(originFileObj)
                    formData.append('photos', thumbnail)
                }

                if (type.split('/')[0].toLowerCase() === 'video') {
                    formData.append('photos', originFileObj)
                }
            } else {
                const { uid } = f
                preservedPhotoIds.push(uid)
            }
        }
        const removePhotos = originalPhotoIds.filter(id => preservedPhotoIds.indexOf(id) < 0)

        removePhotos.forEach((p) => {
            formData.append('removePhotos[]', p)
        })
        this.isSubmitting = true
        try {
            const response = yield api.updateFeed(token, feedId, formData)
            const index = this.feeds.findIndex((b) => {
                return b._id === feedId
            })
            this.feeds[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    deleteFeed = flow(function* (token, feedId) {
        this.isSubmitting = true
        try {
            yield api.deleteFeed(token, feedId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new FeedStore()
